import { RecoilValueReadOnly, selector } from 'recoil';
import { getRecoil } from 'recoil-nexus';

import {
	ToolboxItems,
	ToolboxItem,
	ToolboxApp,
	SettingNames,
	PageInfo,
	SavedWebChatData,
	WebchatSettings,
	WebChatSettingsData,
	LogoChoice,
	ApiResourceProps,
	ApiExplorerNavMode,
} from '../../types';
import SettingsManager, { ToolboxDefaultPages } from './SettingsManager';

/**
 * AppSettings provides boilerplate functions to provide typed access to a storage-backed atom. It also
 * includes typed getter and setter functions. All atoms exposed via this class will be persisted to local storage
 * per the SettingsManager's confguration.
 *
 * Using AppSettings is required for all atoms exposed here; SettingsManager may be used directly for cases that don't fit this pattern.
 */
class AppSettings {
	private _selectedToolboxItemAtom: RecoilValueReadOnly<ToolboxItem | undefined>;
	private _isGoogleAnalyticsInitialized: boolean = false;

	constructor() {
		this._selectedToolboxItemAtom = selector({
			key: SettingNames.SelectedToolboxItem,
			get: ({ get }) => {
				const items = get(this.toolboxItemsAtom());
				const app = get(this.selectedToolboxAppAtom());
				if (!items || !app || !items[app] || items[app].length === 0) return;
				return items[app].find((item) => item.isSelected) || items[app][0];
			},
		});

		// Initialize effects

		// Fetch all atoms to pre-initialize them
		// This is done to avoid triggering the <Suspense> placeholder in <App> after the initial app load
		this.allowStorageAtom();
		this.allowTelemetryAtom();
		this.allowSyntaxHighlightingAtom();
		this.syntaxHighlightingLimitKbAtom();
		this.toolboxItemsAtom();
		this.selectedToolboxItemAtom();
		this.selectedToolboxAppAtom();
		this.apiExplorerCateogryFiltersAtom();
		this.apiExplorerReadingModeAtom();
		this.paginatorPageSizeAtom();
		this.navigationCollapsedAtom();
		this.sidebarCollapsedAtom();
	}

	// AllowStorage
	allowStorageAtom() {
		return SettingsManager.getSettingAtom<boolean | undefined>(SettingNames.AllowStorage);
	}
	getAllowStorage(): boolean | undefined {
		return SettingsManager.get(SettingNames.AllowStorage);
	}
	setAllowStorage(allow: boolean) {
		return SettingsManager.set(SettingNames.AllowStorage, allow);
	}

	// AllowTelemetry
	allowTelemetryAtom() {
		return SettingsManager.getSettingAtom<boolean>(SettingNames.AllowTelemetry, false);
	}
	getAllowTelemetry(): boolean {
		return SettingsManager.get(SettingNames.AllowTelemetry) || false;
	}
	setAllowTelemetry(allow: boolean) {
		return SettingsManager.set(SettingNames.AllowTelemetry, allow);
	}

	// AllowSyntaxHighlighting
	allowSyntaxHighlightingAtom() {
		return SettingsManager.getSettingAtom<boolean>(SettingNames.AllowSyntaxHighlighting, true);
	}
	getAllowSyntaxHighlighting(): boolean {
		return SettingsManager.get(SettingNames.AllowSyntaxHighlighting) || true;
	}
	setAllowSyntaxHighlighting(allow: boolean) {
		return SettingsManager.set(SettingNames.AllowSyntaxHighlighting, allow);
	}
	// SyntaxHighlightingLimitKb
	syntaxHighlightingLimitKbAtom() {
		return SettingsManager.getSettingAtom<number>(SettingNames.SyntaxHighlightingLimitKb, 250);
	}
	getSyntaxHighlightingLimitKb(): number {
		return SettingsManager.get(SettingNames.SyntaxHighlightingLimitKb) || 250;
	}
	setSyntaxHighlightingLimitKb(limit: number) {
		return SettingsManager.set(SettingNames.SyntaxHighlightingLimitKb, limit);
	}

	// ToolboxItems
	toolboxItemsAtom() {
		return SettingsManager.getSettingAtom<ToolboxItems>(SettingNames.ToolboxItems, ToolboxDefaultPages);
	}
	getToolboxItems(): ToolboxItems | undefined {
		return SettingsManager.get(SettingNames.ToolboxItems, ToolboxDefaultPages);
	}
	setToolboxItems(items: ToolboxItems) {
		return SettingsManager.set(SettingNames.ToolboxItems, items);
	}

	// SelectedToolboxItem
	selectedToolboxItemAtom() {
		return this._selectedToolboxItemAtom;
	}
	getSelectedToolboxItem(): ToolboxItem | undefined {
		return getRecoil(this._selectedToolboxItemAtom);
	}
	setSelectedToolboxItem(app: ToolboxApp, item: ToolboxItem | undefined) {
		const items = { ...this.getToolboxItems() } as ToolboxItems;
		if (!items || !items[app] || items[app].length === 0) return;
		items[app] = [...items[app]];

		// Reset isSelected on every item
		for (let i = 0; i < items[app].length; i++) {
			items[app][i] = { ...items[app][i], isSelected: areToolboxItemsEquivalent(items[app][i], item) };
		}

		// Default to first item selected if none are
		if (!items[app].find((item) => item.isSelected)) items[app][0].isSelected = true;

		// Update items
		this.setToolboxItems(items);
	}

	// SelectedToolboxApp
	selectedToolboxAppAtom() {
		return SettingsManager.getSettingAtom<ToolboxApp>(SettingNames.SelectedToolboxApp, ToolboxApp.ApiExplorer);
	}
	getSelectedToolboxApp(): ToolboxApp {
		return SettingsManager.get(SettingNames.SelectedToolboxApp) || ToolboxApp.ApiExplorer;
	}
	setSelectedToolboxApp(app: ToolboxApp) {
		return SettingsManager.set(SettingNames.SelectedToolboxApp, app);
	}

	// ApiExplorerCateogryFilters
	apiExplorerCateogryFiltersAtom() {
		return SettingsManager.getSettingAtom<string[]>(SettingNames.ApiExplorerCateogryFilters, []);
	}
	getApiExplorerCateogryFilters(): string[] {
		return SettingsManager.get(SettingNames.ApiExplorerCateogryFilters) || [];
	}
	setApiExplorerCateogryFilters(filters: string[]) {
		return SettingsManager.set(SettingNames.ApiExplorerCateogryFilters, filters);
	}

	// ApiExplorerReadingMode
	apiExplorerReadingModeAtom() {
		return SettingsManager.getSettingAtom<boolean>(SettingNames.ApiExplorerReadingMode, true);
	}
	getApiExplorerReadingMode(): boolean {
		return SettingsManager.get(SettingNames.ApiExplorerReadingMode) === true;
	}
	setApiExplorerReadingMode(readingMode: boolean) {
		return SettingsManager.set(SettingNames.ApiExplorerReadingMode, readingMode);
	}

	// ApiExplorerProMode
	apiExplorerProModeAtom() {
		return SettingsManager.getSettingAtom<boolean>(SettingNames.ApiExplorerProMode, false);
	}
	getApiExplorerProMode(): boolean {
		return SettingsManager.get(SettingNames.ApiExplorerProMode) === true;
	}
	setApiExplorerProMode(proMode: boolean) {
		return SettingsManager.set(SettingNames.ApiExplorerProMode, proMode);
	}

	// ApiExplorerStandaloneSelectedOperation
	apiExplorerStandaloneSelectedOperationAtom() {
		return SettingsManager.getSettingAtom<ApiResourceProps | undefined>(SettingNames.ApiExplorerStandaloneSelectedOperation);
	}
	getApiExplorerStandaloneSelectedOperation(): ApiResourceProps | undefined {
		return SettingsManager.get(SettingNames.ApiExplorerStandaloneSelectedOperation);
	}
	setApiExplorerStandaloneSelectedOperation(selectedOperation: ApiResourceProps | undefined) {
		return SettingsManager.set(SettingNames.ApiExplorerStandaloneSelectedOperation, selectedOperation);
	}

	// ApiExplorerNavStyle
	apiExplorerNavModeAtom() {
		return SettingsManager.getSettingAtom<ApiExplorerNavMode>(SettingNames.ApiExplorerNavMode, ApiExplorerNavMode.Category);
	}
	getApiExplorerNavMode(): ApiExplorerNavMode {
		return SettingsManager.get(SettingNames.ApiExplorerNavMode) || ApiExplorerNavMode.Category;
	}
	setApiExplorerNavMode(mode: ApiExplorerNavMode) {
		return SettingsManager.set(SettingNames.ApiExplorerNavMode, mode);
	}

	// PaginatorPageSize
	paginatorPageSizeAtom() {
		return SettingsManager.getSettingAtom<number>(SettingNames.PaginatorPageSize, 10);
	}
	getPaginatorPageSize(): number {
		return SettingsManager.get(SettingNames.PaginatorPageSize) || 10;
	}
	setPaginatorPageSize(pageSize: number) {
		return SettingsManager.set(SettingNames.PaginatorPageSize, pageSize);
	}

	// NavigationCollapsed
	navigationCollapsedAtom() {
		return SettingsManager.getSettingAtom<boolean>(SettingNames.NavigationCollapsed, false);
	}
	getNavigationCollapsed(): boolean {
		return SettingsManager.get(SettingNames.NavigationCollapsed) === true;
	}
	setNavigationCollapsed(isCollapsed: boolean) {
		return SettingsManager.set(SettingNames.NavigationCollapsed, isCollapsed);
	}

	//Webchat saved data
	webChatDataAtom() {
		return SettingsManager.getSettingAtom<SavedWebChatData[]>(SettingNames.WebChatData, [] as SavedWebChatData[]);
	}

	getWebChatData(): SavedWebChatData[] {
		return SettingsManager.get(SettingNames.WebChatData) || [];
	}

	setWebChatData(data: SavedWebChatData[]) {
		return SettingsManager.set(SettingNames.WebChatData, data);
	}

	//Webchat settings
	webChatSettingsAtom() {
		return SettingsManager.getSettingAtom<WebchatSettings>(SettingNames.WebChatSettings, {} as WebchatSettings);
	}

	getWebchatSettings(): WebchatSettings {
		return SettingsManager.get(SettingNames.WebChatSettings) || ({} as WebchatSettings);
	}

	setWebChatSettings(settings: WebChatSettingsData, version: string) {
		let s = { ...this.getWebchatSettings() };

		if (s) {
			switch (version) {
				case 'one':
					s.version1 = settings;
					break;
				case 'two':
					s.version2 = settings;
					break;
				case 'messenger':
					s.messenger = settings;
					break;
				default:
					return;
			}
			return SettingsManager.set(SettingNames.WebChatSettings, s);
		}
	}

	// SidebarCollapsed
	sidebarCollapsedAtom() {
		return SettingsManager.getSettingAtom<boolean>(SettingNames.SidebarCollapsed, false);
	}
	getSidebarCollapsed(): boolean {
		return SettingsManager.get(SettingNames.SidebarCollapsed) === true;
	}
	setSidebarCollapsed(isCollapsed: boolean) {
		return SettingsManager.set(SettingNames.SidebarCollapsed, isCollapsed);
	}

	// Logo option
	logoChoiceOption() {
		return SettingsManager.getSettingAtom<LogoChoice>(SettingNames.LogoChoice, LogoChoice.Default);
	}

	getLogoChoiceOption(): LogoChoice {
		return SettingsManager.get(SettingNames.LogoChoice) || LogoChoice.Default;
	}

	setLogoChoiceOption(choice: LogoChoice) {
		return SettingsManager.set(SettingNames.LogoChoice, choice);
	}
}

export default new AppSettings();

function areToolboxItemsEquivalent(a?: ToolboxItem, b?: ToolboxItem) {
	if (!a || !b) return false;
	if ((a.props as PageInfo).link !== (b.props as PageInfo).link) return false;
	return a.title === b.title && a.appType === b.appType;
}
