import React from 'react';

import CmsApi from '../../helpers/CmsApi';
//import NotificationSubscriptions from './subscriptions/NotificationSubscriptions';
import moment from 'moment';

export default function MyProfile() {
	let lastVisit = '';
	if (CmsApi.currentUser?.lastLogin) {
		lastVisit = moment.duration(moment().diff(moment(CmsApi.currentUser?.lastLogin))).humanize();
	}
	return (
		<div>
			<p>
				Welcome {CmsApi.currentUser?.firstName || CmsApi.currentUser?.displayName || 'fellow human'}.{' '}
				{lastVisit &&
					`It has been 
				${lastVisit} since you last authenticated.`}
			</p>
			{/* <NotificationSubscriptions />  TODO: uncomment when subscription endpoint is readyb for prod*/}
		</div>
	);
}
