import { useEffect, useState } from 'react';
import { DxButton } from 'genesys-react-components';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import { PathSegmentInfo, visibilityMap } from '../../../helpers/openapi/OpenAPITypes';
import { AddApiResource } from '../../../helpers/atoms/ToolboxAtom';
import AppSettings from '../../../helpers/settings/AppSettings';
import { ApiResourceProps, ToolboxApp } from '../../../types';
import Tag from '../../cards/Tag';

import './PathSegment.scss';

interface IProps {
	segment: PathSegmentInfo;
	pushOpenDepth?: number;
}

export default function PathSegment(props: IProps) {
	// const pushOpenDepth = props.pushOpenDepth || 0;
	const [pushOpenDepth, setPushOpenDepth] = useState<number>(props.pushOpenDepth || 0);
	const [isOpen, setIsOpen] = useState<boolean>(pushOpenDepth > 0);

	useEffect(() => {
		if (props.pushOpenDepth === undefined || isNaN(props.pushOpenDepth)) return;
		setPushOpenDepth(props.pushOpenDepth);
		if (props.pushOpenDepth > 0) setIsOpen(true);
	}, [props.pushOpenDepth]);

	return (
		<div className="path-segment">
			<DxButton
				className="path-toggle"
				type="link"
				onClick={() => {
					setIsOpen(!isOpen);
					if (isOpen) setPushOpenDepth(0);
				}}
			>
				<span className="path-parts">
					/{props.segment.segment}
					<GenesysDevIcon
						className="expander-button"
						icon={GenesysDevIcons.AppZoomZoomDown}
						onClick={() => {
							setIsOpen(true);
							setPushOpenDepth(pushOpenDepth + 100);
						}}
					/>
				</span>
			</DxButton>
			{isOpen && (
				<div className="segment-content">
					<div className="operations-container">
						{props.segment.operations.map((operation) => (
							<DxButton
								key={operation.key}
								type="link"
								className={`operation-info operation-${operation.verb.toLowerCase()}`}
								onClick={() => {
									const props = { path: operation.path, verb: operation.verb } as ApiResourceProps;
									AddApiResource({
										title: operation.title,
										appType: ToolboxApp.ApiExplorer,
										props,
									});
									AppSettings.setApiExplorerStandaloneSelectedOperation(props);
								}}
							>
								<div className="operation-key" title={operation.key}>
									<span className="verb">{operation.verb.toUpperCase()}</span>
									<span>{operation.path}</span>
									{operation.operation.deprecated && <Tag>Deprecated</Tag>}
									{operation.operation['x-genesys-visibility'] && (
										<Tag className={`tag-${operation.operation['x-genesys-visibility']}`}>
											{visibilityMap[operation.operation['x-genesys-visibility']]}
										</Tag>
									)}
								</div>
								<div className="operation-description">{operation.description}</div>
							</DxButton>
						))}
					</div>
					{(Object.values(props.segment.segments) as PathSegmentInfo[])
						.sort((a, b) => (a.path < b.path ? -1 : a.path > b.path ? 1 : 0))
						.map((segment) => (
							<PathSegment key={segment.path} segment={segment} pushOpenDepth={pushOpenDepth - 1} />
						))}
				</div>
			)}
		</div>
	);
}
