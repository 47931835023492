import React from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import { ContentMediaData } from '../../../helpers/AssetLoaderTypes';
import { CardClickAction, WrapWithLink } from '../Card';

import './IconCard.scss';
import MarkdownDisplay from '../../markdown/display/MarkdownDisplay';

interface IProps {
	data: ContentMediaData;
	icon: GenesysDevIcons;
	iconFormat?: 'small' | 'large';
	onClickAction?: CardClickAction;
}

export default function IconCard(props: IProps) {
	let titleContainerClasses = ['dx-card-title-container'];
	let bodyContainerClasses = ['dx-card-body'];
	if (props.iconFormat === 'small') {
		titleContainerClasses.push('icon');
		bodyContainerClasses.push('icon');
	} else {
		titleContainerClasses.push('large-icon');
		bodyContainerClasses.push('large-icon');
	}

	const cardText = props.data.summary || props.data.description;

	return WrapWithLink(
		<div className="dx-card-icon">
			<div className={titleContainerClasses.join(' ')}>
				<GenesysDevIcon icon={props.icon} />
				<div className="dx-card-title">
					<span className="dx-card-title-text" title={props.data.title || 'Card Title'}>
						{props.data.title || 'Card Title'}
					</span>
				</div>
				<div className="clearfix"></div>
			</div>
			<div className={bodyContainerClasses.join(' ')}>
				<div className="dx-card-body-text">{cardText ? <MarkdownDisplay markdown={cardText} /> : undefined}</div>
			</div>
		</div>,
		props.data.link,
		props.onClickAction
	);
}
