import React, { useEffect, useState } from 'react';

import AssetLoader from '../../../../helpers/AssetLoader';
import AvailableMediaTypeTile from './AvailableMediaTypeTile';
import { IProps, IMediaTypes } from './AvailableMediaTypeDefinitions';
import { addToast, ToastType } from '../../../../helpers/atoms/ToastAtom';

import './AvailableMediaTypeTool.scss';
import { LoadingPlaceholder } from 'genesys-react-components';

function AvailableMediaTypeTool(props: IProps) {
	const [mediaTypes, setMediaTypes] = useState<IMediaTypes[] | undefined>(); // saves state through function calls

	useEffect(() => {
		// tells react that we need to do something after render
		const { source } = props || {}; // get the source location (data will be written to a json in a script)

		AssetLoader.get(source, true, undefined) // load json
			.then((res: any) => {
				return parsedMediaTypes(res);
			})
			.then((data: IMediaTypes[]) => {
				if (data) {
					setMediaTypes(data); // store data
				}
			})
			.catch((err) => {
				// toast an error if we cannot load data
				addToast({
					title: 'Failed to Load Available Media Types',
					message: `${err}`,
					toastType: ToastType.Critical,
				});
			}); // eslint-disable-next-line react-hooks/exhaustive-deps
	});

	function parsedMediaTypes(mediaTypes: any) {
		let availableMediaTypes: IMediaTypes[] = [];
		mediaTypes.forEach((type: any) => {
			availableMediaTypes.push({
				mediaType: type.mediaType,
				subMediaTypes: type.subMediaTypes,
			});
		});

		return mediaTypes;
	}

	return (
		<div className="mediatypes-container">
			<div className="headings-container">
				<h4>Media type</h4>
				<h4 className="submediatypes">Subtypes</h4>
			</div>

			{mediaTypes && mediaTypes.map((type: IMediaTypes) => <AvailableMediaTypeTile type={type} />)}
			{!mediaTypes && <LoadingPlaceholder text="Loading available media types" />}
		</div>
	);
}

export default AvailableMediaTypeTool;
