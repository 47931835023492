import { ReactNode } from 'react';
import { YeastChild, HeadingNode } from 'yeast-core';
import { NodeRendererPlugin, ReactRenderer, useKey } from 'yeast-react-renderer';

import SectionHeading from '../../heading/SectionHeading';

const HeadingRendererPlugin: NodeRendererPlugin = (node: YeastChild, renderer: ReactRenderer): ReactNode => {
	const key = useKey();

	const typedNode = node as HeadingNode;
	if (typedNode.level === 7) {
		return (
			<span className="h7" key={key.current} id={typedNode.id}>
				{renderer.renderComponents(typedNode.children) as ReactNode}
			</span>
		);
	} else {
		return (
			<SectionHeading key={key.current} id={typedNode.id} level={typedNode.level}>
				{renderer.renderComponents(typedNode.children) as ReactNode}
			</SectionHeading>
		);
	}
};

export default HeadingRendererPlugin;
