import { ContentPage, MatchType } from '../types';

// Content Components
// import Snake from '../components/content/snake/Snake';
// import Test from '../components/content/test/Test';
// import NavProviderExample from '../components/content/test/NavProviderExample';

// Use these values for the matchType property on a route to control how the path is matched
// export let MatchType = {
// 	exact: 'EXACT', // default
// 	startsWith: 'STARTSWITH',
// };

class ContentPages {
	[path: string]: ContentPage;

	constructor() {
		// Routes
		this['/me'] = {
			title: 'My Profile',
			component: 'MyProfile',
			matchType: MatchType.StartsWith,
		};

		if (window.location.host.toLowerCase().startsWith('developer') || window.location.host.toLowerCase().startsWith('localhost')) {
			this['/forum/'] = {
				title: 'Developer Forum',
				component: 'ExternalLinkRefresher',
				matchType: MatchType.StartsWith,
			};
			// this['/devapps/sdk/docexplorer'] = {
			// 	title: 'SDK Documentation Explorer',
			// 	component: 'SdkDocExplorer',
			// 	matchType: MatchType.StartsWith,
			// };
			// this['/api/rest/client-libraries'] = {
			// 	title: 'SDK Documentation Redirect',
			// 	component: 'SdkRedirecter',
			// 	matchType: MatchType.StartsWith,
			// 	notoc: true,
			// };
		}
	}
}

export default new ContentPages();
