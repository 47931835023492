import { atom, useRecoilValue } from 'recoil';
import AssetLoader from '../AssetLoader';
import CmsApi from '../CmsApi';
import { Asset, AssetType } from '../CmsApiTypes';
import { Page, Sitemap, SitemapData } from '../Sitemap';

const requiresAuthorization = process.env.REACT_APP_REQUIRES_AUTHORIZATION === 'true';

export const sitemapAtom = atom({
	key: 'sitemap',
	// default: (async () => new Sitemap((await AssetLoader.get('/data/sitemap.json', true, undefined)) as SitemapData))(),
	default: (async () => {
		if (requiresAuthorization) {
			const isAuthorized = await CmsApi.checkAuthorization();
			if (!isAuthorized) {
				// User is not authorized, but the site requires it. Redirect to start a new session.
				if (!window.location.pathname.toLowerCase().startsWith('/sessions/new')) {
					window.location.assign(
						// `http://localhost:3100/sessions/new?redirect_uri=${encodeURIComponent('http://localhost:3100/oauth/callback')}`
						// `/sessions/new?redirect_uri=${encodeURIComponent(`https://${window.location.hostname}/sessions/callback`)}`
						// '/sessions/new'
						process.env.REACT_APP_NEW_SESSION_URL || '/sessions/new'
					);
				}
			}
		}

		const data = await CmsApi.getAssets();
		// console.log('ASSET DATA', data);
		if (data) {
			const sitemapData: SitemapData = {
				isDir: true,
				path: '/',
			};

			const addToSitemap = (asset: Asset, targetPath: string, relativeSitemap: SitemapData) => {
				if (asset.assetType !== AssetType.Document) {
					return;
				}
				let keyPath = removePropertyPrefix(asset.keyPath);
				keyPath = encodeURI(keyPath);
				if (keyPath.toLowerCase().endsWith('/index')) {
					keyPath = keyPath.substring(0, keyPath.length - 5);
				}
				const targetPathParts = targetPath.split('/').filter((v) => !!v);
				// console.log(asset, targetPathParts);
				// Add page
				if (targetPathParts.length === 1) {
					relativeSitemap[encodeURIComponent(targetPathParts[0])] = {
						// First apply the attributes of the asset
						...asset.attributes,
						// Then set specific properties
						title: asset.title,
						link: keyPath,
					} as Page;
					return;
				}

				// Lazy create directory and drill down
				const nextFolder = targetPathParts.shift();
				if (!nextFolder || targetPathParts.length === 0) {
					console.warn('failed to add asset!', asset, targetPath, relativeSitemap);
					return;
				}
				if (!relativeSitemap[nextFolder]) {
					relativeSitemap[nextFolder] = {
						isDir: true,
						path: relativeSitemap.path + nextFolder + '/',
					} as SitemapData;
				}
				addToSitemap(asset, targetPathParts.join('/'), relativeSitemap[nextFolder]);
			};

			data.forEach((asset: Asset) => addToSitemap(asset, removePropertyPrefix(asset.keyPath), sitemapData));

			// console.log('NEW SITEMAP', sitemapData);
			return new Sitemap(sitemapData);
		} else {
			return new Sitemap((await AssetLoader.get('/data/sitemap.json', true, undefined)) as SitemapData);
		}
	})(),
});

function removePropertyPrefix(keyPath: string) {
	// TODO: make this dynamic
	if (keyPath.startsWith(`/${process.env.REACT_APP_SITE_PROPERTY}/`)) {
		return keyPath.substring(process.env.REACT_APP_SITE_PROPERTY!.length + 1);
	} else {
		return keyPath;
	}
}

// Sitemap hook
export const useSitemap = () => useRecoilValue(sitemapAtom);
