import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxToggle, DxButton } from 'genesys-react-components';

import { v4 as uuidv4 } from 'uuid';

import AssetLoader from '../../helpers/AssetLoader';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import { selectedAccountAtom } from '../../helpers/atoms/AccountsAtom';

import {
	updateSearchCache,
	searchCacheAtom,
	searchFilterConfigAtom,
	searchDataAtom,
	executeSearch,
	updateSearchData,
	recentSearchesAtom,
	removeRecentSearch
} from '../../helpers/atoms/SearchAtom';
import {
	ISearchProps,
	SearchResult,
	SearchSuggestion,
	Origin,
	DevCenterContentType,
	HelpContentType,
	SuggestionType,
	originMap,
	contentTypeMap,
	HighlightLimits,
	SearchCache,
	SearchData,
	APICentralContentType,
	RecentSearch,
	DevCenterTopic,
	FilterUpdate,
	topicMap,
	ResultItem
} from './SearchTypes';
import { SearchTools } from './SearchTools';

import historyIcon from './history.svg';
import './SearchBar.scss';
import { LoadingPlaceholder } from 'genesys-react-components';
import { ToastType, addToast } from '../../helpers/atoms/ToastAtom';

const searchTools = new SearchTools();

export default function SearchBar(props: ISearchProps) {
	let [searchTerm, setSearchTerm] = useState('');
	let [searchText, setSearchText] = useState('');
	let [timer, setTimer] = useState(undefined as unknown as ReturnType<typeof setTimeout>);
	let [searchInputRef, setSearchInputRef] = useState(undefined as unknown as HTMLInputElement | null);
	let [isInputFocused, setIsInputFocused] = useState(false);
	let [showRecent, setShowRecent] = useState(false);
	let [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);
	let [autocompleteText, _setAutocompleteText] = useState<string>('');
	// These state elements serve as a trigger to run the show/hide logic in the correct context of the component. The values of the state
	// hooks aren't getting updated inside the callback passed into document.addEventListener(); they remain their initially seeded values.
	let [showCommand, setShowCommand] = useState(Date.now());
	let [hideCommand, setHideCommand] = useState(Date.now());

	// recoil
	let [searchCache] = useRecoilState(searchCacheAtom);
	let [searchFilterConfig, setSearchFilterConfig] = useRecoilState(searchFilterConfigAtom);
	let [searchData] = useRecoilState(searchDataAtom);
	let [recentSearches] = useRecoilState(recentSearchesAtom);
	const selectedAccount = useRecoilValue(selectedAccountAtom);

	// refs
	const autocompleteTextRef = useRef<string>(autocompleteText);
	const searchBarId = useRef<string>(`search-bar-${uuidv4()}`);
	const wrapperRef = useRef(null as any);

	const setAutocompleteText = (data: string) => {
		autocompleteTextRef.current = data;
		_setAutocompleteText(data);
	};

	// Constructor
	useEffect(() => {
		const cancelToken = AssetLoader.generateCancelToken();
		// Add a click listener to close the search results when the user clicks outside of it
		document.addEventListener('click', handleClickOutside);
		// Register global key bindings
		document.addEventListener('keydown', globalKeyBindings, false);
		// initialize guest visitor id for search feedback if needed
		if (!localStorage.getItem('searchGuestId')) {
			localStorage.setItem('searchGuestId', uuidv4());
		}

		return () => {
			cancelToken.cancel();
			document.removeEventListener('click', handleClickOutside);
			document.removeEventListener('keydown', globalKeyBindings, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Focus search input on timestamp change
	useEffect(() => {
		searchInputRef?.focus();
		// Don't trigger focus when the input element changes, only when the search command does
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showCommand]);

	// Hide search input on timestamp change
	useEffect(() => {
		hide();
		// Don't trigger focus when the input element changes, only when the search command does
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideCommand]);

	// execute search on search term or filter update
	useEffect(() => {
		if (searchFilterConfig.changeInitiator === undefined || searchFilterConfig.changeInitiator === searchBarId.current) {
			const searchGuestId: string | undefined = localStorage.getItem('searchGuestId') || undefined;

			executeSearch(
				searchBarId.current,
				searchTerm,
				searchText,
				searchFilterConfig.sourceFilters,
				searchFilterConfig.helpContentFilters,
				searchFilterConfig.devCenterContentFilters,
				searchFilterConfig.devCenterTopicFilters,
				searchFilterConfig.apiCentralContentFilters,
				searchFilterConfig.topicFiltersEnabled,
				selectedAccount?.userId || searchGuestId
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		searchTerm,
		searchFilterConfig.sourceFilters,
		searchFilterConfig.devCenterContentFilters,
		searchFilterConfig.devCenterTopicFilters,
		searchFilterConfig.topicFiltersEnabled,
		searchFilterConfig.helpContentFilters,
		searchFilterConfig.changeInitiator
	]);

	// handle search result data update
	useEffect(() => {
		if (!searchData) return;

		// get the search data that matches the component instance
		const matchingData: SearchData | undefined = searchData.find((data: SearchData) => data.id === searchBarId.current);
		if (!matchingData) return;

		if (matchingData.autocompleteText !== autocompleteText) {
			setAutocompleteText(matchingData.autocompleteText || '');
		}
	}, [searchData]); // eslint-disable-line react-hooks/exhaustive-deps

	// hide when click is detected outside the search bar component
	function handleClickOutside(event: any) {
		// suggestions are removed from the dom by the time this check is done, so searching within the wrapper ref won't work for that case
		const isInsideClick: boolean =
			event.target.parentNode?.className?.includes('search-suggestion') ||
			event.target.className?.includes('clear-history-icon') ||
			event.target.id?.includes('source-filter') ||
			event.target.id?.includes('content-filter') ||
			(wrapperRef.current && wrapperRef.current.contains(event.target))
		if (!isInsideClick) {
			hide();
		}
	}

	// Global key bindings
	function globalKeyBindings(event: KeyboardEvent) {
		// Helper to determine if key press is supposed to go to an input type element
		const isSomeKindOfInput = (target: HTMLElement) => {
			if (!target) return false;
			return ['input', 'textarea'].includes(target.tagName.toLowerCase());
		};

		// Slash key - focus search
		if (props.enableHotkey && event.key === '/' && !isSomeKindOfInput(event.target as HTMLElement)) {
			event.stopPropagation();
			event.preventDefault();
			setShowCommand(Date.now());
			return;
		}

		// Escape - cancel search
		if (event.key === 'Escape') {
			event.stopPropagation();
			event.preventDefault();
			setHideCommand(Date.now());
			return;
		}

		if (autocompleteTextRef.current) {
			let selectionStart = null;

			// capture caret position to reset after autocomplete change
			if (event.key === 'Left' || event.key === 'ArrowLeft') {
				selectionStart = searchInputRef?.selectionStart;
			}

			// search on autocomplete value when these keys are pressed
			if (
				event.key === 'Enter' ||
				event.key === 'Right' ||
				event.key === 'ArrowRight' ||
				event.key === 'Left' ||
				event.key === 'ArrowLeft'
			) {
				const autocompleteCopy: string = autocompleteTextRef.current;
				updateSearchData({
					id: searchBarId.current,
					results: undefined,
					searchSuggestions: undefined,
					autocompleteText: '',
					isLoading: true,
				});
				setSearchText(autocompleteCopy);
				setSearchTerm(autocompleteCopy);
			}

			if (event.key === 'Left' || event.key === 'ArrowLeft') {
				if (selectionStart) {
					searchInputRef?.setSelectionRange(selectionStart + 1, selectionStart + 1);
				}
			}

			// remove autocomplete and preserve current search term on backspace (when autocomplete suffix is present)
			if (event.key === 'Backspace') {
				if (autocompleteTextRef.current) {
					event.stopPropagation();
					event.preventDefault();
					updateSearchData({ id: searchBarId.current, autocompleteText: '' });
				}
			}
		}
	}

	// Hide the search results/filters and unfocus the search input
	function hide() {
		setSearchTerm('');
		setSearchText('');
		updateSearchData({ id: searchBarId.current, results: undefined, searchSuggestions: undefined, autocompleteText: '' });
		setIsFilterExpanded(false);
		searchInputRef?.blur();
		setShowRecent(false);
	}

	// debounce adds a buffer time between search input text and the triggering of a search
	function debounce(e: ChangeEvent<HTMLInputElement>) {
		setSearchText(e.target.value);
		if (isFilterExpanded) setIsFilterExpanded(false);

		// Clear the pending timers
		clearTimeout(timer);

		// e.target is a transient DOM reference; store the value for use in the timeout callback
		const term = e.target.value;

		// Set a timer to update the search term
		setTimer(
			setTimeout(() => {
				// get the search data that matches the component instance
				updateSearchData({
					id: searchBarId.current,
					results: undefined,
					searchSuggestions: undefined,
					isLoading: searchTools.isValidSearchTerm(term),
				});
				setSearchTerm(term);
			}, 300)
		);
	}

	// update source filters
	function handleSourceFilters(e: React.ChangeEvent<HTMLInputElement>) {
		const sourceFilterUpdate: FilterUpdate<Origin> = searchTools.updateSourceFilters(e, searchFilterConfig.sourceFilters);
		const { filter, newFilters, newFilterVal } = sourceFilterUpdate;

		if (!filter) return;
		if (newFilters[filter] !== newFilterVal) {
			newFilters[filter] = newFilterVal;
			updateSearchData({
				id: searchBarId.current,
				results: undefined,
				searchSuggestions: undefined,
				isLoading: searchTools.isValidSearchTerm(searchTerm),
			});
			setSearchFilterConfig({
				...searchFilterConfig,
				sourceFilters: newFilters,
				changeInitiator: searchBarId.current
			});
		}
	}

	// update Developer Center content filters
	function handleDevCenterContentFilters(e: React.ChangeEvent<HTMLInputElement>) {
		const devCenterFilterUpdate: FilterUpdate<DevCenterContentType> = searchTools.updateDevCenterContentFilters(e, searchFilterConfig.devCenterContentFilters);
		const { filter, newFilters, newFilterVal } = devCenterFilterUpdate;

		if (!filter) return;
		if (newFilters[filter] !== newFilterVal) {
			newFilters[filter] = newFilterVal;
			updateSearchData({
				id: searchBarId.current,
				results: undefined,
				searchSuggestions: undefined,
				isLoading: searchTools.isValidSearchTerm(searchTerm),
			});
			setSearchFilterConfig({
				...searchFilterConfig,
				devCenterContentFilters: newFilters,
				changeInitiator: searchBarId.current
			});
		}
	}

	// toggle Developer Center topic filters
	function handleDevCenterTopicToggle(value = false) {
		updateSearchData({
			id: searchBarId.current,
			results: undefined,
			searchSuggestions: undefined,
			isLoading: searchTools.isValidSearchTerm(searchTerm),
		});
		setSearchFilterConfig({
			...searchFilterConfig,
			topicFiltersEnabled: value,
			changeInitiator: searchBarId.current
		});
	}

	// update Developer Center topic filters
	function handleDevCenterTopicFilters(e: React.ChangeEvent<HTMLInputElement>) {
		const devCenterFilterUpdate: FilterUpdate<DevCenterTopic> = searchTools.updateDevCenterTopicFilters(e, searchFilterConfig.devCenterTopicFilters);
		const { filter, newFilters, newFilterVal } = devCenterFilterUpdate;

		if (!filter) return;
		if (newFilters[filter] !== newFilterVal) {
			newFilters[filter] = newFilterVal;
			updateSearchData({
				id: searchBarId.current,
				results: undefined,
				searchSuggestions: undefined,
				isLoading: searchTools.isValidSearchTerm(searchTerm),
			});
			setSearchFilterConfig({
				...searchFilterConfig,
				devCenterTopicFilters: newFilters,
				changeInitiator: searchBarId.current
			});
		}
	}

	// update Resource Center content filters
	function handleHelpContentFilters(e: React.ChangeEvent<HTMLInputElement>) {
		const helpFilterUpdate: FilterUpdate<HelpContentType> = searchTools.updateHelpContentFilters(e, searchFilterConfig.helpContentFilters);
		const { filter, newFilters, newFilterVal } = helpFilterUpdate;

		if (!filter) return;
		if (newFilters[filter] !== newFilterVal) {
			newFilters[filter] = newFilterVal;
			updateSearchData({
				id: searchBarId.current,
				results: undefined,
				searchSuggestions: undefined,
				isLoading: searchTools.isValidSearchTerm(searchTerm),
			});
			setSearchFilterConfig({
				...searchFilterConfig,
				helpContentFilters: newFilters,
				changeInitiator: searchBarId.current
			});
		}
	}

	// update API Central content filters
	function handleAPICentralContentFilters(e: React.ChangeEvent<HTMLInputElement>) {
		const apiCentralFilterUpdate: FilterUpdate<APICentralContentType> = searchTools.updateAPICentralContentFilters(e, searchFilterConfig.apiCentralContentFilters);
		const { filter, newFilters, newFilterVal } = apiCentralFilterUpdate;

		if (!filter) return;
		if (newFilters[filter] !== newFilterVal) {
			newFilters[filter] = newFilterVal;
			updateSearchData({
				id: searchBarId.current,
				results: undefined,
				searchSuggestions: undefined,
				isLoading: searchTools.isValidSearchTerm(searchTerm),
			});
			setSearchFilterConfig({
				...searchFilterConfig,
				apiCentralContentFilters: newFilters,
				changeInitiator: searchBarId.current
			});
		}
	}

	// handle click on suggestion list item beneath search bar
	function handleSuggestionClick(e: React.MouseEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		const newSearchTerm: string = e.currentTarget.textContent || '';
		updateSearchData({ id: searchBarId.current, results: undefined, searchSuggestions: undefined, autocompleteText: '', isLoading: true });
		setSearchText(newSearchTerm);
		setSearchTerm(newSearchTerm);
	}

	function handleAutocompleteClick() {
		if (!autocompleteText) return;
		const autocompleteCopy: string = autocompleteText;
		updateSearchData({ id: searchBarId.current, results: undefined, searchSuggestions: undefined, autocompleteText: '', isLoading: true });
		setSearchText(autocompleteCopy);
		setSearchTerm(autocompleteCopy);
	}

	// handle history suggestion removal click
	function handleRemoveHistory(suggestion: SearchSuggestion) {
		const cachedSearches: SearchCache[] = searchCache.filter((cached: SearchCache) => cached.searchTerm === suggestion.text);
		if (cachedSearches.length) {
			cachedSearches.forEach((cached: SearchCache) => {
				const updatedSearch: SearchCache = Object.assign({}, cached);
				updatedSearch.isSuggestionRemoved = true;
				updateSearchCache(cached, updatedSearch);
			});
		}
		// get the search data that matches the component instance
		const matchingData: SearchData | undefined = searchData?.find((data: SearchData) => data.id === searchBarId.current);
		if (matchingData?.searchSuggestions && matchingData.searchSuggestions.length) {
			const suggestionIndex = matchingData.searchSuggestions.indexOf(suggestion);
			const newSuggestions = Array.from(matchingData.searchSuggestions);
			newSuggestions.splice(suggestionIndex, 1);
			const autocompleteTextMatches: boolean = autocompleteText.toLowerCase() === suggestion.text.toLowerCase();
			const isOnlyMatchingSuggestion: boolean = !newSuggestions.find(
				(suggestion: SearchSuggestion) => suggestion.text.toLowerCase() === autocompleteText.toLowerCase()
			);
			// if the history item to be removed matches the autocomplete text, remove the autocomplete text as well
			if (autocompleteTextMatches && isOnlyMatchingSuggestion) {
				updateSearchData({ id: searchBarId.current, searchSuggestions: newSuggestions, autocompleteText: '' });
			} else {
				updateSearchData({ id: searchBarId.current, searchSuggestions: newSuggestions });
			}
		}
	}

	// submit relevance feedback, provide a confirmation toast, and remove the controls from the result to prevent spamming
	async function handleRelevanceFeedback(isRelevant: boolean, id: string, indexId: string, queryId: string, matchingData: SearchData | undefined) {
		try {
			await searchTools.submitRelevanceFeedback(isRelevant, id, indexId, queryId);
			// if feedback submission is successful, add a confimation toast and remove the relevance feedback controls from this result
			addToast({
				message: 'Successfully submitted search feedback',
				toastType: ToastType.Success,
				timeoutSeconds: 10
			});
			const cachedSearch: SearchCache | undefined = searchCache.find((cached: SearchCache) => 
				cached.queryResponse.QueryResults?.ResultItems?.some((item: ResultItem) => item.Id === id));
			if (cachedSearch) {
				const updatedSearch: SearchCache = {
					...cachedSearch,
					queryResponse: {
						...cachedSearch.queryResponse,
						QueryResults: cachedSearch.queryResponse.QueryResults ? {
							...cachedSearch.queryResponse.QueryResults,
							ResultItems: cachedSearch.queryResponse.QueryResults.ResultItems!
								.map((item: ResultItem) => {
									if (item.Id === id) {
										return {
											...item,
											RelevanceFeedbackSubmitted: true
										};
									}
									else return item;
								}) || undefined
						} : undefined
					}
				}
				updateSearchCache(cachedSearch, updatedSearch);
			}
			if (matchingData && matchingData.results) {
				updateSearchData({
					id: searchBarId.current,
					results: matchingData.results
						.map((result: SearchResult) => {
							if (result.id === id) {
								return {
									...result,
									relevanceFeedbackSubmitted: true
								};
							}
							else return result;
						})
				});
			}
		} catch (err: any) {
			addToast({
				message: 'Failed to submit search feedback',
				toastType: ToastType.Critical,
				timeoutSeconds: 10
			});
			console.error('failed to submit relevance feedback', err);
		}
	}

	// format search excerpts into preview text for search results
	function renderPreviewText(result: SearchResult): React.ReactNode {
		if (!result.excerptText || !result.excerptText.length) return;

		// set highlighted excerpts if available
		if (result.excerptHighlights && result.excerptHighlights.length) {
			// render top two highlights
			return result.excerptHighlights?.slice(0, 2)?.map((highlight, i) => {
				const highlightLimits: HighlightLimits = searchTools.calcHighlightLimits(result, highlight);
				const { startIndex, endIndex } = highlightLimits;

				return (
					<div className="preview-text" key={i}>
						<span>...{result.excerptText!.substring(startIndex, highlight[0])}</span>
						<span className="highlight">{result.excerptText!.substring(highlight[0], highlight[1])}</span>
						<span>{result.excerptText!.substring(highlight[1], endIndex)}...</span>
					</div>
				);
			});
		}
		// if no highlights available, no extra formatting needed
		else {
			return <span className="preview-text">{result.excerptText}</span>;
		}
	}

	// render breadcrumbs in search results
	function renderBreadcrumbs(origin: string | undefined, url: string, documentType?: string): React.ReactNode {
		if (!origin || !url) return;

		let breadcrumbPath = '';
		if (origin === Origin.DEVELOPER_CENTER || origin === Origin.API_CENTRAL) {
			breadcrumbPath = url;
			return (
				<span>
					<Breadcrumb pagePath={breadcrumbPath} noClick />
				</span>
			);
		} else {
			if (documentType) {
				breadcrumbPath = searchTools.formatBreadcrumbPath(documentType);
			}

			return (
				<div className="breadcrumb-container">
					<span className="before-breadcrumb">{originMap[origin]}&nbsp;-&nbsp;</span>
					<span className="breadcrumb">{breadcrumbPath}</span>
				</div>
			);
		}
	}

	// render search results underneath the search bar
	function renderSearchResult(result: SearchResult, index: number): React.ReactNode {
		const contentIcon: GenesysDevIcons = searchTools.getContentIcon(result.documentType || '');

		const { id, origin, url, documentType, indexId, queryId } = result;
		const children: React.ReactNode = (
			<div
				className="search-result"
				onClick={() => {
					searchTools.submitClickFeedback(id, indexId, queryId);
					hide();
				}}
				key={index}
			>
				<GenesysDevIcon icon={contentIcon} />
				<div className="result-details">
					<div className="result-details-top">
						{renderBreadcrumbs(origin, url, documentType)}
						{!result.relevanceFeedbackSubmitted &&
							<div className="relevance-feedback">
								<DxButton
									type="link"
									onClick={() => {
										handleRelevanceFeedback(true, id, indexId, queryId, matchingData);
									}}
								>
									<GenesysDevIcon icon={GenesysDevIcons.AppThumbsUp} className="feedback-button" />
								</DxButton>
								<DxButton
									type="link"
									onClick={() => {
										handleRelevanceFeedback(false, id, indexId, queryId, matchingData);
									}}
								>
									<GenesysDevIcon icon={GenesysDevIcons.AppThumbsDown} className="feedback-button" />
								</DxButton>
							</div>
						}
					</div>
					<span className="page-title">{result.title}</span>
					{renderPreviewText(result)}
				</div>
			</div>
		);
		
		// TODO: Fix this
		if (origin === Origin.DEVELOPER_CENTER || origin === Origin.API_CENTRAL) {
			return (
				<Link to={url} key={id}>
					{children}
				</Link>
			);
		} else {
			const externalUrl: string = url.startsWith('/') ? url.substring(1) : url;
			return (
				<a href={externalUrl} key={id} target="_blank" rel="noreferrer">
					{children}
				</a>
			);
		}
	}

	// render relevant search suggestions underneath the search bar
	function renderSearchSuggestion(suggestion: SearchSuggestion, index: number): React.ReactNode {
		const { text, highlights, type } = suggestion;
		let currIndex: number = 0;
		return (
			<div className="search-suggestion" key={`suggestion-container-${index}`} onClick={handleSuggestionClick}>
				<div className={`buffer${index === 0 ? '' : ' bordered-suggestion'}`}>
					<div className={type === SuggestionType.HISTORY ? 'history-icon-wrapper' : ''} key={`suggestion-text-${index}`}>
						{type === SuggestionType.HISTORY && <img src={historyIcon} alt="past search" className="history-icon" />}
						{highlights && highlights.length ? (
							highlights.map((highlight: number[], i: number) => {
								const temp: number = currIndex;
								currIndex = highlight[1];
								return (
									<React.Fragment key={`suggestion-fragment-${i}`}>
										<span className={type === SuggestionType.HISTORY ? 'history-text' : ''}>{text.substring(temp, highlight[0])}</span>
										<span className="suggestion-highlight">{text.substring(highlight[0], highlight[1])}</span>
										{i === highlights.length - 1 && <span>{text.substring(highlight[1])}</span>}
									</React.Fragment>
								);
							})
						) : (
							<span className={type === SuggestionType.HISTORY ? 'history-text' : ''}>{text}</span>
						)}
					</div>
					<span className={type === SuggestionType.HISTORY ? 'clear-history-icon-wrapper' : ''}>
						{type === SuggestionType.HISTORY && (
							<GenesysDevIcon
								className="clear-history-icon"
								icon={GenesysDevIcons.AppTimes}
								onClick={() => handleRemoveHistory(suggestion)}
							/>
						)}
						<GenesysDevIcon className={type === SuggestionType.HISTORY ? 'history-search-icon' : ''} icon={GenesysDevIcons.AppSearch} />
					</span>
				</div>
			</div>
		);
	}

	// display autocomplete if there are matching search suggestions and the search bar is focused
	function renderAutocomplete(): React.ReactNode {
		if (!autocompleteText) return;
		return (
			<div className={`search-bar-overlay${autocompleteText.length > searchText.length ? ' overlay-active' : ''}`}>
				<span className="autocomplete-prefix">{autocompleteText.substring(0, searchText.length)}</span>
				<div className="suffix-wrapper" onClick={handleAutocompleteClick}>
					<span className="autocomplete-suffix">{autocompleteText.substring(searchText.length)}</span>
				</div>
			</div>
		);
	}

	function renderRecentSearch(recentSearch: RecentSearch, index: number): React.ReactNode {
		return (
			<div className="search-suggestion" key={`suggestion-container-${index}`} onClick={handleSuggestionClick}>
				<div className={`buffer${index === 0 ? '' : ' bordered-suggestion'}`}>
					<div className="history-icon-wrapper" key={`recent-search-${index}`}>
						<img src={historyIcon} alt="recent search" className="history-icon" />
						<span className="history-text">{recentSearch.searchTerm}</span>
					</div>
					<span className="clear-history-icon-wrapper">
						<GenesysDevIcon
							className="clear-history-icon"
							icon={GenesysDevIcons.AppTimes}
							onClick={() => removeRecentSearch(recentSearch)}
						/>
						<GenesysDevIcon className="history-search-icon" icon={GenesysDevIcons.AppSearch} />
					</span>
				</div>
			</div>
		);
	}

	function renderFilters(): React.ReactNode {
		return (
			<div className="search-filter-container">
				<h5 className="search-filter-title">Search Filters</h5>
				<span className="filter-category-title">Source</span>
				<div className="search-filters">
					{Object.keys(searchFilterConfig.sourceFilters).map((originStr: string, i: number) => {
						const id: string = uuidv4();
						return (
							<div className="option" key={`filter-container-${id}`}>
								<input
									id={`source-filter-${id}`}
									className="input"
									type="checkbox"
									value={originStr}
									checked={searchFilterConfig.sourceFilters[originStr]}
									onChange={handleSourceFilters}
								/>
								<label htmlFor={`source-filter-${id}`} className="label">
									{originMap[originStr]}
								</label>
							</div>
						);
					})}
				</div>
				{searchFilterConfig.sourceFilters[Origin.DEVELOPER_CENTER] && (
					<div onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						// stop bubbling of the event before it reaches the "handleClickOuside" handler
						e.nativeEvent.stopImmediatePropagation();
					}}>
						<span className="filter-category-title">Developer Center content</span>
						<div className="search-filters">
							{Object.entries(DevCenterContentType).map(([k, v], i) => (
								<div className="option"  key={`dev-center-content-filter-${uuidv4()}`}>
									<input
										id={`dev-center-content-filter-${i}`}
										className="input"
										type="checkbox"
										value={v}
										checked={searchFilterConfig.devCenterContentFilters[v]}
										onChange={handleDevCenterContentFilters}
									/>
									<label htmlFor={`dev-center-content-filter-${i}`} className="label">
										<GenesysDevIcon className="filter-icon" icon={searchTools.getContentIcon(v)} />
										{contentTypeMap[v]}
									</label>
								</div>
							))}
						</div>
						<DxToggle
							label="Topic filters"
							initialValue={searchFilterConfig.topicFiltersEnabled}
							onChange={handleDevCenterTopicToggle}
							description="Enable to search Developer Center topics"
						/>
						{searchFilterConfig.topicFiltersEnabled && (
							<React.Fragment>
								<span className="filter-category-title">Developer Center topics</span>
								<div className="search-filters">
									{Object.entries(DevCenterTopic).map(([k, v], i) => (
										<div className="option"  key={`dev-center-topic-filter-${uuidv4()}`}>
											<input
												id={`dev-center-topic-filter-${i}`}
												className="input"
												type="checkbox"
												value={v}
												checked={searchFilterConfig.devCenterTopicFilters[v]}
												onChange={handleDevCenterTopicFilters}
											/>
											<label htmlFor={`dev-center-topic-filter-${i}`} className="label">
												{topicMap[v]}
											</label>
										</div>
									))}
								</div>
							</React.Fragment>
						)}
					</div>
				)}
				{searchFilterConfig.sourceFilters[Origin.RESOURCE_CENTER] && (
					<React.Fragment>
						<span className="filter-category-title">Resource Center content</span>
						<div className="search-filters">
							{Object.entries(HelpContentType).map(([k, v], i) => (
								<div className="option"  key={`help-content-filter-${uuidv4()}`}>
									<input
										id={`help-content-filter-${i}`}
										className="input"
										type="checkbox"
										value={v}
										checked={searchFilterConfig.helpContentFilters[v]}
										onChange={handleHelpContentFilters}
									/>
									<label htmlFor={`help-content-filter-${i}`} className="label">
										<GenesysDevIcon className="filter-icon" icon={searchTools.getContentIcon(v)} />
										{contentTypeMap[v]}
									</label>
								</div>
							))}
						</div>
					</React.Fragment>
				)}
				{searchFilterConfig.sourceFilters[Origin.API_CENTRAL] && (
					<React.Fragment>
						<span className="filter-category-title">API Central content</span>
						<div className="search-filters">
							<div className="option">
								<input
									id="api-central-content-filter-1"
									className="input"
									type="checkbox"
									value={APICentralContentType.API_CENTRAL_DOC}
									checked={searchFilterConfig.apiCentralContentFilters[APICentralContentType.API_CENTRAL_DOC]}
									onChange={handleAPICentralContentFilters}
								/>
								<label htmlFor="api-central-content-filter-1" className="label">
									<GenesysDevIcon className="filter-icon" icon={GenesysDevIcons.DestPages} />
									API Documentation
								</label>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		);
	}

	// get the search data that matches the component instance
	const matchingData: SearchData | undefined = searchData?.find((data: SearchData) => data.id === searchBarId.current);
	const areResultElementsPresent: boolean =
		!!matchingData?.results || !!matchingData?.searchSuggestions || !!matchingData?.isLoading || isFilterExpanded || (showRecent && !searchTerm && recentSearches.length > 0);

	return (
		<div className="search-wrapper">
			<div id={searchBarId.current} className="search" ref={wrapperRef}>
				<div className="search-bar-container">
					<div className={`search-bar${searchTerm ? ' open' : ''}`}>
						<GenesysDevIcon icon={GenesysDevIcons.AppSearch} className="search-icon" />
						<input
							type="text"
							className="search-bar-input"
							placeholder="Search"
							value={searchText}
							onChange={debounce}
							ref={(ref) => setSearchInputRef(ref)}
							onFocus={() => {
								setIsInputFocused(true);
								setShowRecent(true);
							}}
							onBlur={() => {
								setIsInputFocused(false);
							}}
						/>
						{renderAutocomplete()}
						{searchTerm || isInputFocused ? (
							<React.Fragment>
								{!autocompleteText && <GenesysDevIcon icon={GenesysDevIcons.AppTimes} className="clear-icon" onClick={hide} />}
								<GenesysDevIcon
									icon={GenesysDevIcons.AppFilter}
									className="search-filter-icon"
									onClick={() => setIsFilterExpanded(!isFilterExpanded)}
								/>
							</React.Fragment>
						) : (
							<React.Fragment>
								{props.enableHotkey && <span className="search-hotkey">/</span>}
								<GenesysDevIcon
									icon={GenesysDevIcons.AppFilter}
									className="search-filter-icon"
									onClick={() => setIsFilterExpanded(!isFilterExpanded)}
								/>
							</React.Fragment>
						)}
					</div>
				</div>
				<div className="search-results" style={{ display: areResultElementsPresent ? 'block' : 'none' }}>
					{isFilterExpanded && renderFilters()}
					{matchingData?.results && matchingData.results.length > 0 ? (
						matchingData.results.map((result: SearchResult, i: number) => renderSearchResult(result, i))
					) : matchingData?.searchSuggestions && matchingData.searchSuggestions.length ? (
						<React.Fragment>
							<em className="search-result no-pointer">No results, try one of these suggestions</em>
							{matchingData.searchSuggestions.map((suggestion: SearchSuggestion, i) => renderSearchSuggestion(suggestion, i))}
						</React.Fragment>
					) : matchingData?.isLoading && searchTerm.length >= 2 ? (
						<em className="search-result">
							<LoadingPlaceholder />
						</em>
					) : showRecent && !searchTerm && recentSearches.length > 0 ? (
						recentSearches.map((recentSearch: RecentSearch, i: number) => renderRecentSearch(recentSearch, i))
					) : (
						matchingData?.results &&
						matchingData.results.length === 0 && <em className="search-result no-pointer">No results, try another search term</em>
					)}
				</div>
			</div>
		</div>
	);
}
