import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton, LoadingPlaceholder } from 'genesys-react-components';
import { useNavigate } from 'react-router-dom';

import { CategorizedOperations, OperationDetails, TagInfo } from '../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../helpers/openapi/SwaggerCache';
import { selectedThemeAtom } from '../../../theme/ThemeAtom';
import SiteBanner from '../anemia/sitebanner/SiteBanner';
import AppSettings from '../../../helpers/settings/AppSettings';
import { AddApiResource, clearApiResources, removeApiResource, toolboxApiResourcesAtom } from '../../../helpers/atoms/ToolboxAtom';
import OpenAPIExplorer from '../../tools/openapi/OpenAPIExplorer';
import FeedbackWidget from '../../feedback/FeedbackWidget';
import OperationFilterWidget from '../../tools/openapi/OperationFilterWidget';
import Toolbox from '../anemia/toolbox/Toolbox';
import Header from '../anemia/header/Header';
import CategoryNavigation from './CategoryNavigation';
import PathNavigation from './PathNavigation';
import ToggleGroup from './togglegroup/ToggleGroup';
import { ApiResourceProps, ToolboxApp, ApiExplorerNavMode } from '../../../types';

import '../BootstrapBackfill.scss';
import '../anemia/typography.scss';
import '../anemia/movethisstuff.scss';
// This import of the default layout's stylesheet is intentional; this layout is meant to be a derivation of the default layout
import '../DefaultLayout2021.scss';
import '../../../theme/themes.scss';
import './ApiExplorerLayout.scss';

export default function ApiExplorerLayout() {
	const [apiOperations, setApiOperations] = useState<CategorizedOperations>();
	const [filteredApiOperations, setFilteredApiOperations] = useState<CategorizedOperations>();
	const [tags, setTags] = useState<TagInfo[]>([]);
	const theme = useRecoilValue(selectedThemeAtom());
	const isNavCollapsed = useRecoilValue(AppSettings.navigationCollapsedAtom());
	const toolboxApiResources = useRecoilValue(toolboxApiResourcesAtom);
	const selectedOperation = useRecoilValue(AppSettings.apiExplorerStandaloneSelectedOperationAtom());
	const navMode = useRecoilValue(AppSettings.apiExplorerNavModeAtom());
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			const newOps = await SwaggerCache.getCategorizedOperations(SwaggerCache.overrideSwaggerUrl);
			const swagger = await SwaggerCache.get(SwaggerCache.overrideSwaggerUrl);
			const newTags: TagInfo[] = [];

			// Create a list of tag info for ones that have operations
			Object.keys(newOps).forEach((tag) => {
				newTags.push(
					swagger.tags.find((t) => t.name === tag) || {
						name: tag,
						description: '',
					}
				);
			});

			newTags.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1));

			//check if window hash is a link for an operation
			const parameters = window.location.hash.slice(1);
			if (parameters && newOps) {
				//find operation key to match hash
				let operation: ApiResourceProps | undefined;
				Object.entries(newOps as { [tag: string]: OperationDetails[] }).forEach(([tag, operations]) => {
					// Iterate operations
					let foundOp = operations.find((operation) => operation.key === parameters);
					if (foundOp) {
						operation = { path: foundOp.path, verb: foundOp.verb };
						return;
					}
				});
				if (operation) {
					const props = { path: operation.path, verb: operation.verb } as ApiResourceProps;
					AddApiResource({
						title: SwaggerCache.makeOperationTitle(operation.verb, operation.path),
						appType: ToolboxApp.ApiExplorer,
						props,
					});
					AppSettings.setApiExplorerStandaloneSelectedOperation(props);
				}
			}

			setTags(newTags);
			setApiOperations(newOps);
		})();
	}, []);

	useEffect(() => {
		if (toolboxApiResources.length === 0) {
			AppSettings.setNavigationCollapsed(false);
		}
	}, [toolboxApiResources]);

	const hasResources = toolboxApiResources && toolboxApiResources.length > 0;

	return (
		<div className={`layout default-layout api-explorer-layout disappearing-scrollbars ${theme}`}>
			<SiteBanner />
			<Header hideSearch={true}>
				<DxButton type="secondary" onClick={() => navigate('./api-explorer')}>
					Return to {process.env.REACT_APP_SITE_NAME}
				</DxButton>
			</Header>
			<div id="layout-body-div" className={`layout-body${isNavCollapsed ? ' nav-collapsed' : ''}`}>
				{!apiOperations && <LoadingPlaceholder text="Loading API definition" />}
				{apiOperations && (
					<React.Fragment>
						<div className="layout-navigation">
							<GenesysDevIcon
								icon={isNavCollapsed ? GenesysDevIcons.AppChevronRight : GenesysDevIcons.AppChevronLeft}
								className="nav-collapse-icon"
								onClick={() => AppSettings.setNavigationCollapsed(!isNavCollapsed)}
							/>
							<div className="site-navigation operation-list">
								<span className="h7">API Resources</span>
								<OperationFilterWidget apiOperations={apiOperations} onOperationsFiltered={setFilteredApiOperations} />
								<ToggleGroup
									title="Navigation Display Mode"
									options={[
										[ApiExplorerNavMode.Category, 'Category View'],
										[ApiExplorerNavMode.Path, 'Path View'],
									]}
									selectedValue={navMode}
									onSelectionChanged={(value) => AppSettings.setApiExplorerNavMode(value as ApiExplorerNavMode)}
								/>
								{navMode === ApiExplorerNavMode.Category && (
									<CategoryNavigation apiOperations={apiOperations} filteredApiOperations={filteredApiOperations} tags={tags} />
								)}
								{navMode === ApiExplorerNavMode.Path && <PathNavigation operations={filteredApiOperations || apiOperations} />}
							</div>
						</div>
						<div className={`layout-content`}>
							{!hasResources && <div className="no-operations">Select API resources from the left-hand menu to get started</div>}
							{hasResources && (
								<React.Fragment>
									<div className="operation-tab-container">
										{toolboxApiResources.map((item, i) => (
											<div
												key={item.title}
												className={`operation-info operation-${(item.props as ApiResourceProps).verb.toLowerCase()} ${
													selectedOperation &&
													item.title === SwaggerCache.makeOperationTitle(selectedOperation.verb, selectedOperation.path)
														? 'selected'
														: ''
												}`}
												onClick={() => AppSettings.setApiExplorerStandaloneSelectedOperation(item.props as ApiResourceProps)}
											>
												<div className="operation-key" title={item.title}>
													<span className="verb">{(item.props as ApiResourceProps).verb.toUpperCase()}</span>
													<span>{(item.props as ApiResourceProps).path}</span>
												</div>
												<DxButton
													type="link"
													className="remove-operation-button"
													onClick={() => {
														// Select new tab when removing active tab
														if (
															selectedOperation &&
															item.title === SwaggerCache.makeOperationTitle(selectedOperation.verb, selectedOperation.path)
														) {
															// Last tab in list
															if (i === toolboxApiResources.length - 1) {
																if (toolboxApiResources.length > 1) {
																	AppSettings.setApiExplorerStandaloneSelectedOperation(
																		toolboxApiResources[i - 1].props as ApiResourceProps
																	);
																} else {
																	AppSettings.setApiExplorerStandaloneSelectedOperation(undefined);
																}
															} else {
																AppSettings.setApiExplorerStandaloneSelectedOperation(toolboxApiResources[i + 1].props as ApiResourceProps);
															}
														}

														removeApiResource(item);
													}}
												>
													<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />
												</DxButton>
											</div>
										))}
										<DxButton className="close-all-button" type="secondary" onClick={() => clearApiResources()}>
											Close All
										</DxButton>
									</div>
									{selectedOperation && (
										<OpenAPIExplorer
											verb={selectedOperation.verb}
											path={selectedOperation.path}
											showExpanded={true}
											preventCollapse={true}
										/>
									)}
								</React.Fragment>
							)}
							<FeedbackWidget />
						</div>
					</React.Fragment>
				)}
			</div>
			<Toolbox />
		</div>
	);
}
