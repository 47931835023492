import React, { useState, useRef, useEffect } from 'react';
import { DxCheckbox } from 'genesys-react-components';
import { useRecoilValue, useRecoilState } from 'recoil';
import AppSettings from '../../../../../helpers/settings/AppSettings';
import { SettingsPanelProps } from '../../../../../types';
import './DevAppSettings.scss';
import DxLink from '../../../../dxlink/DxLink';
export default function DevAppSettings(props: SettingsPanelProps) {
	const syntaxHighlightingAllowed = useRecoilValue(AppSettings.allowSyntaxHighlightingAtom());
	const [syntaxHighlightingLimitKb, setSyntaxHighlightingLimitKb] = useRecoilState(AppSettings.syntaxHighlightingLimitKbAtom());
	const [limitInputVal, setLimitInputVal] = useState<string>(syntaxHighlightingLimitKb.toString());
	const limitInputValRef: React.MutableRefObject<string> = useRef(limitInputVal);
	const timerRef: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null);
	const debouncing: React.MutableRefObject<boolean> = useRef(false);
	const _setLimitInputVal = (val: string) => {
		limitInputValRef.current = val;
		setLimitInputVal(val);
	};
	// debounce the limit input to reduce latency on the input
	useEffect(() => {
		if (debouncing.current && timerRef.current) {
			clearTimeout(timerRef.current);
		} else {
			convertAndSetLimit(limitInputVal);
		}
		debouncing.current = true;
		timerRef.current = setTimeout(() => {
			debouncing.current = false;
			convertAndSetLimit(limitInputValRef.current);
		}, 300); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [limitInputVal]);
	// sets limit as 0 for NaN and empty string, or the parsed integer otherwise
	const convertAndSetLimit = (val: string) => {
		const limitInt: number = Number.parseInt(val);
		if (Number.isNaN(limitInt)) {
			setSyntaxHighlightingLimitKb(0);
		} else {
			setSyntaxHighlightingLimitKb(limitInt);
		}
	};
	// adds 0 to empty limit input on blur to indicate to the user that 0 is being used as the limit
	const onLimitInputBlur = () => {
		if (limitInputValRef.current === '') {
			_setLimitInputVal('0');
		}
	};
	return (
		<div className="settings dev-app-settings">
			<h2>Developer Application Settings</h2>
			<p>These settings apply to features of the <DxLink href="https://developer.genesys.cloud/devapps/">Developer Applications</DxLink>.</p>
            <h3 className="dev-app-header">API Explorer</h3>
			<h4 className="syntax-highlighting-header">Syntax Highlighting</h4>
			<p>
				Disabling syntax highlighting on API Explorer responses will boost rendering speed, especially for large JSON payloads. You can also adjust
				the max payload size (in kilobytes) above which syntax highlighting will be disabled for API responses.
			</p>
			<DxCheckbox
				label="Allow API response syntax highlighting"
				itemValue="syntax highlighting"
				checked={syntaxHighlightingAllowed}
				onCheckChanged={(checked) => {
					if (checked === syntaxHighlightingAllowed) return;
					AppSettings.setAllowSyntaxHighlighting(checked === true);
				}}
			/>
			<label className="number-input">
				<input
					type="number"
					id="syntax-highlighting-range"
					className={syntaxHighlightingAllowed ? 'payload-limit-input' : ''}
					value={limitInputVal}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => _setLimitInputVal(e.target.value)}
					onBlur={onLimitInputBlur}
					disabled={!syntaxHighlightingAllowed}
				/>
				<span className={`label-text units ${!syntaxHighlightingAllowed && 'disabled'}`}>kb</span>
				<span className={`label-text description ${!syntaxHighlightingAllowed && 'disabled'}`}>
					Max JSON payload size for API response syntax highlighting
				</span>
			</label>
		</div>
	);
}