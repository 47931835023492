import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React from 'react';
import { Link } from 'react-router-dom';

import AssetLoader from '../../helpers/AssetLoader';

import './DxLink.scss';

const externalLinkRegex = /^(?:[a-z]+:)?\/\//i;

interface IProps {
	href: string;
	title?: string;
	children?: any;
	forceNewTab?: boolean;
	className?: string;
}

function DxLink(props: IProps) {
	// Check for external links
	const externalLink = externalLinkRegex.exec(props.href);
	if (externalLink || props.forceNewTab) {
		return (
			<a href={props.href} target="_blank" rel="noopener noreferrer" title={props.title} className={`dx-link ${props.className || ''}`}>
				{props.children}
				<GenesysDevIcon icon={GenesysDevIcons.AppExternalLink} className="dx-link-icon" />
			</a>
		);
	} else {
		let download = false;
		const filenameMatch = /.*(?:\/|^)(.+?)[?#].*$/i.exec(props.href);
		if (filenameMatch) {
			const fn = filenameMatch[1].toLowerCase();
			download = !(fn.endsWith('.md') || fn.endsWith('.html') || fn.endsWith('.pdf') || !fn.includes('.'));
		}
		if (download) {
			return (
				<a
					href={AssetLoader.contentHost + '/' + props.href.replace(/^\//, '')}
					title={props.title}
					target="_blank"
					rel="noopener noreferrer"
					download
					className={`dx-link ${props.className || ''}`}
				>
					{props.children}
				</a>
			);
		} else {
			return (
				<Link to={props.href} title={props.title} className={`dx-link ${props.className || ''}`}>
					{props.children}
				</Link>
			);
		}
	}
}

export default DxLink;
