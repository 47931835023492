import axios, { AxiosInstance, AxiosResponse, AxiosResponseHeaders } from 'axios';

import { ToastType, addToast } from './atoms/ToastAtom';
import {
	User,
	Asset,
	AssetContent,
	getHeader,
	FeedbackSentiment,
	FeedbackRequest,
	PagedNotificationContent,
	MarkReadRequest,
	SubscriptionBase,
	StructuredSubscriptions,
	SubscriptionRequest,
	UserStatus,
} from './CmsApiTypes';

const JWT_KEY = 'yeti-ui-jwt';
const PROPERTY = process.env.REACT_APP_SITE_PROPERTY || '';
const YETI_CMS_API_HOST = 'https://yeticms-api';
class CmsApi {
	api: AxiosInstance;
	isAuthorized: Boolean = false;
	jwt: string;
	currentUser?: User;
	baseURL: string | undefined;

	constructor() {
		// Scrape JWT from hash
		const hash = window.location.hash.substring(1); // remove the '#' symbol
		const params = new URLSearchParams(hash);
		const hashJwt = params.get('jwt');
		const hostname = window.location.hostname;
		const hostRegexPattarn = /\.(test-|dev-)?genesys\.cloud/;
		const matches: RegExpMatchArray | null = hostname.match(hostRegexPattarn);
		if (matches) {
			this.baseURL = YETI_CMS_API_HOST + matches[0];
		}

		if (hashJwt) {
			window.location.hash = '';
		}

		this.jwt = params.get('jwt') || localStorage.getItem(JWT_KEY) || '';
		this.api = axios.create({
			baseURL: this.baseURL,
			headers: {
				Authorization: this.jwt,
			},
			validateStatus: () => true,
		});
	}

	async getAssets(keyPath?: string) {
		let requestPath = `/api/${process.env.REACT_APP_SITE_PROPERTY}/assets`;
		if (keyPath) requestPath += keyPath;
		const res = await this.api.get<Asset[]>(requestPath);
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching assets:', res.status, res.statusText);
		}
	}

	async checkAuthorization() {
		try {
			const res = await this.api.get<User>('/api/users/me');
			this.isAuthorized = res.status === 200;
			if (this.isAuthorized) this.currentUser = normalizeUser(res.data);
			// Save JWT
			if (this.isAuthorized) {
				localStorage.setItem(JWT_KEY, this.jwt);
			}
			return this.isAuthorized;
		} catch (err) {
			console.log('Error fetching user content ', err);
			return false;
		}
	}

	async getAssetContent(draftId: string, asBlob = false): Promise<AssetContent | undefined> {
		try {
			// TODO: genericize this function; it should not know the type of the content it is getting.
			// Any type-specific parsing that's done here should only be based on the content-type header. Calling functions
			// are responsible for applying typings and parsing the response as needed.
			const res = await this.api.get(`/api/${encodeURIComponent(PROPERTY)}/assets/${encodeURIComponent(draftId)}?content=true`, {
				responseType: asBlob ? 'blob' : undefined,
			});

			if (isSuccessful(res.status)) {
				if (!res.data) return undefined;
				return { content: res.data, contentType: getHeader('content-type', res.headers as AxiosResponseHeaders) || '' };
			} else {
				return undefined;
			}
		} catch (err) {
			console.error('Error fetching draft content', err);
			return undefined;
		}
	}

	async postFeedback(message: string, sentiment: FeedbackSentiment) {
		const body: FeedbackRequest = { userId: this.currentUser?.userId || '', url: window.location.href, message, sentiment };
		const res = await this.api.post(`/api/feedback`, body);

		if (isSuccessful(res.status)) {
			return true;
		} else {
			logErrorResponse(res, 'Failed to submit feedback. ' + process.env['REACT_APP_FEEDBACK_FAIL_MESSAGE'], true);
			return false;
		}
	}

	async getNotifications(cursor?: string) {
		let requestPath = '/api/users/me/notifications';
		if (cursor) requestPath += `?cursor=${encodeURIComponent(cursor)}`;
		const res = await this.api.get<PagedNotificationContent>(requestPath);
		//HACK: suppress when disabled
		if (res.status === 501) return { data: [] } as PagedNotificationContent;
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			console.error('Error fetching notifications:', res.status, res.statusText);
		}
	}

	async markNotificationRead(messageId: string, timestamp: number, pinnedUntil?: number) {
		return this.markNotificationsRead([{ messageId, timestamp, pinnedUntil }]);
	}

	async markNotificationsRead(requests: MarkReadRequest[]) {
		const res = await this.api.post<undefined>('/api/users/me/notifications/read', requests);
		//HACK: suppress when disabled
		if (res.status === 501) return;
		if (isSuccessful(res.status)) {
			return;
		} else {
			console.error('Error marking read:', res.status, res.statusText);
		}
	}

	async getSubscriptions() {
		const res = await this.api.get<SubscriptionBase[]>(`/api/users/me/subscriptions`);
		//HACK: suppress when disabled
		if (res.status === 501) return [] as SubscriptionBase[];
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error fetching subscriptions');
			return undefined;
		}
	}

	async getStructuredSubscriptions() {
		const res = await this.api.get<StructuredSubscriptions>(`/api/users/me/subscriptions?structured=true`);
		//HACK: suppress when disabled
		if (res.status === 501) return {} as StructuredSubscriptions;
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error fetching subscriptions');
			return undefined;
		}
	}

	async patchSubscriptions(patchRequest: SubscriptionRequest[]) {
		const res = await this.api.patch<SubscriptionBase[]>(`/api/users/me/subscriptions`, patchRequest);
		//HACK: suppress when disabled
		if (res.status === 501) return [] as SubscriptionBase[];
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error updating subscriptions');
			return undefined;
		}
	}

	async patchStructuredSubscriptions(patchRequest: SubscriptionRequest[]) {
		const res = await this.api.patch<StructuredSubscriptions>(`/api/users/me/subscriptions?structured=true`, patchRequest);
		//HACK: suppress when disabled
		if (res.status === 501) return {} as StructuredSubscriptions;
		if (isSuccessful(res.status)) {
			return res.data;
		} else {
			logErrorResponse(res, 'Error updating subscriptions');
			return undefined;
		}
	}
}

function isSuccessful(httpStatus: number) {
	return httpStatus >= 200 && httpStatus < 300;
}

function logErrorResponse(res: AxiosResponse, message?: string, noTimeout = false): void {
	console.log(res);
	console.error(`Error invoking ${res.config.method} ${res.config.url}:`, res.status, res.statusText, '\n', res.data, '\n', res);
	addToast({
		toastType: ToastType.Critical,
		title: 'API Error',
		message:
			message ||
			'An unexpected error has occurred fetching data from the API. Application functionality may be impacted. See the JavaScript console for details.',
		timeoutSeconds: noTimeout ? undefined : 30,
	});
}

// normalizeUser mutates the user object to ensure defaults are set
export function normalizeUser(user: User): User {
	user.userId = user.userId || '00000000-0000-0000-0000-00000000000';
	user.firstName = user.firstName || 'Unknown';
	user.lastName = user.lastName || 'User';
	user.displayName = user.displayName || `${user.firstName} ${user.lastName}`;
	user.status = user.status || UserStatus.Active;
	user.email = user.email || 'unknown.user@genesys.com';
	user.permissions = user.permissions || [];
	return user;
}

export default new CmsApi();
