import { atom } from 'recoil';
import { setRecoil } from 'recoil-nexus';

export interface Heading {
	title: string;
	link: string;
	level: number;
}

export const inPageHeadingsAtom = atom({
	key: 'inPageHeadings',
	default: undefined as Heading[] | undefined,
});

export function setHeadings(headings: Heading[]) {
	setRecoil(inPageHeadingsAtom, headings.length > 0 ? headings : undefined);
}

export function clearHeadings() {
	setRecoil(inPageHeadingsAtom, undefined);
}
