import React from 'react';
import { useRecoilValue } from 'recoil';

import {
	getRequestDataBodyAtom,
	getRequestDataHeadersAtom,
	getRequestDataParametersAtom,
} from '../../../../helpers/atoms/APIExplorerRequestCache';
import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import CodeFence from '../../../codefence/CodeFence';
import { selectedAccountAtom } from '../../../../helpers/atoms/AccountsAtom';
import SwaggerCache from '../../../../helpers/openapi/SwaggerCache';

interface IProps {
	operationDetails: OperationDetails;
	host?: string;
}

export default function OperationHttpDisplay(props: IProps) {
	const selectedAccount = useRecoilValue(selectedAccountAtom);
	const parameterData = useRecoilValue(getRequestDataParametersAtom(props.operationDetails.operation.operationId));
	const headerData = useRecoilValue(getRequestDataHeadersAtom(props.operationDetails.operation.operationId));
	const bodyData = useRecoilValue(getRequestDataBodyAtom(props.operationDetails.operation.operationId));

	// Prepare path
	let requestPath = props.operationDetails.path;
	if (parameterData) {
		Object.entries(parameterData).forEach(([key, value]) => {
			if (!value) return;
			requestPath = requestPath.replaceAll(new RegExp(`{${key}}`, 'gi'), encodeURIComponent(value as string));
		});
	}

	// Prepare query string
	const queryParameters = props.operationDetails.operation.parameters?.filter((param) => param.in === 'query') || [];
	const queryString = queryParameters
		.map((param) =>
			parameterData && parameterData[param.name] !== undefined
				? `${param.name}=${
						Array.isArray(parameterData[param.name])
							? (parameterData[param.name] as string[]).map(encodeURIComponent).join(',')
							: encodeURIComponent(parameterData[param.name] as string)
				  }`
				: undefined
		)
		.filter((value) => !!value)
		.join('&');

	let hostname = props.host || 'api.mypurecloud.com';
	if (SwaggerCache.overrideApiHost) {
		const overrideUrl = new URL(SwaggerCache.overrideApiHost || '');
		hostname = overrideUrl.hostname;
		if (overrideUrl.port && overrideUrl.port !== '80' && overrideUrl.port !== '443') hostname += `:${overrideUrl.port}`;
	} else if (selectedAccount?.api.defaults.baseURL) hostname = new URL(selectedAccount?.api.defaults.baseURL || '').hostname;

	// Build display value
	let value = `${props.operationDetails.verb.toUpperCase()} ${requestPath}${queryString ? '?' + queryString : ''} HTTP/1.1
Host: ${hostname}
${
	headerData
		?.filter((data) => data['value'] !== undefined && data['value'].trim().length !== 0)
		?.map((data) => `${data.name}: ${data.prefix || ''}${data.isSecret ? data.value.replaceAll(/./gi, '*') : data.value}`)
		.join('\n') || ''
}${bodyData ? '\n\n' + JSON.stringify(bodyData) : ''}`;

	return (
		<div>
			<CodeFence value={value} language="http" />
		</div>
	);
}
