import React, { useEffect, useRef, useState } from 'react';
import { AlertBlock, DxButton, DxItemGroup, DxItemGroupItem, DxTextbox, LoadingPlaceholder } from 'genesys-react-components';

import AssetLoader from '../../../helpers/AssetLoader';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import { Language, toSdkLanguageName } from '../../../types';
import MarkdownDisplay from '../../markdown/display/MarkdownDisplay';

import './SdkDocExplorer.scss';
import DxLink from '../../dxlink/DxLink';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const APP_PATH = '/devapps/sdk/docexplorer';

export default function SdkDocExplorer() {
	const [sdkLanguage, setSdkLanguage] = useState<string | undefined>();
	const [docList, setDocList] = useState<string[]>([]);
	const [docFilter, setDocFilter] = useState<string>('');
	const [appPathname, setAppPathname] = useState<string>('');
	const [docContent, setDocContent] = useState<string | undefined>();
	const pageTopRef = useRef<HTMLSpanElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [scrollAnchorName, setScrollAnchorName] = useState<string | undefined>();
	const navigate = useNavigate();
	const location = useLocation();

	// Constructor
	useEffect(() => {
		// Select SDK based on path
		const pathParts = location.pathname.split('/');
		if (pathParts.length >= 6) {
			setSdkLanguage(pathParts[4]);
			setAppPathname(location.pathname);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setScrollAnchorName(window.location.hash?.replace('#', ''));
	}, [location]);

	// Synchronize app pathname when window pathname changes
	useEffect(() => {
		if (location.pathname !== appPathname) {
			const pathParts = location.pathname.split('/');
			if (pathParts.length >= 6) {
				setSdkLanguage(pathParts[4]);
				setAppPathname(location.pathname);
				setScrollAnchorName(window.location.hash?.replace('#', ''));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	// Scroll to anchor on change
	useEffect(() => {
		if (!scrollAnchorName) return;
		let el: HTMLElement | null = document.getElementsByName(scrollAnchorName)[0];
		if (!el) el = document.getElementById(scrollAnchorName);
		el?.scrollIntoView();
	}, [scrollAnchorName]);

	// React to change in SDK language
	useEffect(() => {
		if (!sdkLanguage) return;

		setIsLoading(true);
		AssetLoader.get(`/data/docexplorer/${sdkLanguage}.json`, true)
			.then((data) => {
				setDocList(data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				addToast({ title: 'Loading SDK doc list failed', message: err.message || 'Failed to load data', toastType: ToastType.Critical });
			});
	}, [sdkLanguage]);

	// Load document when app pathname changes
	useEffect(() => {
		if (!appPathname) return;

		setIsLoading(true);
		AssetLoader.get(appPathname + (appPathname.endsWith('/') ? 'index' : '') + '.md')
			.then((data) => {
				setDocContent(data);
				setIsLoading(false);
				if (window.location.hash) {
					// Delay this a little to give the DOM time to render so page doesn't jump due to a render after scrolling
					setTimeout(() => {
						setScrollAnchorName(window.location.hash?.replace('#', ''));
					}, 100);
				} else {
					pageTopRef.current?.scrollIntoView();
				}
			})
			.catch((err) => {
				console.error(err);
				addToast({ title: 'Loading SDK doc failed', message: err.message || 'Failed to load page', toastType: ToastType.Critical });
			});
	}, [appPathname]);

	const userNav = (pathname: string) => {
		navigate(pathname);
		setAppPathname(pathname);
	};

	return (
		<React.Fragment>
			<AlertBlock title="Temporary Page" alertType="warning">
				<p>This page contains very large amounts of content and may not be performant at times.</p>
				<p>
					This SDK documentation viewer is a temporary solution. The SDK docs on this page will be replaced in the future. Keep an eye on
					the <DxLink href="https://developer.genesys.cloud/forum/c/announcements/20">Genesys Cloud Developer Forum Announcements</DxLink>{' '}
					for more upcoming announcements.
				</p>
			</AlertBlock>
			<div className="sdk-doc-explorer">
				<span className="top-ref" ref={pageTopRef}></span>
				<div className="sdk-doc-explorer-sidebar">
					<DxItemGroup
						title="SDK Language"
						format="dropdown"
						items={[
							{ label: '---Select SDK---', value: '' } as DxItemGroupItem,
							...Object.values(Language).map((lang) => {
								return { label: toSdkLanguageName(lang as Language), value: lang, isSelected: lang === sdkLanguage } as DxItemGroupItem;
							}),
						]}
						onItemChanged={(item) => {
							navigate(`${APP_PATH}/${item.value}/`);
						}}
					/>
					<DxTextbox
						label="File Filter"
						placeholder="E.g. usersapi"
						onChange={(value) => setDocFilter(value.trim().toLowerCase().replace(/\s/g, ''))}
					/>
					{sdkLanguage && docList.length > 0 && (
						<ul className="doc-list">
							<li>
								<Link to={`${APP_PATH}/${sdkLanguage}/`}>{toSdkLanguageName(sdkLanguage as Language)} Overview</Link>
							</li>
							{docList
								.filter((docFile) => !docFilter || docFile.toLowerCase().includes(docFilter))
								.map((docFile, i) => (
									<li key={i}>
										<DxButton type="link" onClick={() => userNav(`${APP_PATH}/${sdkLanguage}/${docFile.replace(/.md$/i, '')}`)}>
											{docFile.replace(/.md$/i, '')}
										</DxButton>
									</li>
								))}
						</ul>
					)}
				</div>
				{!isLoading && docContent && (
					<div className="doc-content">
						{appPathname.endsWith('/') && <h1>{toSdkLanguageName(sdkLanguage as Language)}</h1>}
						<MarkdownDisplay markdown={docContent} />
					</div>
				)}
				{isLoading && <LoadingPlaceholder />}
			</div>
		</React.Fragment>
	);
}
