export interface Asset {
	keyPath: string;
	version?: string;
	authorId: string;
	title: string;
	attributes?: { [attributeName: string]: string };
	public?: boolean;
	assetType: AssetType;
	contentType: ContentType | string;
}

export interface AssetContent {
	content: any;
	contentType: string;
}

export enum AssetType {
	Document = 'document',
	Image = 'image',
}

export enum ContentType {
	JSON = 'application/json',
	Markdown = 'application/markdown',
	Text = 'text/plain',
	PNG = 'image/png',
	JPG = 'image/jpeg',
	SVG = 'image/svg+xml',
}

export interface User {
	userId: string;
	firstName: string;
	lastName: string;
	displayName: string;
	status: UserStatus;
	email: string;
	permissions: PermissionKey[];
	title?: string;
	organization?: string;
	officeLocation?: string;
	profileImage?: {
		gravatar?: string;
	};
	githubName?: string;
	lastLogin?: string;
}

export enum UserStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export enum PermissionKey {
	YetitorApiCentralAll = 'yetitor:api-central:*',
	YetitorGcDeveloperCenterAll = 'yetitor:gc-dev-center:*',
	YetitorYetiCmsDocsAll = 'yetitor:yeti-cms-docs:*',
	CmsAdminGroupCreate = 'cms-admin:group:create',
	CmsAdminGroupManage = 'cms-admin:group:manage',
	CmsAdminUserUpdate = 'cms-admin:user:update',
	CmsAdminPermissionManage = 'cms-admin:permission:manage',
	CmsAdminChangeSetForcePublish = 'cms-admin:change-set:force-publish',
	CmsAdminTokensManage = 'cms-admin:tokens:manage',
	OpenSourceAppAll = 'open-source:app:*',
	OpenSourceRepoRequest = 'open-source:repo:request',
	OpenSourceRepoManage = 'open-source:repo:manage',
}

export interface FeedbackRequest {
	userId: string;
	url: string;
	message: string;
	sentiment: FeedbackSentiment;
}

export enum FeedbackSentiment {
	Positive = 'positive',
	Negative = 'negative',
	Bug = 'bug',
	None = '',
}

export function getHeader(key: string, headers: { [key: string]: string }) {
	const lckey = key.toLowerCase();
	const header = Object.entries(headers).find(([headerKey, value]) => headerKey.toLowerCase() === lckey);
	return header ? header[1] : undefined;
}

export async function readBlob(imageBlob: Blob) {
	const reader = new FileReader();
	return new Promise<string>((resolve) => {
		reader.onloadend = () => resolve(reader.result as string);
		reader.readAsDataURL(imageBlob);
	});
}

export interface NotificationContent {
	messageId: string;
	timestamp: number;
	pinnedUntil?: number;
	subject?: string;
	content: string;
	link?: string;
	read?: boolean;
}

export interface PagedNotificationContent {
	cursor?: string;
	data: NotificationContent[];
}

export interface MarkReadRequest {
	messageId: string;
	timestamp: number;
	pinnedUntil?: number;
}

export enum NotificationMode {
	Unknown = '',
	Email = 'email',
	Blast = 'blast',
	DigestDaily = 'digest-daily',
	DigestWeekly = 'digest-weekly',
	App = 'app',
}

export enum TopicKey {
	YetiCmsPublishTag = 'yeti-cms.{property}.publish.tag.{tag}',
	YetiCmsPublishPath = 'yeti-cms.{property}.publish.path.{keyPath}',
	YetiCmsActivity = 'yeti-cms.activity.{!userId}',
	YetiCmsDevNewsletterPublish = 'yeti-cms.devnewsletter.publish',
	YetiCmsAnnouncement = 'yeti-cms.announcements.{property}.type.{type}',
	OssConfigError = 'oss.config-error.{userId}',
	OssActivity = 'oss.activity.{!userId}',
}

export interface SubscriptionBase {
	topic: string;
	mode: NotificationMode;
}

export interface StructuredSubscription {
	topic: string;
	mode: NotificationMode;
	params: { [key: string]: string };
}

export type StructuredSubscriptions = {
	[key in TopicKey]: StructuredSubscription[] | undefined;
};

export interface SubscriptionRequest {
	topic: string;
	mode?: NotificationMode;
	remove?: boolean;
}
