export interface OpenAPIDefinition {
	swagger: string;
	info: Info;
	host: string;
	tags: TagInfo[];
	schemes: string[];
	consumes: string[];
	produces: string[];
	paths: PathList;
	//TODO
	securityDefinitions: any;
	definitions: any;
	responses: any;
	externalDocs: any;
}

export interface Info {
	description: string;
	version: string;
	title: string;
	termsOfService: string;
	contact: ContactInfo;
	license: LicenseInfo;
}

export interface ContactInfo {
	name: string;
	url: string;
	email: string;
}

export interface LicenseInfo {
	name: string;
	url: string;
}

export interface TagInfo {
	name: string;
	description: string;
	externalDocs?: ExternalDocInfo;
}

export interface ExternalDocInfo {
	description: string;
	url: string;
}

export interface PathList {
	[index: string]: PathGroup;
}

export enum HTTPMethods {
	GET = 'get',
	PUT = 'put',
	POST = 'post',
	DELETE = 'delete',
	OPTIONS = 'options',
	HEAD = 'head',
	PATCH = 'patch',
}

export enum EntityTypeHints {
	INTERVAL = 'INTERVAL',
	USER_ID = 'USER_ID',
	QUEUE_ID = 'QUEUE_ID',
	ORG_PRESENCE_ID = 'ORG_PRESENCE_ID',
	SYSTEM_PRESENCE_ID = 'SYSTEM_PRESENCE_ID',
	DIVISION_ID = 'DIVISION_ID',
	MEDIA_TYPE_ID = 'MEDIA_TYPE_ID',
	DIMENSION_TYPE = 'DIMENSION_TYPE',
	DIMENSION_SELECTOR = 'DIMENSION_SELECTOR',
}

export interface PathGroup {
	[index: string]: OperationInfo | undefined;
	get?: OperationInfo;
	put?: OperationInfo;
	post?: OperationInfo;
	delete?: OperationInfo;
	options?: OperationInfo;
	head?: OperationInfo;
	patch?: OperationInfo;
}

export interface OperationInfo {
	tags: string[];
	summary: string;
	description: string;
	operationId: string;
	produces: string[];
	parameters?: ParameterInfo[];
	responses: ResponseList;
	security?: SecurityInfo[];
	deprecated?: boolean;
	'x-purecloud-method-name': string;
	'x-inin-requires-permissions': RequiresPermissions;
	'x-genesys-visibility'?: VisibilityType;
}

export interface ParameterInfo {
	name: string;
	in: string;
	description?: string;
	required: boolean;
	type: string;
	items?: ItemsObject;
	default?: any;
	format?: string;
	schema?: SchemaRef;
	enum?: string[];
}

export interface ItemsObject {
	type: string;
	format?: string;
	items?: ItemsObject;
	collectionFormat?: string;
	default?: any;
	maximum?: number;
	exclusiveMaximum?: boolean;
	minimum?: number;
	exclusiveMinimum?: boolean;
	maxLength?: number;
	minLength?: number;
	pattern?: string;
	maxItems?: number;
	minItems?: number;
	uniqueItems?: boolean;
	enum?: any[];
	multipleOf?: number;
}

export interface SchemaRef {
	$ref: string;
}

export interface ResponseList {
	[statusCode: number]: ResponseInfo;
}

export interface ResponseInfo {
	description: string;
	schema?: SchemaRef;
	'x-inin-error-codes': ErrorCodeList;
}

export interface SecurityInfo {
	[name: string]: string[];
}

export interface ModelDefinitions {
	[modelName: string]: ModelSchema;
}

export interface ModelSchema {
	// Commonly used properties
	$ref?: string;
	type?: string;
	format?: string;
	default?: boolean;
	enum?: string[];
	items?: ModelSchema;
	properties?: ModelProperty;
	additionalProperties?: ModelSchema;
	required?: string[];
	readOnly?: boolean;
	description?: string;

	// Genesys vendor extensions
	'x-genesys-enum-members'?: EnumMembers[];
	'x-genesys-entity-type'?: EntityType;
	'x-genesys-search-fields'?: SearchFields;

	// Types added by this app
	// The name of the model resolved from a reference (e.g. "CallConversation" from "#/definitions/CallConversation")
	__modelName?: string;
	// The name of the property that references this definition
	__propertyName?: string;
	// [true] if it's an arbitrary object with no known type information
	__isPrimitiveObject?: boolean;
	// [true] if it's meant to be a reference to a model that's already been resolved in the model's schema
	__isRecursive?: boolean;
	// [true] if the type is being used as the value in a map
	__isMap?: boolean;
	// [true] if the schema is meant to be an array member
	__isArrayMember?: boolean;
	// [true] if property is in the required list
	__isRequired?: boolean;

	// Other properties in the OpenAPI 2.0 spec
	title?: any;
	multipleOf?: any;
	maximum?: any;
	exclusiveMaximum?: any;
	minimum?: any;
	exclusiveMinimum?: any;
	maxLength?: any;
	minLength?: any;
	pattern?: any;
	maxItems?: any;
	minItems?: any;
	uniqueItems?: any;
	maxProperties?: any;
	minProperties?: any;
	allOf?: any;
	discriminator?: any;
	xml?: any;
	externalDocs?: any;
	example?: any;
}

export interface ModelProperty {
	[propertyName: string]: ModelSchema;
}

/**
 * VENDOR EXTENSION TYPES
 */

// x-inin-error-codes
export interface ErrorCodeList {
	[code: string]: string;
}

// x-inin-requires-permissions
export interface RequiresPermissions {
	type: string;
	permissions: string[];
}

// x-genesys-enum-members
export interface EnumMembers {
	name: string;
	description?: string;
}

// x-genesys-entity-type
export interface EntityType {
	value: EntityTypeHints;
}

// x-genesys-search-fields
export interface SearchFields {
	value: string[];
}

// x-genesys-visbility
export enum VisibilityType {
	Public = 'public',
	Preview = 'preview',
	Internal = 'internal',
	Unstable = 'unstable',
}

export const visibilityMap = {
	public: 'Public',
	preview: 'Preview',
	internal: 'Internal',
	unstable: 'Unstable',
};

/**
 * CUSTOM TYPES
 */

// Custom wrapper for an operation for UI use
export interface OperationDetails {
	key: string;
	title: string;
	verb: string;
	path: string;
	description: string;
	tags: string[];
	operation: OperationInfo;
}

export interface CategorizedOperations {
	[tag: string]: OperationDetails[] | undefined;
}
export interface PathSegments {
	[segment: string]: PathSegmentInfo | undefined;
}

export interface PathSegmentInfo {
	path: string;
	segment: string;
	operations: OperationDetails[];
	segments: PathSegments;
}

// Property data that has been normalized with default values
export interface NormalizedProperty {
	schema: ModelSchema;
	id: string;
	propertyName: string;
	typeDisplay: string;
	className: string;
	inlineComponent?: JSX.Element;
	blockComponent?: JSX.Element;
}

/**
 * Model editor
 */

export interface ValueUpdatedCallback {
	(propertyName: string, value: any, arrayIndex?: number): void;
}

export enum UpdateSource {
	INIT,
	WIZARD,
	JSON,
}

export interface BodyEditorValueCallback {
	(propertyName: string, value: any, updateSource: UpdateSource): void;
}
