export class Sitemap {
	sitemap: SitemapData;

	constructor(sitemap: SitemapData) {
		this.sitemap = sitemap;
	}

	// Gets a page by its path
	public getPage(pathname: String, returnDirs = false) {
		if (!pathname) return;

		// Quick return for root
		if (pathname === '/') return returnDirs ? this.sitemap : this.sitemap.index;

		// Drill down to current directory
		let context = this.sitemap;
		let parts = pathname.split('/').filter((part) => part !== '');
		let found;
		if (parts.length > 0) {
			found = parts.every((part) => {
				if (!context[part]) return false;
				context = context[part];
				return true;
			});
		}

		// Abort if page not found
		if (!found) return;

		// Figure out what to return
		const ret = context.isDir && !returnDirs ? context.index : context;
		return ret;
	}
}

// Determines if a page should be displayed in navigation and list contexts
export function isPageVisible(page?: Page): boolean {
	if (!page) return false;

	// Look at index page if is dir
	if (page.index && typeof page.index === 'object') return isPageVisible(page.index);

	// Look at page
	return page && (page.forceShow || (!page.notoc && !page.private && !page.redirect && !page.hardRedirect));
}

export interface SitemapData {
	// Directories with content must have an index page
	index?: Page;
	// Ony set on dirs and always true when set
	isDir: boolean;
	// Arbitrary frontmatter properties
	[pathpart: string]: SitemapData | Page | any;
}

export interface Page {
	// Page title
	title: string;
	// Group display name, only used on index pages for display in the nav tree
	group?: string;
	// Intrasite link to the page
	link: string;
	// Arbitrary frontmatter properties
	[pathpart: string]: SitemapData | any;
}
