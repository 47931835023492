import React, { useEffect, useState } from 'react';

import Card, { CardStyles } from './Card';
import { addToast, ToastType } from '../../helpers/atoms/ToastAtom';
import { ContentMediaData } from '../../helpers/AssetLoaderTypes';
import { GenesysDevIcons } from 'genesys-dev-icons';
import Paginator, { Pagination } from '../paginator/Paginator';
import AssetLoader from '../../helpers/AssetLoader';

import './CardCatalog.scss';
import { FilterGroup, setFilters } from '../../helpers/atoms/InPageFilters';
import { DxItemGroupItem, DxItemGroupItemValue, LoadingPlaceholder } from 'genesys-react-components';
import TaxonomyCategories from '../../helpers/TaxonomyCategories';

interface IProps {
	source?: any;
	cardStyle?: CardStyles;
	useCategorySubtitle?: boolean;
}

const MIN_PAGE_SIZE = 6;
const normalSort = (a: String, b: String) => (a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0);

export default function CardCatalog(props: IProps) {
	const [cardData, setCardData] = useState<ContentMediaData[] | undefined>();
	const [filteredCards, setFilteredCards] = useState<ContentMediaData[] | undefined>();
	const [pagination, setPagination] = useState<Pagination>({ startItem: 1, endItem: MIN_PAGE_SIZE, page: 1, pageSize: MIN_PAGE_SIZE });
	const [tagFilters, setTagFilters] = useState<DxItemGroupItemValue[]>([]);
	const [categoryFilters, setCategoryFilters] = useState<DxItemGroupItemValue[]>([]);

	// Constructor
	useEffect(() => {
		if (props.source) {
			AssetLoader.get(props.source, true)
				.then((data: ContentMediaData[]) => {
					// Ensure display order is maintained
					data.sort((a, b) => (a.order === b.order ? 0 : a < b ? 1 : -1));
					setCardData(data);
					setFilteredCards(data);

					// Scrub and normalize data
					data.forEach((val) => {
						// Resolve and overwrite legacy category numbers
						if (Number.isInteger(val.category)) {
							val.category = TaxonomyCategories.getKeyFromLegacyId(parseInt(val.category || ''));
						}

						// Set subtitle to category display
						if (props.useCategorySubtitle && val.category) {
							val.subtitle = TaxonomyCategories.categoryKeyData[val.category]?.displayName;
						}
					});

					// Scrape data for filters
					const filterData = [] as FilterGroup[];
					filterData.push({
						label: 'Tags',
						options:
							// Split tag data by commas, flatten, and remove duplicates
							Array.from(new Set(data.map((val) => (val.tags || '').split(',').map((val) => val.trim())).flat()).values())
								// Remove empty entries
								.filter((val) => val !== '' && val !== undefined)
								// Sort alphabetically
								.sort(normalSort)
								// Translate to filter items
								.map((val) => {
									return { label: val, value: val } as DxItemGroupItem;
								}),
						onItemsChanged: (items: DxItemGroupItemValue[]) => setTagFilters(items),
					});
					filterData.push({
						label: 'Category',
						options:
							// Normalize categories and remove duplicates
							Array.from(new Set(data.map((val) => val.category || '')).values())
								// Remove empty entries
								.filter((val) => val !== '' && val !== undefined)
								// Sort alphabetically
								.sort(normalSort)
								// Translate to filter items
								.map((val) => {
									return { label: TaxonomyCategories.categoryKeyData[val]?.displayName, value: val } as DxItemGroupItem;
								}),
						onItemsChanged: (items: DxItemGroupItemValue[]) => setCategoryFilters(items),
					});
					setFilters(filterData);
				})
				.catch((err) => {
					console.error(err);
					addToast({ title: 'Error loading guide data', message: err.message || 'unknown error', toastType: ToastType.Critical });
				});
		}

		return () => {
			setFilters(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!cardData) return;

		// Filter tags
		let activeFilters = tagFilters.filter((f) => f.isSelected).map((f) => f.item.value);
		let cards = [...cardData];
		if (activeFilters.length > 0) {
			cards = cards.filter((card) => activeFilters.some((value) => card.tags?.includes(value)));
		}

		// Filter categories
		activeFilters = categoryFilters.filter((f) => f.isSelected).map((f) => f.item.value);
		if (activeFilters.length > 0) {
			cards = cards.filter((card) => activeFilters.some((value) => card.category?.includes(value)));
		}

		setFilteredCards(cards);
	}, [cardData, categoryFilters, tagFilters]);

	return filteredCards ? (
		<div className="card-catalog">
			<div className="card-list">
				{filteredCards.slice(pagination.startItem - 1, pagination.endItem).map((d, i) => (
					<div className="card-container" key={i}>
						<Card data={d} icon={d.icon as GenesysDevIcons | undefined} cardStyle={props.cardStyle} />
					</div>
				))}
			</div>
			<Paginator itemCount={filteredCards.length} minPageSize={MIN_PAGE_SIZE} onPaginationChanged={(p) => setPagination(p)} />
		</div>
	) : (
		<LoadingPlaceholder />
	);
}
