import React, { useRef, useState } from 'react';
import { DocumentNode } from 'yeast-core';
import { MarkdownParser } from 'yeast-markdown-parser';
import { YeastDocumentRenderer } from 'yeast-react-renderer';

import './MarkdownEditor.scss';

export default function MarkdownEditor() {
	const [ast, setAst] = useState<DocumentNode | undefined>(undefined);
	const timer = useRef<NodeJS.Timeout>();

	const textChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (timer.current) clearTimeout(timer.current);
		timer.current = setTimeout(() => {
			const parser = new MarkdownParser();
			setAst(parser.parse(e.target.value) as DocumentNode);
		}, 300);
	};

	return (
		<div className="markdown-editor">
			<div>
				<textarea onChange={textChanged} />
			</div>
			<div>{ast && <YeastDocumentRenderer ast={ast} />}</div>
		</div>
	);
}
