import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	id: string;
	level: number;
	children: ReactNode;
}

export default function SectionHeading(props: IProps) {
	return (
		<Link to={'#' + props.id} id={props.id} className={'toc-link toc-link-h' + props.level}>
			{React.createElement(`h${props.level}`, {}, [
				props.children,
				<GenesysDevIcon key={props.id} icon={GenesysDevIcons.AppLink} className="toc-link-icon" />,
			])}
		</Link>
	);
}
