import { CategorizedOperations, OperationDetails, PathSegmentInfo } from '../../../helpers/openapi/OpenAPITypes';
import PathSegment from './PathSegment';

import './PathNavigation.scss';

interface IProps {
	operations: CategorizedOperations;
}

export default function PathNavigation(props: IProps) {
	const root: PathSegmentInfo = {
		path: '',
		segment: '',
		operations: [],
		segments: {},
	};
	const addOperation = (pathParts: string[], op: OperationDetails, haystack: PathSegmentInfo): void => {
		const key = pathParts.shift();
		// Too deep!
		if (!key) return;

		// Lazy init segment
		if (!haystack.segments[key]) {
			haystack.segments[key] = {
				path: `${haystack.path || ''}/${key}`,
				segment: key,
				operations: [],
				segments: {},
			};
		}

		// End of path?
		if (pathParts.length === 0) {
			haystack.segments[key]?.operations.push(op);
			return;
		}

		// Process segment
		return addOperation(pathParts, op, haystack.segments[key]!);
	};
	Object.values(props.operations).forEach((opgroup) =>
		opgroup?.forEach((op) =>
			addOperation(
				op.path.split('/').filter((v) => !!v),
				op,
				root
			)
		)
	);

	return (
		<div className="path-navigation">
			{(Object.values(root.segments) as PathSegmentInfo[]).map((segment) => (
				<PathSegment key={segment.path} segment={segment} pushOpenDepth={2} />
			))}
		</div>
	);
}
