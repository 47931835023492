import React from 'react';
import { useRecoilValue } from 'recoil';

import { selectedAccountAtom } from '../../../../helpers/atoms/AccountsAtom';
import {
	getRequestDataParametersAtom,
	getRequestDataHeadersAtom,
	getRequestDataBodyAtom,
} from '../../../../helpers/atoms/APIExplorerRequestCache';
import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import CodeFence from '../../../codefence/CodeFence';

interface IProps {
	operationDetails: OperationDetails;
	host?: string;
}

export default function OperationCurlDisplay(props: IProps) {
	const selectedAccount = useRecoilValue(selectedAccountAtom);
	const parameterData = useRecoilValue(getRequestDataParametersAtom(props.operationDetails.operation.operationId));
	const headerData = useRecoilValue(getRequestDataHeadersAtom(props.operationDetails.operation.operationId));
	const bodyData = useRecoilValue(getRequestDataBodyAtom(props.operationDetails.operation.operationId));

	// Prepare path
	let requestPath = props.operationDetails.path;
	if (parameterData) {
		Object.entries(parameterData).forEach(([key, value]) => {
			if (!value) return;
			requestPath = requestPath.replaceAll(new RegExp(`{${key}}`, 'gi'), encodeURIComponent(value as string));
		});
	}

	// Prepare query string
	const queryParameters = props.operationDetails.operation.parameters?.filter((param) => param.in === 'query') || [];
	const queryString = queryParameters
		.map((param) =>
			parameterData && parameterData[param.name] !== undefined
				? `${param.name}=${
						Array.isArray(parameterData[param.name])
							? (parameterData[param.name] as string[]).map(encodeURIComponent).join(',')
							: encodeURIComponent(parameterData[param.name] as string)
				  }`
				: undefined
		)
		.filter((value) => !!value)
		.join('&');

	//add verb
	let verb = props.operationDetails.verb;
	// Build display value
	let value: string = `curl ${verb && '-X ' + verb.toUpperCase()} 'https://${
		selectedAccount?.api.defaults.baseURL
			? new URL(selectedAccount?.api.defaults.baseURL || '').hostname
			: props.host || 'api.mypurecloud.com'
	}${requestPath}${queryString ? '?' + queryString : ''}' \\
${
	headerData
		?.filter((data) => data['value'] !== undefined && data['value'].trim().length !== 0)
		?.map((data) => `  -H '${data.name}: ${data.prefix || ''}${data.isSecret ? data.value.replaceAll(/./gi, '*') : data.value}'`)
		.join(' \\\n') || ''
}${bodyData ? ` \\\n  --data-raw $'${JSON.stringify(bodyData).replaceAll(/'/gi, "\\'")}'` : ''}`;
	return (
		<div className="CURL">
			<CodeFence value={value} language="bash" />
		</div>
	);
}
