import React from 'react';
import AssetLoader from '../../helpers/AssetLoader';

interface IProps {
	src: string;
	alt?: string;
	className?: string;
}

export default function ContentImage(props: IProps) {
	return <img src={makeAbsoluteUrl(props.src)} alt={props.alt} className={props.className} />;
}

function makeAbsoluteUrl(src: string) {
	return AssetLoader.contentHost + src.replace(/^\/+/, '/');
}
