import React, { useState, useEffect } from 'react';
import { useSitemap } from '../../../../helpers/atoms/SitemapAtom';
import { SitemapData } from '../../../../helpers/Sitemap';

import NavGroup from './NavGroup';

import './SiteNavigation.scss';

const IA_CATEGORY_KEYS = [
	'analyticsdatamanagement',
	'authorization',
	'billing',
	'commdigital',
	'gdprprivacy',
	'notificationsalerts',
	'organization',
	'platform',
	'routing',
	'telephony',
	'useragentman',
];

export default function SiteNavigation() {
	const sitemap = useSitemap();
	const [items, setItems] = useState({} as SitemapData);
	const [topics, setTopics] = useState({} as SitemapData);
	const [resources, setResources] = useState({} as SitemapData);

	useEffect(() => {
		let newItems = {} as SitemapData;
		let newTopics = {} as SitemapData;
		let newResources = {} as SitemapData;

		Object.entries(sitemap.sitemap).forEach(([key, item]) => {
			// Add to master list
			newItems[key] = item;

			// Add to segregated lists
			if (IA_CATEGORY_KEYS.includes(key)) newTopics[key] = item;
			else newResources[key] = item;
		});

		setItems(newItems);
		setTopics(newTopics);
		setResources(newResources);
	}, [sitemap]);

	if (process.env.REACT_APP_USE_IA_NAVIGATION === 'true') {
		return (
			<div className="site-navigation">
				<div className="h7">Topics</div>
				{Object.entries(topics).map(([key, item]) => {
					return typeof item === 'object' ? <NavGroup relativeSitemap={item} level={1} key={key} /> : null;
				})}
				<div className="h7">Resources</div>
				{Object.entries(resources).map(([key, item]) => {
					return typeof item === 'object' ? <NavGroup relativeSitemap={item} level={1} key={key} /> : null;
				})}
			</div>
		);
	} else {
		return (
			<div className="site-navigation">
				<div className="h7">Navigation</div>
				{Object.entries(items).map(([key, item]) => {
					return typeof item === 'object' ? <NavGroup relativeSitemap={item} level={1} key={key} /> : null;
				})}
			</div>
		);
	}
}
