import { AwsRum, AwsRumConfig } from 'aws-rum-web';

class RumInjectorClass {
	awsRum?: AwsRum;

	public init(): void {
		const hostnameRegex = /\.(?:(dev|test)-)?genesys\.cloud$/i;
		const match = hostnameRegex.exec(window.location.hostname);
		if (match) {
			try {
				// Determine environment
				const envkey = (match[1] || 'prod').toUpperCase();

				// Prepare config
				const APPLICATION_ID: string = process.env[`REACT_APP_CWRUM_${envkey}_APPLICATION_ID`] || '';
				const APPLICATION_VERSION: string = '1.0.0';
				const APPLICATION_REGION: string = 'us-east-1';
				const config: AwsRumConfig = {
					sessionSampleRate: 1,
					guestRoleArn: process.env[`REACT_APP_CWRUM_${envkey}_GUEST_ROLE_ARN`],
					identityPoolId: process.env[`REACT_APP_CWRUM_${envkey}_IDENTITY_POOL_ID`],
					endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
					telemetries: ['performance', 'errors', 'http'],
					allowCookies: true,
					enableXRay: false,
				};

				// Start RUM monitor
				this.awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
				this.awsRum.addSessionAttributes({
					initTime: Date.now(),
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			console.warn('Why is all the RUM gone?');
			return;
		}
	}

	public event(eventType: AppEvent, eventData?: object) {
		this.awsRum?.recordEvent(eventType, eventData || {});
	}
}

export const RumInjector = new RumInjectorClass();

export enum AppEvent {
	Loaded = 'com.genesys.app.loaded',
	ThemeSwitch = 'com.genesys.app.theme_switch',
}
