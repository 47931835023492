import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ModelSchema, OpenAPIDefinition, BodyEditorValueCallback, UpdateSource } from '../../../../helpers/openapi/OpenAPITypes';
import CodeFence from '../../../codefence/CodeFence';
import WizardEditor from './wizardeditor/WizardEditor';
import { AlertBlock, DxButton, DxToggle } from 'genesys-react-components';
import ModelSchemaDisplay from '../display/ModelSchemaDisplay';
import AppSettings from '../../../../helpers/settings/AppSettings';
import { processModel } from '../../../../helpers/openapi/OpenAPITools';

import './ModelEditor.scss';

interface IProps {
	schema: ModelSchema;
	isRequest?: boolean;
	propertyName?: string;
	onValueUpdated?: BodyEditorValueCallback;
	swagger: OpenAPIDefinition;
	initialValue?: any;
	updateSource: UpdateSource;
}

export default function ModelEditor(props: IProps) {
	const [jsonObject, setJsonObject] = useState<any>();
	const [jsonString, setJsonString] = useState<string>();
	const [jsonParseError, setJsonParseError] = useState<string>();
	const proMode = useRecoilValue(AppSettings.apiExplorerProModeAtom());
	const codeFenceRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (JSON.stringify(props.initialValue) !== JSON.stringify(jsonObject)) {
			setJsonObject(props.initialValue);
			setJsonString(JSON.stringify(props.initialValue, null, 2));
			setJsonParseError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.initialValue]);

	const valueUpdated = (propertyName: string, value: any) => {
		let newJsonObject: any;
		if (Array.isArray(value)) newJsonObject = [...value];
		else if (typeof value === 'object') newJsonObject = { ...value };
		else newJsonObject = value;

		setJsonObject(newJsonObject);
		setJsonString(value ? JSON.stringify(value, null, 2) : '');

		// Propigate notification
		if (props.onValueUpdated) props.onValueUpdated(propertyName, value, UpdateSource.WIZARD);
	};

	const updateJson = (s: string) => {
		setJsonString(s);

		let newJsonObject: any;
		try {
			newJsonObject = s ? JSON.parse(s) : undefined;
			setJsonObject(newJsonObject);
			setJsonParseError(undefined);

			// Propigate notification
			if (props.onValueUpdated) props.onValueUpdated(props.propertyName || '', newJsonObject, UpdateSource.JSON);
		} catch (err) {
			setJsonParseError(err instanceof SyntaxError ? err.message || 'Invalid JSON' : 'Invalid JSON');
		}
	};

	const handleScroll = (e: React.UIEvent<HTMLElement>) => {
		if (codeFenceRef.current?.children?.[0] && inputRef.current) {
			codeFenceRef.current.children[0].scrollLeft = inputRef.current.scrollLeft;
			codeFenceRef.current.children[0].scrollTop = inputRef.current.scrollTop;
		}
	};

	return (
		<div className="model-editor-container">
			<div className="model-editor-controls">
				<DxToggle initialValue={proMode} label="Pro mode" onChange={(value) => AppSettings.setApiExplorerProMode(value === true)} />
				<DxButton
					type="secondary"
					onClick={() => {
						updateJson(JSON.stringify(processModel(props.schema, props.isRequest, undefined, props.swagger), null, 2));
					}}
				>
					Load empty schema
				</DxButton>
			</div>
			<div className="model-editor-content">
				{proMode && (
					<ModelSchemaDisplay schema={props.schema} definition={props.swagger} modelName={props.schema.__modelName} showExpanded={true} />
				)}
				{!proMode && (
					<WizardEditor
						{...props}
						onValueUpdated={valueUpdated}
						initialValue={props.initialValue || jsonObject}
						swagger={props.swagger}
						updateSource={props.updateSource}
					/>
				)}
				<div className="json-editor-container">
					{jsonParseError && <AlertBlock alertType="critical">{jsonParseError}</AlertBlock>}
					<div className={`json-editor-components${jsonParseError ? ' error' : ''}`}>
						<div className="json-inner">
							<CodeFence
								jsonEditor={true}
								language="json"
								noHeader={true}
								value={jsonString !== undefined ? jsonString : props.initialValue ? JSON.stringify(props.initialValue, null, 2) || '' : ''}
								innerRef={codeFenceRef}
							/>
						</div>
						<div className="json-outer">
							<textarea
								name="text"
								id="exampleText"
								value={jsonString !== undefined ? jsonString : props.initialValue ? JSON.stringify(props.initialValue, null, 2) || '' : ''}
								spellCheck={false}
								onChange={(e) => updateJson(e.target.value)}
								onScroll={handleScroll}
								ref={inputRef}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
