import { useState } from 'react';
import { AlertBlock, DxButton, DxTextbox } from 'genesys-react-components';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import { setFeedbackMessage, useFeedback } from './FeedbackAtom';
import { ToastType, addToast } from '../../helpers/atoms/ToastAtom';
import CmsApi from '../../helpers/CmsApi';
import { FeedbackSentiment } from '../../helpers/CmsApiTypes';

import './FeedbackWidget.scss';

const MIN_MESSAGE_LENGTH = 30;
const MAX_MESSAGE_LENGTH = 3000;

export default function FeedbackWidget() {
	const feedbackData = useFeedback();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [validationError, setValidationError] = useState<string>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [sentiment, setSentiment] = useState<FeedbackSentiment>(FeedbackSentiment.None);

	if (!isOpen) {
		return (
			<div className="feedback-widget">
				<div className="sentiment-container collapsed">
					<span>Was this page helpful?</span>
					<DxButton className={`sentiment-button${sentiment === FeedbackSentiment.Positive ? ' active' : ''}`} type="link">
						<GenesysDevIcon
							className="thumbs-icon"
							icon={GenesysDevIcons.AppThumbsUp}
							onClick={() => {
								setSentiment(FeedbackSentiment.Positive);
								setIsOpen(true);
							}}
						/>
					</DxButton>
					<DxButton className={`sentiment-button${sentiment === FeedbackSentiment.Negative ? ' active' : ''}`} type="link">
						<GenesysDevIcon
							className="thumbs-icon"
							icon={GenesysDevIcons.AppThumbsDown}
							onClick={() => {
								setSentiment(FeedbackSentiment.Negative);
								setIsOpen(true);
							}}
						/>
					</DxButton>
					<DxButton className={`sentiment-button${sentiment === FeedbackSentiment.Bug ? ' active' : ''}`} type="link">
						<GenesysDevIcon
							className="thumbs-icon"
							icon={GenesysDevIcons.AppBug}
							onClick={() => {
								setSentiment(FeedbackSentiment.Bug);
								setIsOpen(true);
							}}
						/>
					</DxButton>
				</div>
			</div>
		);
	}

	return (
		<div className="feedback-widget">
			<h2 className="feedback-heading">{process.env['REACT_APP_SITE_NAME']} Feedback</h2>
			<p>
				Need help with something? Found a bug? Have something to say? Fill out the form below and we'll get back to you.{' '}
				{process.env['REACT_APP_FEEDBACK_PROMPT']}
			</p>
			<div className="sentiment-container">
				<span>Sentiment:</span>
				<DxButton
					className={`sentiment-button${sentiment === FeedbackSentiment.Positive ? ' active' : ''}`}
					type={sentiment === FeedbackSentiment.Positive ? 'primary' : 'link'}
				>
					<GenesysDevIcon
						className="thumbs-icon"
						icon={GenesysDevIcons.AppThumbsUp}
						onClick={() => setSentiment(FeedbackSentiment.Positive)}
					/>
				</DxButton>
				<DxButton
					className={`sentiment-button${sentiment === FeedbackSentiment.Negative ? ' active' : ''}`}
					type={sentiment === FeedbackSentiment.Negative ? 'primary' : 'link'}
				>
					<GenesysDevIcon
						className="thumbs-icon"
						icon={GenesysDevIcons.AppThumbsDown}
						onClick={() => setSentiment(FeedbackSentiment.Negative)}
					/>
				</DxButton>
				<DxButton
					className={`sentiment-button${sentiment === FeedbackSentiment.Bug ? ' active' : ''}`}
					type={sentiment === FeedbackSentiment.Bug ? 'primary' : 'link'}
				>
					<GenesysDevIcon className="thumbs-icon" icon={GenesysDevIcons.AppBug} onClick={() => setSentiment(FeedbackSentiment.Bug)} />
				</DxButton>
			</div>
			<DxTextbox
				className="message-text"
				inputType="textarea"
				placeholder="What's on your mind?"
				initialValue={feedbackData.message}
				disabled={isSubmitting}
				description={`Message length: ${feedbackData.message.length}/${MAX_MESSAGE_LENGTH}${
					feedbackData.message.length < MIN_MESSAGE_LENGTH ? ` (must be at least ${MIN_MESSAGE_LENGTH})` : ''
				}`}
				changeDebounceMs={-1}
				clearOnEscape={false}
				onChange={(val) => {
					const message = val?.trim() || '';
					setFeedbackMessage(message);
					if (message.length > MAX_MESSAGE_LENGTH) {
						setValidationError(`Message is too long. Maximum length is ${MAX_MESSAGE_LENGTH} characters.`);
						return;
					}
					setValidationError(undefined);
				}}
			/>
			{validationError && (
				<AlertBlock alertType="critical" title="Validation Error">
					{validationError}
				</AlertBlock>
			)}
			<div className="submit-buttons">
				<DxButton
					disabled={!!validationError || feedbackData.message.length < MIN_MESSAGE_LENGTH || isSubmitting}
					onClick={async () => {
						setIsSubmitting(true);
						const ok = await CmsApi.postFeedback(feedbackData.message, sentiment);
						if (ok) {
							setFeedbackMessage('');
							setIsOpen(false);
							setSentiment(FeedbackSentiment.None);
							addToast({
								title: 'Feedback submitted',
								message: "Thanks for your message. We'll get back to you soon. " + process.env['REACT_APP_FEEDBACK_SUCCESS_MESSAGE'],
								toastType: ToastType.Success,
							});
						}
						setIsSubmitting(false);
					}}
				>
					{isSubmitting ? 'Sending...' : 'Submit'}
				</DxButton>
				<DxButton
					type="secondary"
					disabled={isSubmitting}
					onClick={() => {
						setFeedbackMessage('');
						setSentiment(FeedbackSentiment.None);
						setIsOpen(false);
					}}
				>
					Cancel
				</DxButton>
			</div>
		</div>
	);
}
