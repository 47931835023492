import axios, { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import CmsApi from './CmsApi';

// const originRegex = /^(https:\/\/developer(?:-messaging|-beta)?)(\.(?:dev-|test-)?genesys\.cloud)$/i;

class AssetLoader {
	contentHost: string;
	client: AxiosInstance;

	constructor() {
		this.contentHost = `/api/${process.env.REACT_APP_SITE_PROPERTY}/assets`;
		this.client = axios.create({
			baseURL: CmsApi.baseURL,
			// headers: {
			// 	Authorization: jwt,
			// },
		});
	}

	generateCancelToken() {
		return axios.CancelToken.source();
	}

	isCancel(err: any) {
		return axios.isCancel(err);
	}

	get(pathname: string, returnRawBody?: boolean, cancelToken?: CancelToken) {
		// scrub filename
		let keyPath = pathname;
		if (keyPath.endsWith('.md')) {
			keyPath = keyPath.substring(0, keyPath.length - 3);
		}

		// Make request
		return this.client
			.request({
				method: 'get',
				url: this.contentHost + keyPath,
				cancelToken,
				params: {
					content: true,
				},
				headers: {
					Authorization: CmsApi.jwt,
				},
			} as AxiosRequestConfig)
			.then((response) => {
				if (returnRawBody) return response.data;

				// Stringify objects for content
				let data = typeof response.data === 'object' ? `<pre>${JSON.stringify(response.data, null, 2)}</pre>` : response.data;
				return this.addContentHost(data, pathname);
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				if (err.response && err.response.status === 401 && process.env.REACT_APP_OAUTH_REDIRECT_URI) {
					const state = encodeURIComponent(
						window.location.pathname +
							(window.location.search && window.location.search.length > 1 ? window.location.search : '') +
							(window.location.hash && window.location.hash.length > 1 ? window.location.hash : '')
					);
					window.location.href = process.env.REACT_APP_OAUTH_REDIRECT_URI + '&state=' + state;
				} else {
					throw err;
				}
			});
	}

	put(pathname: string, requestBody?: any, requestContentType?: string, cancelToken?: CancelToken) {
		return this.client
			.request({
				method: 'put',
				url: this.contentHost + pathname,
				headers: {
					'content-type': requestContentType,
					Authorization: CmsApi.jwt,
				},
				data: requestBody,
				cancelToken,
			})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				throw err;
			});
	}

	post(pathname: string, requestBody?: any, requestContentType?: string, cancelToken?: CancelToken, timeoutMs?: number, headers?: any) {
		const config: AxiosRequestConfig = {
			method: 'post',
			url: this.contentHost + pathname,
			headers: {
				'content-type': requestContentType,
			},
			data: requestBody,
			cancelToken,
		};

		if (headers) {
			const updatedHeaders = Object.assign({}, config.headers);
			for (const [k, v] of Object.entries(headers)) {
				updatedHeaders[k] = v;
			}
			config.headers = updatedHeaders;
		}

		if (timeoutMs && timeoutMs > 0) {
			config.timeout = timeoutMs;
		}

		return axios
			.request(config)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				throw err;
			});
	}

	setContentHost(contentHost: string) {
		this.contentHost = contentHost;
	}

	addContentHost(document: string, contextPath = '') {
		// Strip filename
		contextPath = contextPath.substring(0, contextPath.lastIndexOf('/') + 1);

		// Add content host to image sources
		const replacer = (match: string, p1: string, p2: string, p3: string) => {
			let file;
			if (p2.startsWith('data:image')) {
				file = p2;
			} else if (p2.startsWith('//')) {
				file = p2;
			} else if (p2.startsWith('/')) {
				file = this.contentHost + p2;
			} else if (!p2.includes('/') || (p2.includes('/') && !p2.startsWith('http'))) {
				file = this.contentHost + contextPath + p2;
			} else {
				file = p2;
			}
			return `${p1}${file}${p3}`;
		};

		document = document.replace(/(<img.+?src=['"])(.+?)(['"].*?>)/gi, replacer);
		document = document.replace(/(<link.+?href=['"])(.+?)(['"].*?>)/gi, replacer);

		return document;
	}
}

export default new AssetLoader();

const SearchLoader = new AssetLoader();
if (process.env.REACT_APP_SEARCH_HOST) {
	// can be set to use search when running locally
	SearchLoader.setContentHost(process.env.REACT_APP_SEARCH_HOST);
} else {
	// search needs empty content host to bypass other CloudFront path patters
	SearchLoader.setContentHost('');
}
export { SearchLoader };
