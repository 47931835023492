import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxAccordion, DxItemGroup, DxButton, DxItemGroupItem, LoadingPlaceholder } from 'genesys-react-components';
import axios, { CancelTokenSource } from 'axios';
import { GenesysDevIcons, GenesysDevIcon } from 'genesys-dev-icons';
import { AlertBlock } from 'genesys-react-components';

import AppSettings from '../../../helpers/settings/AppSettings';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import CodeFence from '../../codefence/CodeFence';
import { WebChatSettingsData } from '../../../types';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import { Models } from '../../../helpers/platformapi/PlatformAPITypes';
import { WebchatVersion } from './WebChatV1';
import { loadDeployments } from './helperFunctions';

import './WebMessenger.scss';

interface IframeParameter {
	deployment?: Models.WebDeployment;
}

function WebMessenger() {
	const [configDisabled, setConfigDisabled] = useState(false);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const [deploymentItems, setDeploymentItems] = useState<DxItemGroupItem[]>([]);
	const [chatInitiated, setChatInitiated] = useState(false);
	const [selectedDeployment, setSelectedDeployment] = useState<Models.WebDeployment>({});
	const [permissions, setPermissions] = useState('');
	const [loadingConfigData, setLoadingConfigData] = useState(true);
	const [deployments, setDeployments] = useState<Models.WebDeployment[]>([]);
	const [iframeUrlParam, setIframeUrlParam] = useState<IframeParameter>({});
	const savedSettings = useRecoilValue(AppSettings.webChatSettingsAtom());
	const cancelToken = useRef<CancelTokenSource | undefined>();

	useEffect(() => {
		if (!selectedAccount) return;

		let permissionsErr = '';

		const tokenSource = axios.CancelToken.source();
		cancelToken.current = tokenSource;

		if (!selectedAccount.me?.authorization) {
			addToast({
				title: 'Authorization Error',
				message:
					'Unable to validate permissions. You may not see deployments in the dropdowns. You\'re probably missing the permission "authorization:grant:view".',
				toastType: ToastType.Critical,
			});
			return;
		}

		let hasReadDeployment = false;

		selectedAccount.me?.authorization?.permissions?.forEach((p: string) => {
			// Must check for startswith because of how permissions look with divisions. Can't use exact match here.
			if (p.startsWith('webDeployments:deployment:view')) hasReadDeployment = true;
		});

		// Set error messages
		if (!hasReadDeployment) permissionsErr += 'webDeployments:deployment:view';
		setPermissions(permissionsErr);

		setLoadingConfigData(true);
		//get deployments
		loadDeployments(cancelToken.current, WebchatVersion.MESSENGER)
			.then((res: any) => {
				let deploymentItems: DxItemGroupItem[] = res.map((d: Models.WebDeployment) => {
					return { label: d.name, value: d.id };
				});

				if (savedSettings.messenger && savedSettings.messenger.selectedDeployment !== '') {
					deploymentItems = [];
					res.forEach((d: Models.WebDeployment) => {
						if (d.name && d.id) {
							if (savedSettings.messenger.selectedDeployment === d.id) {
								deploymentItems.push({ label: d.name, value: d.id, isSelected: true });
								return;
							}
							deploymentItems.push({ label: d.name, value: d.id });
						}
					});

					setDeploymentItems(deploymentItems);

					const sd: Models.WebDeployment | undefined = res.find(
						(d: Models.WebDeployment) => d.id === savedSettings.messenger.selectedDeployment
					);

					if (sd) {
						setSelectedDeployment(sd);
					}
				} else {
					deploymentItems.unshift({ label: '--- Select a deployment ---', value: '', disabled: true, isSelected: true });
					setDeploymentItems(deploymentItems);
				}
				setDeployments(res);
				setLoadingConfigData(false);
			})
			.catch((err) => {
				if (!axios.isCancel(err)) {
					addToast({ title: 'Unable to get deployments', message: err.message, toastType: ToastType.Critical });
					setLoadingConfigData(false);
				}
			});

		window.addEventListener('message', getIframeEvents);

		return () => {
			cancelToken.current?.cancel('Component unmounted');
			window.removeEventListener('message', getIframeEvents);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount]);

	const getIframeEvents = (event: any) => {
		const message = event.data.message;
		if (message === 'iframe-error') {
			addToast({ title: 'Error starting chat', message: event.data.value.message, toastType: ToastType.Critical });
			resetConfiguration();
		}
	};

	if (!selectedAccount) {
		return (
			<div className="no-account-warning">
				<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
				<span> Please add an account to utilize this tool</span>
			</div>
		);
	}

	const selectDeployment = (deploymentId: string) => {
		const d = deployments.find((deployment: Models.WebDeployment) => deployment.id === deploymentId);
		if (d) {
			setSelectedDeployment(d);
		}
	};

	function saveSettings() {
		const newSettings: WebChatSettingsData = {
			selectedDeployment: selectedDeployment.id,
		};
		AppSettings.setWebChatSettings(newSettings, WebchatVersion.MESSENGER);
	}

	function startChat() {
		if (Object.keys(selectedDeployment).length === 0) {
			addToast({
				title: 'Unable to start chat ',
				message: 'Missing configuration(s): please select a deployment',
				toastType: ToastType.Critical,
			});
			return;
		}
		const newIframeParam: IframeParameter = {
			deployment: selectedDeployment,
		};

		setIframeUrlParam(newIframeParam);
		setChatInitiated(true);
		setConfigDisabled(true);
		saveSettings();
	}

	function resetConfiguration() {
		setConfigDisabled(false);
		setChatInitiated(false);
	}

	const stringifyScript = (script: string) => {
		if (script) {
			const snippet = script?.split(' ').filter((e: string, index: number, arr: string[]) => index > 3 && index < arr.length - 1);
			return snippet.join(' ') + '});';
		}
		return '';
	};

	const scriptTag = `<script type="text/javascript" charset="utf-8">
  ${stringifyScript(JSON.stringify(selectedDeployment.snippet)).replace(/ {3}/gim, '\n ')}
</script>`;

	return (
		<div className="web-messenger-container">
			<div>
				To get started, follow these instructions:
				<ul>
					<li>
						Ensure that you are have pre-configured a web messenger deployment set up in your org. See more about that{' '}
						<a href="https://help.mypurecloud.com/articles/get-started-with-web-messaging/" target={'_blank'} rel={'noreferrer'}>
							here
						</a>
						.
					</li>
					<li>Ensure that you are on-queue.</li>
					<li>Select a web messenger deployment.</li>
					<li>Click the start chat button to initiate chat.</li>
				</ul>
			</div>
			<DxAccordion title="Chat configuration (required)" showOpen={true}>
				{permissions ? (
					<AlertBlock
						title="You do not have the required permission(s) to continue to use this tool"
						alertType="critical"
						children={`Missing Permission(s): ${permissions}`}
					/>
				) : (
					<>
						{loadingConfigData ? (
							<LoadingPlaceholder text="Loading Chat Config" />
						) : (
							<React.Fragment>
								<DxItemGroup
									format="dropdown"
									items={deploymentItems}
									title="Deployments"
									description="Select a deployment or create a new one if there is none available"
									onItemChanged={(item: DxItemGroupItem) => selectDeployment(item.value)}
									disabled={configDisabled}
								/>
								<span>
									To learn more about creating a web messenger deployment or configuration, checkout the
									<a href="https://help.mypurecloud.com/articles/get-started-with-web-messaging/" target={'_blank'} rel={'noreferrer'}>
										{' '}
										web messaging{' '}
									</a>
									page on the resource center
								</span>
							</React.Fragment>
						)}
					</>
				)}
			</DxAccordion>
			<div className="start-chat-button-container">
				<DxButton className="start-chat-button" onClick={startChat} disabled={!!permissions}>
					Start Chat
				</DxButton>
			</div>
			<DxAccordion title="Deployment Script Snippet">
				<CodeFence title="Snippet" value={scriptTag} language="html" />
			</DxAccordion>

			{chatInitiated && (
				<>
					<iframe
						id="web-messenger-iframe"
						src={`/WebMessenger.html?script=${encodeURIComponent(JSON.stringify(iframeUrlParam.deployment))}`}
						title="Web Messenger"
					></iframe>

					<DxButton onClick={resetConfiguration} className="resetConfigButton">
						Reset Configuration
					</DxButton>
				</>
			)}
		</div>
	);
}

export default WebMessenger;
