import React, { useRef, useState } from 'react';
import { DxButton } from 'genesys-react-components';
import { getSvgDimensions } from './utils';

import './ImageUploader.scss';

interface IProps {
	onChange?: (file: File | null) => void;
	accepts?: string[];
}

export default function ImageUploader(props: IProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const [selectedFile, setSelectedFile] = useState<File | null>();
	const [fileText, setFileText] = useState<string>();
	const [dimensions, setDimensions] = useState<Array<Number>>();

	const accepts = !props.accepts ? 'image/*' : props.accepts.map((ext) => `.${ext}`).join(',');
	const onChange = props.onChange;

	// When button is click activate the hidden file input form
	const handleClick = () => {
		if (!inputRef || !inputRef.current) return;

		inputRef.current.click();
	};

	// Selecting an SVG for upload
	const handleFileChange = (event: React.ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		if (!target.files) return;

		const fileObj = target.files[0];
		if (!fileObj) return;

		// Set the svg file
		setSelectedFile(fileObj);

		// Get dimensions
		getSvgDimensions(fileObj)
			.then((dimensions) => {
				setDimensions(dimensions);
			})
			.catch((e) => {
				setDimensions([0, 0]);
			});

		// Parse the SVG file to HAST javascript object
		fileObj.text().then((text) => {
			setFileText(text);

			// Call the onChange prop callback
			if (onChange) onChange(fileObj);
		});
	};

	return (
		<div>
			<input className="image-uploader" ref={inputRef} type="file" accept={accepts} onChange={handleFileChange} />

			<DxButton type="primary" onClick={handleClick} disabled={false}>
				Upload SVG Icon
			</DxButton>

			{selectedFile && fileText && dimensions ? (
				<div>
					<div className="svg-container">
						<img src={`data:image/svg+xml;utf8,${encodeURIComponent(fileText)}`} alt="logo preview" />
					</div>
					<div>
						Dimensions: {`${dimensions[0]}`} x {`${dimensions[1]}`}
					</div>
					<DxButton
						type="primary"
						onClick={() => {
							setSelectedFile(null);
							setFileText('');
							if (inputRef && inputRef.current) inputRef.current.value = '';
							if (onChange) onChange(null);
						}}
					>
						Remove SVG
					</DxButton>
				</div>
			) : (
				<p>Please select an image</p>
			)}
		</div>
	);
}
