import React, { useEffect, useState } from 'react';
import yaml from 'js-yaml';
import { AlertBlock } from 'genesys-react-components';

import AssetLoader from '../../../../helpers/AssetLoader';
import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import DataTable from '../../../markdown/datatable/DataTable';

interface LimitWrapper {
	approvers: any[];
	description: string;
	friendlyName: string;
	limits: Limits;
	namespace: string;
	owner: string;
	services: any[];
}

interface Limits {
	[keyName: string]: Limit;
}

interface Limit {
	default: number;
	description: string;
	documented: boolean;
	enforced: boolean;
	key: string;
	resource: string;
	values: number[];
	configurable: boolean;
}

interface IProps {
	operationDetails: OperationDetails;
}

export default function LimitsDisplay(props: IProps) {
	const [limits, setLimits] = useState<Limit[]>();
	const [limitError, setLimitError] = useState<string | undefined>();

	// Constructor
	useEffect(() => {
		// Load rate limit data for operation
		AssetLoader.get('/data/outerlimits.yml', true, undefined)
			.then((data) => {
				const limitWrappers = yaml.load(data) as LimitWrapper[];
				const resourcePath: string = props.operationDetails.path;
				let matchingLimits: Limit[] = [];
				// Collect only rate limits that apply to this operation
				limitWrappers
					.map((wrapper: LimitWrapper) => wrapper.limits)
					.forEach((limits: Limits) => {
						const partialLimits = Object.keys(limits)
							.map((limitKey: string) => limits[limitKey])
							.filter((limit: Limit) => {
								if (!limit.resource) return false;
								const formattedResource: string = limit.resource
									.replaceAll(/\[([^,]*),\s?([^\]]*)\]/gi, '($1|$2)')
									.replaceAll('*', '[^/]*');
								const limitRegex = new RegExp(formattedResource, 'gi');
								return limitRegex.test(resourcePath);
							})
							.map((limit: Limit) => {
								return {
									...limit,
									resource: limit.resource
										.replaceAll('[', '\\[')
										.replaceAll(']', '\\]')
								};
							});
						if (partialLimits.length > 0) matchingLimits = matchingLimits.concat(partialLimits);
					});
				setLimits(matchingLimits);
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					console.warn('Limit data not loaded');
				} else {
					console.error(err);
				}
				setLimitError('Failed to load limit information for this resource');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (limitError) return <AlertBlock alertType="critical">{limitError}</AlertBlock>;

	return limits && limits.length > 0 ? (
		<DataTable
			sortable={true}
			filterable={false}
			headerRow={{
				cells: [
					{ content: 'Key' },
					{ content: 'Description' },
					{ content: 'Limit' },
					{ content: 'Resource(s)' },
					{ content: 'Configurable?' },
				],
			}}
			rows={limits.map((limit: Limit) => {
				return {
					cells: [
						{ content: limit.key || '' },
						{ content: limit.description || '' },
						{ content: limit.default?.toString() || '' },
						{ content: limit.resource || '' },
						{ content: limit.configurable ? 'yes' : 'no' },
					],
				};
			})}
		/>
	) : (
		<p>No limits are associated with this operation</p>
	);
}
