import { LoadingPlaceholder } from 'genesys-react-components';
import React, { useEffect, useRef, useState } from 'react';
import CmsApi from '../../../helpers/CmsApi';
import { readBlob } from '../../../helpers/CmsApiTypes';

const hostnameRegex = /^https?:\/\//i;

interface IProps {
	src: string;
	alt?: string;
	title?: string;
}

export default function ImageAsset(props: IProps) {
	const [imgSrc, setImgSrc] = useState<string>();
	const [loadingError, setLoadingError] = useState<string>();
	const currentSrc = useRef<string>();

	useEffect(() => {
		if (currentSrc.current === props.src) return;
		currentSrc.current = props.src;

		(async () => {
			try {
				setLoadingError(undefined);
				setImgSrc(undefined);
				const match = hostnameRegex.exec(props.src);
				let src = new URL(props.src, window.location.href);
				const isSameHost = window.location.hostname.toLowerCase() === src.hostname.toLowerCase();
				if (match && !isSameHost) {
					// Set src to URL to let the browser load the image normally
					setImgSrc(props.src);
				} else {
					// Load image from API and set src as encoded image data
					const content = await CmsApi.getAssetContent(src.pathname, true);
					if (!content) {
						setLoadingError('Failed to load image');
					}
					let str = await readBlob(content?.content);
					setImgSrc(str);
				}
			} catch (err) {
				console.error(err);
				setLoadingError('Failed to load image');
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.src]);

	if (loadingError) return <em title={props.src}>{loadingError}</em>;
	if (!imgSrc) return <LoadingPlaceholder />;
	else return <img src={imgSrc} alt={props.alt} title={props.title} />;
}
