import { atom, RecoilState } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { StorageProvider, ChangeCallback, AtomList, SettingNames, ToolboxApp, ToolboxItem, OnSetEffect } from '../../types';
import { addToast, ToastType } from '../atoms/ToastAtom';

import { IndexedDBProvider } from './storage/IndexedDBProvider';

export const ToolboxDefaultPages = {
	apiexplorer: [] as ToolboxItem[],
	notifications: [] as ToolboxItem[],
	pages: [] as ToolboxItem[],
	settings: [
		{ title: 'Privacy', appType: ToolboxApp.Settings, props: { page: 'privacy' }, disableUserRemove: true },
		{ title: 'Developer Applications', appType: ToolboxApp.Settings, props: { page: 'dev-apps' }, disableUserRemove: true }
	] as ToolboxItem[],
};

// SettingsManager is a the low-level interface to the storage provider. Use AppSettings for most cases.
class SettingsManager {
	public provider: StorageProvider;
	public storeName = 'dx-ui-settings';
	private callbacks: ChangeCallback[];
	private atoms: AtomList = {};

	constructor() {
		this.callbacks = [];
		this.provider = new IndexedDBProvider();
		if (!this.provider.isAvailable()) return;

		this.provider.initialize(this.storeName);
	}

	// Sets a value for an atom-cached setting
	public set<T>(key: string, value: T) {
		try {
			setRecoil(this.getSettingAtom<T>(key), value);
		} catch (err) {
			console.error(err);
		}
	}

	// Sets a value directly to storage without involving atoms
	public setDirect<T>(key: string, value: T) {
		if (!this.getStorageAllowed()) return;
		this.provider.setItem(key, value);
		this.change(key, value);
	}

	// Returns the setting's atom-cached value
	public get<T>(key: string, defaultValue?: T) {
		try {
			const atom = this.getSettingAtom<T>(key, defaultValue);
			return getRecoil(atom);
		} catch (err) {
			// This throws errors when the app is loading because the recoil nexus has not yet been instantiated and the atom's value can't yet be fetched
			// console.error(err);
			return defaultValue;
		}
	}

	// Returns the setting directly from storage
	public async getDirect<T>(key: string) {
		return (await this.provider.getItem(key)) as T;
	}

	public getStorageAllowed() {
		return (
			this.atoms[SettingNames.AllowStorage] !== undefined && getRecoil(this.atoms[SettingNames.AllowStorage] as RecoilState<any>) === true
		);
	}

	public getSettingAtom<T>(settingKey: string, defaultValue?: T, onSetEffect?: OnSetEffect<T>): RecoilState<T> {
		if (!this.atoms[settingKey]) {
			// Load setting from storage
			const initSetting = async (): Promise<T> => {
				// Load from storage
				var x = await this.provider.getItem(settingKey);

				// Override toolbox items to init from defaults
				if (x && settingKey === SettingNames.ToolboxItems) {
					x.settings = ToolboxDefaultPages.settings;
				}

				const initialValue = x !== undefined ? x : defaultValue;

				// Invoke callbacks
				if (onSetEffect) onSetEffect(initialValue);

				return initialValue;
			};

			// Lazy create atom for this setting
			this.atoms[settingKey] = atom({
				key: `dx-setting-${settingKey}`,
				default: initSetting(),
				effects_UNSTABLE: [
					({ onSet }) => {
						onSet(async (newValue) => {
							try {
								const storageAllowed = this.getStorageAllowed();
								if (settingKey !== SettingNames.AllowStorage && storageAllowed !== true) return;

								if (settingKey === SettingNames.AllowStorage) {
									// This happens when the setting never existed so can be safely ignored. There is no flow to revoke the
									// setting, only set it to false.
									if (newValue === undefined) return;

									// Purge saved settings
									if ((newValue as any) === false) {
										try {
											this.provider.purge();
										} catch (error) {
											console.error(error);
											addToast({
												title: 'Unable to clear data from indexedDB',
												message: 'The app was unable to clear the data from the indexedDB, see JavaScript console for more information',
												toastType: ToastType.Warning,
											});
										}
									}

									// Store allow storage setting
									this.provider.setItem(SettingNames.AllowStorage, newValue);

									// Save atom values to local storage when it's enabled
									if ((newValue as any) === true) {
										Object.values(SettingNames).forEach((sk) => {
											// Avoid initializing the atom from here if nothing else has already. Leave initialization to the app.
											if (!this.atoms[sk]) return;
											// Get the atom
											const v = this.get(sk);
											// Avoid setting a value while atom is initializing (atom is uninitialized and returns a promise instead of a value)
											if (typeof (v as any)?.then !== 'function') this.provider.setItem(sk, v);
										});
									}
								} else if (storageAllowed !== true) {
									// Storage not allowed, do nothing
									return;
								} else {
									// Save to storage
									this.provider.setItem(settingKey, newValue);
								}

								// Raise legacy changed handler
								this.change(settingKey, newValue);

								// Invoke callbacks
								if (onSetEffect) onSetEffect(newValue);
							} catch (err) {
								console.error(err);
							}
						});
					},
				],
			});
		}

		// Return atom from cache
		return this.atoms[settingKey] as RecoilState<T>;
	}

	// (Un)Registers settings changed callbacks
	onChange(callback?: ChangeCallback, unset = false) {
		if (!callback) return;
		var idx = this.callbacks.indexOf(callback);
		if (unset && idx >= 0) {
			this.callbacks.splice(idx, 1);
		} else if (idx < 0) {
			this.callbacks.push(callback);
		}
	}

	// Invokes onChange functions for the given setting data
	change(key: string, value: any) {
		this.callbacks.forEach((f: Function) => setTimeout(() => f(key, value), 0));
	}

	// Clears everything from local storage
	purge() {
		return this.provider.purge();
	}
}

export default new SettingsManager();
