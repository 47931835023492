import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React, { useState, useRef, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxButton, DxCheckbox, DxToggle } from 'genesys-react-components';

import AccountCard from './AccountCard';
import AddAccountSelector from './AddAccountSelector';
import { RegionShortnames } from '../../helpers/platformapi/Region';
import { accountsAtom, removeAccount, selectedAccountAtom, setSelectedAccount } from '../../helpers/atoms/AccountsAtom';

import './AccountSwitcher.scss';
import silhouette from './silhouette.svg';
import CopyButton from '../copybutton/CopyButton';
import AppSettings from '../../helpers/settings/AppSettings';
import Tag from '../cards/Tag';
import { selectedThemeAtom } from '../../theme/ThemeAtom';
import { AnemiaTheme, LogoChoice } from '../../types';

interface IProps {
	className?: string;
	mode?: 'default' | 'mini';
}

export default function AccountSwitcher(props: IProps) {
	const [accounts] = useRecoilState(accountsAtom);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const [theme, setTheme] = useRecoilState(selectedThemeAtom());
	const [logoChoice, setLogoChoice] = useRecoilState(AppSettings.logoChoiceOption());
	const [isOpen, setIsOpen] = useState(false);
	const [isRegionSelection, setIsRegionSelection] = useState(false);
	const storageAllowed = useRecoilValue(AppSettings.allowStorageAtom());

	const wrapperRef = useRef(null as any);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleOpen = () => {
		if (isOpen) {
			close();
		} else {
			setIsOpen(true);
		}
	};

	const close = () => {
		setIsOpen(false);
		setIsRegionSelection(false);
	};

	function handleClickOutside(event: any) {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			if (event.target.className.includes('add-account-button')) {
				return;
			}
			close();
		}
	}

	const currentAccountContent = selectedAccount ? (
		<div className="account-list clickable" onClick={toggleOpen}>
			<AccountCard account={selectedAccount} />
		</div>
	) : (
		<div>
			<span className="no-account-text">Account Selection</span>
		</div>
	);

	let menu;
	if (isOpen) {
		if (isRegionSelection) {
			menu = (
				<div className="dropdown-content-group">
					<AddAccountSelector />
					<DxButton type="primary" className="add-account-button" onClick={() => setIsRegionSelection(false)}>
						Cancel
					</DxButton>
					<div style={{ clear: 'both' }}></div>
				</div>
			);
		} else {
			const addAccountButton = (
				<React.Fragment>
					<DxButton type="primary" className="add-account-button" onClick={() => setIsRegionSelection(true)}>
						Add Account
					</DxButton>
					<div style={{ clear: 'both' }}></div>
				</React.Fragment>
			);
			let selectedAccountDisplay;
			if (selectedAccount) {
				const img = selectedAccount.profileImageUri || silhouette;
				const removeAccountButton = (
					<React.Fragment>
						<DxButton type="secondary" className="remove-account-button" onClick={() => removeAccount(selectedAccount)}>
							Log Out
						</DxButton>
					</React.Fragment>
				);
				const accountFacts = [];
				accountFacts.push({ label: 'User ID', value: selectedAccount.userId });
				accountFacts.push({ label: 'Org Name', value: selectedAccount.orgName });
				accountFacts.push({ label: 'Org ID', value: selectedAccount.me?.organization?.id });
				accountFacts.push({ label: 'Division', value: selectedAccount.me?.division?.name });
				accountFacts.push({ label: 'Division ID', value: selectedAccount.me?.division?.id });

				selectedAccountDisplay = (
					<div className="dropdown-content-group selected-account">
						<div className="content-title">Account Information</div>
						<div className="selected-account-overview">
							<img src={img} alt="profile pic" />
							<div className="account-info">
								<span className="account-name" title={selectedAccount.name}>
									{selectedAccount.name}
								</span>
								<span className="account-org" title={selectedAccount.orgName}>
									{selectedAccount.orgName}
								</span>
								<Tag>{RegionShortnames.get(selectedAccount.region)}</Tag>
							</div>
						</div>
						<div className="account-facts">
							{accountFacts.map((fact, i) => (
								<div key={i}>
									<CopyButton copyText={fact.value || ''} tooltipPosition="left" />
									<span className="label">{fact.label}</span>
									<span>{fact.value}</span>
								</div>
							))}
						</div>
						{removeAccountButton}
						{addAccountButton}
					</div>
				);
			} else {
				selectedAccountDisplay = <div className="dropdown-content-group">{addAccountButton}</div>;
			}

			menu = (
				<React.Fragment>
					{props.mode !== 'mini' && (
						<React.Fragment>
							<div className="dropdown-content-group privacy-settings">
								<div className="content-title">Privacy Settings</div>
								<DxCheckbox
									label="Allow browser storage"
									itemValue="storage"
									checked={storageAllowed}
									onCheckChanged={(checked) => {
										if (checked === storageAllowed || (checked === false && storageAllowed === undefined)) return;
										AppSettings.setAllowStorage(checked === true);
									}}
								/>
								<div className="content-title">Theme Settings</div>
								<div className="toggle-row">
									<DxToggle
										label="Dark Mode"
										value={theme === AnemiaTheme.DefaultDark}
										onChange={(value) => setTheme(value ? AnemiaTheme.DefaultDark : AnemiaTheme.Default)}
										trueIcon={GenesysDevIcons.AppMoon}
										falseIcon={GenesysDevIcons.AppSun}
									/>
									<DxToggle
										label="Pride 24x7x365"
										value={logoChoice === LogoChoice.Pride}
										onChange={(value) => setLogoChoice(value ? LogoChoice.Pride : LogoChoice.Default)}
									/>
								</div>
							</div>
						</React.Fragment>
					)}
					{props.mode !== 'mini' && selectedAccountDisplay}
					{accounts
						.filter((account) => account.userId !== selectedAccount?.userId)
						.map((account) => (
							<div className="dropdown-content-group account-item" key={account.userId}>
								<AccountCard
									account={account}
									active={account.userId === selectedAccount?.userId}
									allowRemove={props.mode !== 'mini'}
									onClick={(account) => {
										setSelectedAccount(account);
										close();
									}}
								/>
							</div>
						))}
				</React.Fragment>
			);
		}

		// Add container
		menu = <div className={`accounts-dropdown${props.mode === 'mini' ? ' accounts-dropdown-mini' : ''}`}>{menu}</div>;
	}

	let containerClassName = 'account-switcher';
	if (isOpen) {
		containerClassName += ' open';
		if (props.mode === 'mini') containerClassName += ' open-mini';
	}
	if (props.className) containerClassName += ' ' + props.className;

	return (
		<div className={containerClassName} ref={wrapperRef}>
			<div className="current-account-container clickable" onClick={toggleOpen}>
				{currentAccountContent}
				<button type="button" onClick={toggleOpen} className="toggle-button clickable">
					<GenesysDevIcon icon={isOpen ? GenesysDevIcons.AppChevronUp : GenesysDevIcons.AppChevronDown} />
				</button>
			</div>
			{menu}
		</div>
	);
}
