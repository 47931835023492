export enum Region {
	us_east_1 = 'mypurecloud.com',
	us_east_2 = 'use2.us-gov-pure.cloud',
	us_west_2 = 'usw2.pure.cloud',
	eu_west_1 = 'mypurecloud.ie',
	ap_southeast_2 = 'mypurecloud.com.au',
	ap_northeast_1 = 'mypurecloud.jp',
	ap_northeast_2 = 'apne2.pure.cloud',
	ap_northeast_3 = 'apne3.pure.cloud',
	eu_central_1 = 'mypurecloud.de',
	eu_central_2 = 'euc2.pure.cloud',
	ca_central_1 = 'cac1.pure.cloud',
	eu_west_2 = 'euw2.pure.cloud',
	ap_south_1 = 'aps1.pure.cloud',
	sa_east_1 = 'sae1.pure.cloud',
	me_central_1 = 'mec1.pure.cloud',
	dev = 'inindca.com',
	test = 'inintca.com',
}

export class RegionShortnames {
	static get(region: Region): string {
		switch (region) {
			case Region.us_east_1:
				return 'USE1';
			case Region.us_east_2:
				return 'USE2';
			case Region.us_west_2:
				return 'USW2';
			case Region.eu_west_1:
				return 'EUW1';
			case Region.ap_southeast_2:
				return 'APSE2';
			case Region.ap_northeast_1:
				return 'APNE1';
			case Region.ap_northeast_2:
				return 'APNE2';
			case Region.ap_northeast_3:
				return 'APNE3';
			case Region.eu_central_1:
				return 'EUC1';
			case Region.eu_central_2:
				return 'EUC2';
			case Region.ca_central_1:
				return 'CAC1';
			case Region.eu_west_2:
				return 'EUW2';
			case Region.ap_south_1:
				return 'APS1';
			case Region.sa_east_1:
				return 'SAE1';
			case Region.me_central_1:
				return 'MEC1';
			case Region.dev:
				return 'DEV';
			case Region.test:
				return 'TEST';
			default:
				return 'UNK';
		}
	}
}

// https://www.petermorlion.com/iterating-a-typescript-enum/
export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
	return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
