import { GenesysDevIcons } from 'genesys-dev-icons';

import {
    FilterMap,
    DevCenterContentType,
    HelpContentType,
    Origin,
    SearchResult,
    HighlightLimits,
	APICentralContentType,
	FilterUpdate,
	DevCenterTopic,
	PostFeedbackRequest,
	getRelevanceValue
} from "./SearchTypes";
import { SearchLoader } from '../../helpers/AssetLoader';

const FEEDBACK_TIMEOUT = 10000;

export class SearchTools {

	// processes updates to the Developer Center topic filters
	updateDevCenterTopicFilters(e: React.ChangeEvent<HTMLInputElement>, devCenterTopicFilters: FilterMap): FilterUpdate<DevCenterTopic> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, devCenterTopicFilters);
		let filter: DevCenterTopic | undefined;
		
		Object.entries(DevCenterTopic).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});

        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

    // processes updates to the Developer Center content filters 
    updateDevCenterContentFilters(e: React.ChangeEvent<HTMLInputElement>, devCenterContentFilters: FilterMap): FilterUpdate<DevCenterContentType> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, devCenterContentFilters);
		let filter: DevCenterContentType | undefined;
		
		Object.entries(DevCenterContentType).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});

        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

    // processes updates to the Resource Center content filters 
    updateHelpContentFilters(e: React.ChangeEvent<HTMLInputElement>, helpContentFilters: FilterMap): FilterUpdate<HelpContentType> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, helpContentFilters);
		let filter: HelpContentType | undefined;

		Object.entries(HelpContentType).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});

        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

	// processes updates to the API Central content filters 
	updateAPICentralContentFilters(e: React.ChangeEvent<HTMLInputElement>, apiCentralContentFilters: FilterMap): FilterUpdate<APICentralContentType> {
		const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, apiCentralContentFilters);
		let filter: APICentralContentType | undefined;
		if (e.target.value === APICentralContentType.API_CENTRAL_DOC) {
			filter = APICentralContentType.API_CENTRAL_DOC;
		}

		return {
			newFilters,
			newFilterVal,
			filter
		}
	}

    // processes updates to the help content filters 
    updateSourceFilters(e: React.ChangeEvent<HTMLInputElement>, sourceFilters: FilterMap): FilterUpdate<Origin> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, sourceFilters);
		let filter: Origin | undefined;

		Object.entries(Origin).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});

        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

    // caculates the start index, end index, and max offset for a highlight of a search result
    calcHighlightLimits(result: SearchResult, highlight: number[]): HighlightLimits {
        let startIndex: number;
		let endIndex: number;
		const maxOffset: number = 30;

		// set start index for excerpt
		if (highlight[0] - maxOffset <= 0) {
			startIndex = 0;
		} else {
			startIndex = highlight[0] - maxOffset;
		}

		// set end index for excerpt
		if (highlight[1] + maxOffset >= result.excerptText!.length - 1) {
			endIndex = result.excerptText!.length - 1;
		} else {
			endIndex = highlight[1] + maxOffset;
		}

        return {
            startIndex,
            endIndex
        }
    }

    // formats the breadcrumb path for readability
    formatBreadcrumbPath(documentType: string): string {
        const documentTypeUpper: string = documentType.toUpperCase();
		const documentTypeLower: string = documentType.toLowerCase();
		const capitalizedFirstLetter: string = documentTypeUpper.charAt(0);
		return capitalizedFirstLetter + documentTypeLower.substring(1);
    }

    // selects a content icon based on document type
    getContentIcon(documentType: string): GenesysDevIcons {
        let contentIcon: GenesysDevIcons = GenesysDevIcons.DestPages;;

		if (documentType === DevCenterContentType.BLOG) {
			contentIcon = GenesysDevIcons.DestBlog;
		}
		if (documentType === DevCenterContentType.BLUEPRINT) {
			contentIcon = GenesysDevIcons.DestBlueprint;
		}
		if (documentType === DevCenterContentType.GUIDE) {
			contentIcon = GenesysDevIcons.DestGuide;
		}
		if (documentType === DevCenterContentType.TECH_REF) {
			contentIcon = GenesysDevIcons.DestTechRef;
		}
		if (documentType === DevCenterContentType.SDK_DOC) {
			contentIcon = GenesysDevIcons.DestSdkDoc;
		}
		if (documentType === DevCenterContentType.OTHER) {
			contentIcon = GenesysDevIcons.DestPages;
		}
		if (documentType === HelpContentType.ARTICLE || documentType === HelpContentType.FAQ) {
			contentIcon = GenesysDevIcons.AppExternalLink;
		}

        return contentIcon;
    }

	isValidSearchTerm(searchTerm: string) {
		return searchTerm.length >= 2 && searchTerm.length <= 60;
	}

	async submitClickFeedback(resultId: string, indexId: string, queryId: string) {
		const reqData: PostFeedbackRequest = {
			indexId,
			queryId,
			clickFeedbackItems: [{
				resultId,
				clickTime: Date.now().toString()
			}]
		};
		try {
			await SearchLoader.post('/search/feedback', reqData, 'application/json', undefined, FEEDBACK_TIMEOUT);
		} catch (err: any) {
			console.error('failed to submit click feedback', err);
		}
	}

	async submitRelevanceFeedback(isRelevant: boolean, resultId: string, indexId: string, queryId: string): Promise<void> {
		const reqData: PostFeedbackRequest = {
			indexId,
			queryId,
			relevanceFeedbackItems: [{
				resultId,
				relevanceValue: getRelevanceValue(isRelevant)
			}]
		};
		return SearchLoader.post('/search/feedback', reqData, 'application/json', undefined, FEEDBACK_TIMEOUT);
	}
}