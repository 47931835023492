import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSitemap } from '../../helpers/atoms/SitemapAtom';
import { Page } from '../../helpers/Sitemap';

import './Breadcrumb.scss';

interface IProps {
	pagePath?: string;
	noClick?: boolean;
}

export default function Breadcrumb(props: IProps) {
	const location = useLocation();
	const sitemap = useSitemap();
	const [currentPath, setCurrentPath] = useState(props.pagePath || location.pathname);

	// Constructor
	useEffect(() => {
		if (props.pagePath) {
			setCurrentPath(props.pagePath);
		} else {
			setCurrentPath(location.pathname);
		}
	}, [location.pathname, props.pagePath]);

	// Break page path into crumbs
	let pathCrumbs = currentPath.split('/').filter((part) => part !== '');

	// Drop page from crumb list
	if (!currentPath.endsWith('/')) pathCrumbs = pathCrumbs.slice(0, pathCrumbs.length - 1);

	// Convert path crumbs into page crumbs for display
	let partialPath = '';
	const pageCrumbs = [] as Page[];
	pathCrumbs.forEach((part) => {
		partialPath += `/${part}`;
		let page = sitemap.getPage(partialPath, true);
		if (!page) return;
		if (page.isDir) pageCrumbs.push(page.index || { link: page.path || './', group: 'Breadcrumb' });
		else pageCrumbs.push(page as unknown as Page);
	});

	return (
		<div className="breadcrumb">
			<span className="crumb home-crumb">{props.noClick ? 'Home' : <Link to="/">Home</Link>}</span>
			{pageCrumbs.map((page) => (
				<div className="crumb-container" key={page.link}>
					<span className="crumb">|</span>
					<span className="crumb">{props.noClick ? page.group || page.title : <Link to={page.link}>{page.group || page.title}</Link>}</span>
				</div>
			))}
		</div>
	);
}
