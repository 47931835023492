import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';

import DefaultLayout2021 from './DefaultLayout2021';
import LandingPage from './LandingPage';
import { selectedThemeAtom } from '../../theme/ThemeAtom';
import { useSitemap } from '../../helpers/atoms/SitemapAtom';
import { Page } from '../../helpers/Sitemap';
import ApiExplorerLayout from './apiexplorer/ApiExplorerLayout';

export default function LayoutController() {
	const theme = useRecoilValue(selectedThemeAtom());
	const [page, setPage] = useState<Page>();
	const location = useLocation();
	const sitemap = useSitemap();
	const navigate = useNavigate();

	useEffect(() => {
		// Truncate known page file extensions
		if (location.pathname.toLowerCase().endsWith('.md')) {
			navigate(location.pathname.substring(0, location.pathname.length - 3));
			return;
		} else if (location.pathname.toLowerCase().endsWith('.html')) {
			navigate(location.pathname.substring(0, location.pathname.length - 5));
			return;
		}

		if (!page || page.link.toLowerCase() !== location.pathname.toLowerCase()) {
			const newPage = sitemap.getPage(location.pathname) as Page;
			setPage(newPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, sitemap]);

	let layout = page?.layout || 'default';

	switch (layout) {
		case 'landingpage': {
			layout = <LandingPage />;
			break;
		}
		case 'apiexplorer': {
			layout = <ApiExplorerLayout />;
			break;
		}
		default: {
			if (page?.layout && page?.layout !== 'default') console.warn('Unrecognized layout:', layout);
			layout = <DefaultLayout2021 />;
		}
	}

	// Set theme directly on body to ensure the theme can style everything in the layout
	document.body.className = theme;

	return <div>{layout}</div>;
}
