import { LoadingPlaceholder } from 'genesys-react-components';
import React, { useEffect, useState } from 'react';
import AssetLoader from '../../../helpers/AssetLoader';
import { addToast } from '../../../helpers/atoms/ToastAtom';
import DataTable from '../../markdown/datatable/DataTable';

import './QuickHitListing.scss';

interface QuickHitIndexData {
	[id: string]: string[];
}

export default function QuickHitListing() {
	const [quickHits, setQuickHits] = useState(undefined as unknown as QuickHitIndexData);

	// Constructor
	useEffect(() => {
		AssetLoader.get('/data/quickhit/index.json', true)
			.then(setQuickHits)
			.catch((err) => addToast({ title: 'Error fetching quick hits', message: err.message || err.toString() }));
	}, []);

	if (!quickHits) return <LoadingPlaceholder text="Loading Quick Hits" />;

	console.log('QUICK HIT COUNT', Object.keys(quickHits).length, quickHits);

	return (
		<div>
			<DataTable
				sortable={true}
				filterable={true}
				headerRow={{ cells: [{ content: 'Quick Hit ID' }, { content: 'Markdown Code' }, { content: 'Available languages' }] }}
				rows={Object.keys(quickHits).map((id) => {
					return {
						cells: [{ content: id }, { content: `\`<dxui:QuickHit id="${id}" />\`` }, { content: quickHits[id].join(', ') }],
					};
				})}
			/>
		</div>
	);
}
