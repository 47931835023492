import React, { ReactNode, useEffect, useState } from 'react';
import { DxButton } from 'genesys-react-components';

import './ToggleGroup.scss';

interface IProps {
	options: [value: string, display: ReactNode][];
	onSelectionChanged: { (value: string): void };
	title?: string;
	initialValue?: string;
	selectedValue?: string;
}

export default function ToggleGroup(props: IProps) {
	const [selected, setSelected] = useState<string>(props.selectedValue || props.initialValue || '');

	useEffect(() => {
		if (props.selectedValue === undefined) return;
		setSelected(props.selectedValue);
	}, [props.selectedValue]);

	useEffect(() => {
		if (props.onSelectionChanged) props.onSelectionChanged(selected);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<div className="toggle-group">
			{props.title && <span className="toggle-label">{props.title}</span>}
			<div className="toggle-group-buttons">
				{props.options.map(([value, display]) => (
					<DxButton
						key={value}
						className="toggle-button"
						type={value === selected ? 'primary' : 'secondary'}
						onClick={() => setSelected(value)}
					>
						{display}
					</DxButton>
				))}
			</div>
		</div>
	);
}
