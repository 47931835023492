import React, { useState, useEffect } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxButton, DxTextbox, DxItemGroup, DxItemGroupItem, DxAccordionGroup, DxAccordion } from 'genesys-react-components';
import { AlertBlock } from 'genesys-react-components';

import CodeFence from '../../../codefence/CodeFence';
import { addToast, ToastType } from '../../../../helpers/atoms/ToastAtom';
import { Topic, ChannelEntityListing, MinimalTopic } from './NotificationDefinitions';
import { selectedAccountAtom } from '../../../../helpers/atoms/AccountsAtom';
import { addChannel, channelsUpdated, subscribe } from '../../../../helpers/atoms/ChannelAtoms';
import AccountSwitcher from '../../../accountswitcher/AccountSwitcher';
import TooltipButton from '../../../tooltip/TooltipButton';
import Tooltip from '../../../tooltip/Tooltip';

import './TopicContent.scss';

interface SubscriptionData {
	[parameterName: string]: string;
}

interface IProps {
	topic: Topic;
}

enum LinkType {
	Standard = 'STANDARD',
	Markdown = 'MARKDOWN',
}

export const isWebSocketCompatible = (t: Topic | MinimalTopic) => {
	if (t.transports.includes('All') && t.transports.length === 1) {
		return true;
	}

	if (t.transports.includes('Websocket') && t.transports.length >= 1) {
		return true;
	}
	return false;
};

export default function TopicContent(props: IProps) {
	const { topic } = props || [];
	const [dropdownItems, setDropdownItems] = useState<DxItemGroupItem[]>([]);
	const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>({});
	const [hasPermissions, setHasPermission] = useState(false);
	const [enforced, setEnforced] = useState(true);
	const channelAdded = useRecoilValue(channelsUpdated);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const supportsWebsocket = isWebSocketCompatible(topic);
	const userPermissionList = selectedAccount?.me?.authorization?.permissions;
	const topicPermissions = topic?.requiredPermissions || {};
	const CHANNEL = 'channel';

	useEffect(() => {
		if (!selectedAccount) {
			return;
		}

		const authorized: boolean = checkAuthorization(topicPermissions.permissionList, topicPermissions.enforced);

		setHasPermission(authorized);

		let account = selectedAccount;
		account.api
			.request({
				method: 'GET',
				url: `/api/v2/notifications/channels`,
			})
			.then((res) => {
				const data = res.data as ChannelEntityListing;
				if (!data || !data.entities) {
					setSubscriptionData({});
					setDropdownItems([]);
					return;
				}

				// Create dropdown items
				const channelIds: DxItemGroupItem[] = data.entities.map((channel) => {
					return { label: channel.id, value: channel.id } as DxItemGroupItem;
				});

				// Add placeholder
				channelIds.unshift({ label: '--- Select a channel ---', value: '', disabled: true, isSelected: channelIds.length === 0 });

				// Select the first item in the list; channels are sorted newest first by the API
				if (channelIds.length > 1) channelIds[1].isSelected = true;

				setDropdownItems(channelIds);
				paramChanged(CHANNEL, channelIds[1]?.value || '');
			})
			.catch((error) => addToast({ title: 'Failed to get available channels', message: error.message, toastType: ToastType.Critical }));
		//eslint-disable-next-line
	}, [selectedAccount, channelAdded]);

	/* Iterate through user permissions to see if they have permission provided*/
	function checkPermission(permission: string) {
		if (userPermissionList) {
			for (const p of userPermissionList) {
				if (p.startsWith(permission)) {
					return true;
				}
			}
		}

		return false;
	}

	/*Check if user has all permissions required to subscribe to the topic.
	Also flags a topic if permissions are not enforced
	*/
	function checkAuthorization(permissions?: string[], enforced?: boolean) {
		if (permissions === undefined || enforced === undefined) {
			return false;
		}

		if (!enforced) {
			setEnforced(enforced);
		}

		if (permissions.length === 0) {
			return true;
		}
		return permissions.every(checkPermission);
	}

	function renderSubscriptionAPIPath() {
		const content = (
			<div>
				<ul>
					{topic.uriPaths.map((p: any) => (
						<li key={p}>{p}</li>
					))}
				</ul>
			</div>
		);

		return (
			<div className="transport-permissions-container">
				<div>
					<h3>Api Path(s)</h3>
					{content}
				</div>
			</div>
		);
	}

	function renderTransport() {
		let content = (
			<p>
				<em>Available on {topic.transports.join(' and ')} transport types</em>
			</p>
		);
		return (
			<div>
				<h3>Transport(s)</h3>
				{content}
			</div>
		);
	}

	function renderPermissions() {
		return (
			<div>
				<h3>
					Required Permission(s)
					{hasPermissions && (
						<Tooltip text="Active account has required permissions" className="permission-tooltip">
							<GenesysDevIcon className="permission-icon" icon={GenesysDevIcons.AppCheckSolid} />
						</Tooltip>
					)}
					{!hasPermissions && !enforced && (
						<Tooltip text="Lacks permissions but not enforced" className="permission-tooltip">
							<GenesysDevIcon className="permission-icon permission-icon-warn" icon={GenesysDevIcons.AppWarnSolid} />
						</Tooltip>
					)}
					{!hasPermissions && enforced && (
						<Tooltip text="Active account lacks required permissions" className="permission-tooltip">
							<GenesysDevIcon className="permission-icon permission-icon-fail" icon={GenesysDevIcons.AppTimesSolid} />
						</Tooltip>
					)}
				</h3>
				{topicPermissions.permission}
				<br />
				<em>{topicPermissions.enforced ? '' : '(not currently enforced)'}</em>
				<ul>
					{topicPermissions.permissionList?.map((p, i) => {
						return (
							<li key={i}>
								{p}
								{checkPermission(p) ? (
									<Tooltip text="Has permission" className="permission-tooltip">
										<GenesysDevIcon className="permission-icon permission-icon-small" icon={GenesysDevIcons.AppCheck} />
									</Tooltip>
								) : (
									<Tooltip text="Lacks permission" className="permission-tooltip">
										<GenesysDevIcon
											className="permission-icon permission-icon-small permission-icon-fail"
											icon={GenesysDevIcons.AppTimes}
										/>
									</Tooltip>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	function renderTransportandPermissions() {
		return (
			<div className="transport-permissions-container">
				{renderPermissions()}
				{renderTransport()}
			</div>
		);
	}

	function renderParameters() {
		return (
			<div className="parameter-list-container">
				{!supportsWebsocket ? (
					''
				) : (
					<React.Fragment>
						<div>
							<h4>Topic Parameters</h4>
							{renderParameterList('param')}
						</div>
						<div>
							<h4>Channel Selection</h4>
							{selectedAccount && (
								<React.Fragment>
									{renderParameterList(CHANNEL)}
									<DxButton
										onClick={() => {
											addChannel();
										}}
										className="add-channel-button"
									>
										Add new channel
									</DxButton>
								</React.Fragment>
							)}
							{!selectedAccount && <em>Add an account using the account selector to view your available channels</em>}
						</div>
					</React.Fragment>
				)}
			</div>
		);
	}

	function renderParameterList(type: string) {
		if (type === CHANNEL) {
			return (
				<ul className="parameter-list">
					<li>
						<DxItemGroup
							format="dropdown"
							items={dropdownItems}
							title="Channels"
							className="channels-dropdown"
							description="Select a channel"
							onItemChanged={(item) => paramChanged(CHANNEL, item.value)}
						/>
					</li>
				</ul>
			);
		}

		if (topic.topicParameters?.length === 0 || topic.topicParameters === undefined) {
			return <p> No topic paramaters</p>;
		}

		return (
			<ul className="parameter-list">
				{topic?.topicParameters?.map((param, i) => (
					<li key={i}>
						<DxTextbox
							className="param-textbox"
							inputType="text"
							clearButton={true}
							label={param}
							onChange={(value) => paramChanged(param, value)}
						/>
					</li>
				))}
			</ul>
		);
	}

	function paramChanged(key: string, value: string) {
		let requestParameter = subscriptionData;
		if (value) {
			// Set new value
			subscriptionData[key] = value;
		} else {
			// Remove the key from the parameters object (this object is checked using Object.keys)
			delete subscriptionData[key];
		}
		setSubscriptionData(requestParameter);
	}

	function subscribeToTopic() {
		if (!selectedAccount) {
			addToast({
				title: 'Error: No active account',
				message: `Please add or select an account to subscribe`,
				toastType: ToastType.Critical,
			});
			return;
		}

		if (topic.topicParameters?.length !== Object.keys(subscriptionData).length - 1) {
			addToast({
				title: 'Error: Input Error',
				message: `Please check all input fields and make sure they are filled`,
				toastType: ToastType.Warning,
			});
			return;
		}

		let parameterValues: string[] = [];

		for (const [key, value] of Object.entries(subscriptionData)) {
			if (key !== CHANNEL) {
				parameterValues.push(value);
			}
		}

		const topicId = topic.id;

		let index = 0;
		//Replace {id} in topicId(i.e v2.architect.systemprompts.{id}.resources.{id}) with parameter values
		const updatedTopicID = topicId.replace(/{id}/gi, function () {
			return parameterValues[index++];
		});

		subscribe(subscriptionData[CHANNEL], updatedTopicID);
	}

	const copyButton = (link: string, linkType: LinkType) => {
		const formattedLink = linkType === LinkType.Markdown ? `[${props.topic.id.toLocaleLowerCase()}](${link})` : link;
		let btnText;
		switch (linkType) {
			case LinkType.Standard:
				btnText = 'Copy link';
				break;
			case LinkType.Markdown:
				btnText = 'Copy markdown link';
				break;
			default:
				btnText = '';
		}

		return (
			<TooltipButton tooltipText="Link copied" className="copy-link-button" onClick={() => navigator.clipboard.writeText(formattedLink)}>
				<GenesysDevIcon
					className="button-icon"
					icon={linkType === LinkType.Markdown ? GenesysDevIcons.BrandMarkdown : GenesysDevIcons.AppLink}
				/>
				{btnText}
			</TooltipButton>
		);
	};

	function makeOperationLink(topic: Topic) {
		return `${window.location.origin}${window.location.pathname}#${topic.idKey}`;
	}

	const renderCopyButtons = () => {
		return (
			<>
				{copyButton(makeOperationLink(props.topic), LinkType.Standard)}
				{copyButton(makeOperationLink(props.topic), LinkType.Markdown)}
			</>
		);
	};

	const requestContent = (
		<div>
			{!supportsWebsocket ? (
				<div className="response-output">
					<AlertBlock alertType="warning" className="event-bridge-warning">
						Warning: The notification tool only supports Websocket topics
					</AlertBlock>
				</div>
			) : (
				<div className="request-controls">
					<div className="account-card-container">
						<AccountSwitcher mode="mini" />
					</div>
					<div className="subscribe-button-container">
						<DxButton onClick={subscribeToTopic} className="subscribe-button">
							Subscribe
						</DxButton>
					</div>
				</div>
			)}
		</div>
	);

	return (
		<div className="topic-content">
			{renderCopyButtons()}
			<DxAccordionGroup>
				<DxAccordion title="Topic Information">
					{renderTransportandPermissions()}
					{renderSubscriptionAPIPath()}
				</DxAccordion>
				<DxAccordion title="Subscribe to Topic" showOpen={true}>
					{renderParameters()}
					{supportsWebsocket && <h3>Subscribe to Topic</h3>}
					{selectedAccount && requestContent}
					{!selectedAccount && <em>Add an account using the account selector to subscribe to this topic</em>}
				</DxAccordion>
				<DxAccordion title="Event Schema">
					{<CodeFence title={`Event schema for ${props.topic.id}`} value={JSON.stringify(topic.exampleSchema, null, 2)} language="json" />}
				</DxAccordion>
			</DxAccordionGroup>
		</div>
	);
}
