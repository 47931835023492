import React, { useEffect, useState } from 'react';
import { DxTabbedContent, DxTabPanel, LoadingPlaceholder } from 'genesys-react-components';

import AssetLoader from '../../../../helpers/AssetLoader';
import { ModelSchema, OpenAPIDefinition, OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../../helpers/openapi/SwaggerCache';
import { Language, SdkInvocation, SdkInvocationData, toSdkLanguageName } from '../../../../types';
import ModelJsonDisplay from '../display/ModelJsonDisplay';
import OperationHttpDisplay from '../display/OperationHttpDisplay';
import SdkInvocationDisplay from '../display/SdkInvocationDisplay';
import OperationCurlDisplay from '../display/OperationCurlDisplay';

import './InvocationsDisplay.scss';

interface IProps {
	operationDetails: OperationDetails;
	source?: string;
}

export default function InvocationsDisplay(props: IProps) {
	const [swagger, setSwagger] = useState<OpenAPIDefinition>();
	const [bodySchema, setBodySchema] = useState<ModelSchema | undefined>();
	const [sdkInvocationData, setSdkInvocationData] = useState<SdkInvocationData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	// Constructor
	useEffect(() => {
		// Load swagger
		(async () => setSwagger(await SwaggerCache.get(props.source)))();

		// Load SDK data
		if (process.env['REACT_APP_LOAD_SDK_INVOCATIONS'] === 'true') {
			AssetLoader.get(`/data/sdk-invocations/${props.operationDetails.operation.operationId.toLowerCase()}.json`, true, undefined)
				.then((data) => {
					setSdkInvocationData(data);
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					if (err.response && err.response.status === 404) {
						console.warn('No SDK data for operation', props.operationDetails.operation.operationId.toLowerCase());
					} else {
						console.error(err);
					}
				});
		} else {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.operationDetails]);

	// Update once swagger is loaded
	useEffect(() => {
		// Resolve body schema
		const bodyParam = props.operationDetails.operation.parameters?.find((param: any) => param.in === 'body');
		if (bodyParam && bodyParam.schema && swagger) {
			setBodySchema(SwaggerCache.resolveModelRef(swagger, bodyParam.schema as ModelSchema));
		} else {
			setBodySchema(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swagger, props.operationDetails]);

	// HACK: this is avoiding generating panels until we have all the data. DxTabbedContent doesn't respond to updates to its children.
	if (isLoading) return <LoadingPlaceholder />;

	return (
		<DxTabbedContent className="invocations-display">
			<DxTabPanel title="HTTP">
				<OperationHttpDisplay operationDetails={props.operationDetails} host={swagger?.host} />
			</DxTabPanel>
			<DxTabPanel title="cURL">
				<OperationCurlDisplay operationDetails={props.operationDetails} host={swagger?.host} />
			</DxTabPanel>
			{bodySchema && (
				<DxTabPanel title="JSON">
					<ModelJsonDisplay swagger={swagger} schema={bodySchema} isRequest={true} />
				</DxTabPanel>
			)}
			{sdkInvocationData &&
				Object.entries(sdkInvocationData as { [language: string]: SdkInvocation }).map(([language, SdkInvocation]) => (
					<DxTabPanel title={toSdkLanguageName(language as Language)} key={language}>
						<SdkInvocationDisplay data={sdkInvocationData[language as Language]} language={language as Language} />
					</DxTabPanel>
				))}
		</DxTabbedContent>
	);
}
