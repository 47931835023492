import React from 'react';
import { DxAccordion, DxAccordionGroup, DxButton } from 'genesys-react-components';

import { CategorizedOperations, TagInfo, visibilityMap } from '../../../helpers/openapi/OpenAPITypes';
import { AddApiResource } from '../../../helpers/atoms/ToolboxAtom';
import AppSettings from '../../../helpers/settings/AppSettings';
import { ApiResourceProps, ToolboxApp } from '../../../types';
import Tag from '../../cards/Tag';

import './CategoryNavigation.scss';

interface IProps {
	apiOperations: CategorizedOperations;
	filteredApiOperations?: CategorizedOperations;
	tags: TagInfo[];
}

export default function CategoryNavigation(props: IProps) {
	const filteredTags = props.tags.filter((tag) => Object.keys(props.filteredApiOperations || {}).includes(tag.name));

	return (
		<DxAccordionGroup>
			{filteredTags.map((tag) => (
				<DxAccordion
					key={tag.name}
					className="category-container"
					title={
						<div className="category-header">
							<span className="category-name">{tag.name}</span>
							{tag.description && <span className="cateogry-description">{tag.description}</span>}
						</div>
					}
				>
					{props.filteredApiOperations &&
						props.filteredApiOperations[tag.name]?.map((operation) => (
							<DxButton
								key={operation.key}
								type="link"
								className={`operation-info operation-${operation.verb.toLowerCase()}`}
								onClick={() => {
									const props = { path: operation.path, verb: operation.verb } as ApiResourceProps;
									AddApiResource({
										title: operation.title,
										appType: ToolboxApp.ApiExplorer,
										props,
									});
									AppSettings.setApiExplorerStandaloneSelectedOperation(props);
								}}
							>
								<div className="operation-key" title={operation.key}>
									<span className="verb">{operation.verb.toUpperCase()}</span>
									<span>{operation.path}</span>
									{operation.operation.deprecated && <Tag>Deprecated</Tag>}
									{operation.operation['x-genesys-visibility'] && (
										<Tag className={`tag-${operation.operation['x-genesys-visibility']}`}>
											{visibilityMap[operation.operation['x-genesys-visibility']]}
										</Tag>
									)}
								</div>
								<div className="operation-description">{operation.description}</div>
							</DxButton>
						))}
				</DxAccordion>
			))}
		</DxAccordionGroup>
	);
}
