import React, { useRef } from 'react';
import { redirect } from 'react-router-dom';

import DxLink from '../dxlink/DxLink';

interface IProps {}

const SDK_PATH_RERGEX = /\/api\/rest\/client-libraries\/([^\s/]+)\/(?:latest\/){0,1}(.+){0,1}/i;

export default function SdkRedirecter(props: IProps) {
	const match = window.location.pathname.match(SDK_PATH_RERGEX);
	const redirectIssued = useRef(false);

	const doRedirect = (sdkLang?: string, path?: string) => {
		// Determine correct redirect URL
		let targetUrl = '/devapps/sdk/docexplorer/';
		if (sdkLang) targetUrl += `${sdkLang}/`;
		if (sdkLang && path) targetUrl += path;
		if (window.location.hash) targetUrl += window.location.hash;

		// Debounce redirection; don't redirect on every render
		if (redirectIssued.current !== true) {
			redirect(targetUrl);
			redirectIssued.current = true;
		}

		// Render a link just in case the redirect doesn't work
		return (
			<div>
				Redirecting to the <DxLink href={targetUrl}>SDK Documentation Explorer</DxLink>...
			</div>
		);
	};

	// Not sure what the URL is, go to the main doc explorer page
	if (!match) return doRedirect();

	// Figure out new SDK language doc dir
	let sdkLang = '';
	switch (match[1]) {
		case 'cli-sdk':
		case 'cli': {
			sdkLang = 'clisdkclient';
			break;
		}
		case 'csharp':
		case 'dotnet': {
			sdkLang = 'pureclouddotnet';
			break;
		}
		case 'go': {
			sdkLang = 'purecloudgo';
			break;
		}
		case 'ios': {
			sdkLang = 'purecloudios';
			break;
		}
		case 'java': {
			sdkLang = 'purecloudjava';
			break;
		}
		case 'java-guest': {
			sdkLang = 'purecloudjava-guest';
			break;
		}
		case 'javascript': {
			sdkLang = 'purecloudjavascript';
			break;
		}
		case 'javascript-guest': {
			sdkLang = 'purecloudjavascriptGuest';
			break;
		}
		case 'python': {
			sdkLang = 'purecloudpython';
			break;
		}
		case 'web-messaging-java': {
			sdkLang = 'webmessagingjava';
			break;
		}
	}

	return doRedirect(sdkLang, match[2]);
}
