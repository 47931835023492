import { RecoilState } from 'recoil';
import { AxiosRequestConfig } from 'axios';
import { Channel } from './components/tools/notifications/notificationtopics/NotificationDefinitions';
import { Region } from './helpers/platformapi/Region';

export interface SettingsPanelProps {
	page: SettingsPage;
}

export enum SettingsPage {
	PRIVACY = 'privacy',
	DEV_APPS = 'dev-apps',
}

export enum ToolboxApp {
	ApiExplorer = 'apiexplorer',
	Notifications = 'notifications',
	Pages = 'pages',
	Settings = 'settings',
}

export interface ToolboxItem {
	title: string;
	appType?: ToolboxApp;
	props: SettingsPanelProps | PageInfo | Channel | ApiResourceProps;
	disableUserRemove?: boolean;
	isSelected?: boolean;
}

export type ToolboxItems = {
	[app in ToolboxApp]: ToolboxItem[];
};

export interface ToolboxSize {
	width: number;
	height: number;
}

export interface ApiResourceProps {
	path: string;
	verb: string;
}

export interface PageInfo {
	// Title is derived based on parsing rules
	title: string;

	// link contains value to use for clickable links to this resource
	link: string;
	// contentType indicates the type of the content for this page
	contentType: PageContentType;
	// contentPath contains the path to the content to be served for the page. Must be consumed in context of contentType.
	contentPath?: string;

	// Known frontmatter properties
	group?: string;
	author?: string;
	order?: string;
	ispreview?: string;
	isbeta?: string;
	redirect?: string;
	hardRedirect?: string;
	forceShow?: string;
	newuntil?: string;
	nosearch?: string;
	suppressSidebarLinks?: string;
	notoc?: string;
	hideTitle?: string;
	layout?: string;
	component?: string;

	// Accessor for custom frontmatter properties
	[frontmatterKey: string]: string | boolean | any | undefined;
}

export enum PageContentType {
	Markdown,
	HTML,
	PDF,
	Image,
	PageComponent,
	Download,
	Redirect,
	HardRedirect,
}

export interface StorageProvider {
	isAvailable(): boolean;
	initialize(storeName: string): void;
	getItem(key: string): any;
	setItem(key: string, value: any): any;
	deleteItem(key: string): void;
	purge(): Promise<void>;
}

export interface Setting {
	key: string;
	value: any;
}

export interface ChangeCallback {
	(key: string, value: any): void;
}

export interface OnSetEffect<T> {
	(value: T): void;
}

export interface AtomList {
	[settingKey: string]: RecoilState<any> | undefined;
}

export const SettingNames = {
	// Core settings
	AllowStorage: 'allow-storage',
	AllowTelemetry: 'allow-telemetry',

	// Developer Application Settings
	AllowSyntaxHighlighting: 'allow-syntax-highlighting',
	SyntaxHighlightingLimitKb: 'sytax-highlighting-limit',

	// Toolbox
	SelectedToolboxApp: 'selectedToolboxApp',
	SelectedToolboxItem: 'selectedToolboxItem',
	ToolboxItems: 'toolboxItems',

	// Theme
	SelectedTheme: 'selected-theme',

	// API Explorer
	ApiExplorerCateogryFilters: 'api-explorer-category-filters',
	ApiExplorerReadingMode: 'api-explorer-reading-mode',
	ApiExplorerProMode: 'api-explorer-pro-mode',
	ApiExplorerStandaloneSelectedOperation: 'api-explorer-standalone-selected-operation',
	ApiExplorerNavMode: 'api-explorer-nav-mode',

	// Paginator
	PaginatorPageSize: 'paginator-page-size',

	//Webchat/messenger
	WebChatData: 'webchat-data',
	WebChatSettings: 'webchat-settings',

	// Collapsible layout
	NavigationCollapsed: 'navigation-collapsed',
	SidebarCollapsed: 'sidebar-collapsed',

	// Logo
	LogoChoice: 'logo-choice',
};

export interface AccountData {
	token: string;
	region: Region;
	userId?: string;
	safeMode?: boolean;
}

export enum AnemiaTheme {
	Default = 'theme-anemia',
	DefaultDark = 'theme-anemia-dark',
	// Solarized = 'theme-solarized',
	// SolarizedDark = 'theme-solarized-dark',
}

export interface AuthorCatalog {
	[authorName: string]: AuthorData | undefined;
}

export interface AuthorData {
	name: string;
	title?: string;
	company?: string;
	bio?: string;
	social?: {
		twitter?: string;
		github?: string;
		youtube?: string;
		linkedin?: string;
	};
}

export enum MatchType {
	Exact,
	StartsWith,
}

export interface ContentPage {
	title: string;
	component: string;
	matchType: MatchType;
	notoc?: boolean;
	link?: string;
}

export interface SdkParameter {
	name: string;
	type: string;
}

export interface SdkInvocation {
	functionName: string;
	signature: string;
	parameters?: SdkParameter[];
	return?: string;
	example?: string;
}

export type SdkInvocationData = {
	[language in Language]: SdkInvocation;
};

export interface SavedWebChatData {
	id: string;
	name: string;
	data: ChatData;
}

export interface ChatData {
	id?: string;
	firstName?: string;
	lastName?: string;
	addressStreet?: string;
	addressCity?: string;
	addressState?: string;
	addressPostalCode?: string;
	phoneNumber?: string;
	email?: string;
	locale?: string;
	welcomeMessage?: string;
	customField1?: string;
	customField1Label?: string;
	customField2?: string;
	customField2Label?: string;
	customField3?: string;
	customField3Label?: string;
	[propName: string]: any;
}

export interface WebChatSettingsData {
	selectedDeployment?: string;
	selectedQueue?: string;
}

export interface WebchatSettings {
	version1: WebChatSettingsData;
	version2: WebChatSettingsData;
	messenger: WebChatSettingsData;
}

export enum Language {
	Cli = 'clisdkclient',
	Dotnet = 'pureclouddotnet',
	Go = 'purecloudgo',
	Ios = 'purecloudios',
	Java = 'purecloudjava',
	JavaGuest = 'purecloudjava-guest',
	Javascript = 'purecloudjavascript',
	JavascriptGuest = 'purecloudjavascriptGuest',
	Python = 'purecloudpython',
	WebMessagingJava = 'webmessagingjava',
}

export function toSdkLanguageName(language: Language): string {
	switch (language) {
		case Language.Cli:
			return 'CLI';
		case Language.Dotnet:
			return '.NET SDK';
		case Language.Go:
			return 'GO SDK';
		case Language.Ios:
			return 'iOS SDK';
		case Language.Java:
			return 'Java SDK';
		case 'purecloudjavaGuest' as Language:
		case Language.JavaGuest:
			return 'Java Guest Chat SDK';
		case Language.Javascript:
			return 'JavaScript SDK';
		case Language.JavascriptGuest:
			return 'JavaScript Guest Chat SDK';
		case Language.Python:
			return 'Python SDK';
		case Language.WebMessagingJava:
			return 'Java WebMessaging SDK';
		default:
			return language;
	}
}

/*** API Explorer ***/

export interface HeaderDictionary {
	[headerName: string]: string;
}

export interface ParameterData {
	[parameterName: string]: string | string[];
}

export interface HeaderData {
	name: string;
	value: string;
	isSecret?: boolean;
	disableUserRemove?: boolean;
	prefix?: string;
}

export interface HeaderDataUpdatedCallback {
	(data: HeaderData[]): void;
}

export enum AuthNames {
	GenesysCloudOAuth = 'PureCloud OAuth',
	GuestChat = 'Guest Chat JWT',
	WebMessaging = 'Webmessaging JWT',
}

export enum LogoChoice {
	Default,
	Pride,
}

/** Analytics and Search queries **/

export interface EntityData {
	id: string;
	name: string;
}

export interface EntityResponse {
	config?: any;
	data?: any;
	headers?: any;
	request?: any;
	status?: number;
	statusText?: string;
}

export type EntityCallback = (a: any) => EntityData;

export type GetEntityFuncOuter = (a?: EntityData[]) => Promise<EntityData[]>;
export type GetEntityFuncOuterNoParam = () => Promise<EntityData[]>;
export type GetEntityFuncInner = (
	a: string,
	b?: EntityCallback,
	c?: EntityData[],
	d?: string,
	e?: AxiosRequestConfig
) => Promise<EntityData[]>;

export enum ApiExplorerNavMode {
	Category = 'category',
	Path = 'path',
}
