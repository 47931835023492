import React from 'react';

import ContentImage from '../components/image/ContentImage';
import { AuthorCatalog, AuthorData } from '../types';

const defaultAuthor = 'yuri.yeti';

class AuthorResolver {
	authors: AuthorCatalog = {};

	// constructor() {
	// 	AssetLoader.get('/data/authors/authors.yml', true)
	// 		.then((data) => (this.authors = (yaml.load(data) || {}) as AuthorCatalog))
	// 		.catch(console.error);
	// }

	getData(authorName: string): AuthorData {
		return this.authors[authorName] || this.authors[defaultAuthor] || ({ name: authorName } as AuthorData);
	}

	getImage(authorName: string, className?: string): React.ReactNode {
		if (!this.authors[authorName]) authorName = defaultAuthor;
		return <ContentImage src={`/data/authors/${authorName}.png`} className={className} />;
	}
}

export default new AuthorResolver();
