import React, { ReactNode } from 'react';
import { YeastChild, YeastNode } from 'yeast-core';
import { NodeRendererPlugin, ReactRenderer, useKey } from 'yeast-react-renderer';

import NotificationTool from '../../../tools/notifications/notificationtopics/NotificationTool';
import AvailableMediaTypeTool from '../../../tools/routing/availablemediatypes/AvailableMediaTypeTool';
import OpenAPIExplorer from '../../../tools/openapi/OpenAPIExplorer';
import PostmanFiles from '../../../embeddable/postmanfiles/PostmanFiles';
import QuickHitListing from '../../../embeddable/quickhit/QuickHitListing';
import SwaggerDiff from '../../../embeddable/SwaggerDiff/swaggerDiff';
import PremiumAppSubmission from '../../../tools/premium-app-submission/PremiumAppSubmission';
import ScreenShare from '../../../tools/screenshare/ScreenShare';
import WebChatAndMessenger from '../../../tools/webchat/WebChatAndMessenger';
import ApplicationInspector from '../../../tools/application-inspector/ApplicationInspector';
import CardCatalog from '../../../cards/CardCatalog';
import Changelog from '../../../changelog/Changelog';
import SdkDocExplorer from '../../../embeddable/sdkdocexplorer/SdkDocExplorer';
import QuickHit from '../../../embeddable/quickhit/QuickHit';
import RelativeSitemap from '../../../embeddable/relativesitemap/RelativeSitemap';

export enum YeastCustomNodesTypes {
	OpenAPIExplorer = 'openapiexplorer',
	NotificationTool = 'notificationtool',
	AvailableMediaTypeTool = 'availablemediatypetool',
	PostmanFiles = 'postmanfiles',
	QuickHit = 'quickhit',
	QuickHitListing = 'quickhitlisting',
	ScreenShare = 'screenshare',
	SwaggerDiff = 'swaggerdiff',
	WebChatAndMessenger = 'webchatandmessenger',
	PremiumAppSubmission = 'premiumappsubmission',
	ApplicationInspector = 'applicationinspector',
	BlogIndex = 'blogindex',
	BlueprintIndex = 'blueprintindex',
	Changelog = 'changelog',
	SdkDocExplorer = 'sdkdocexplorer',
	GuideIndex = 'guideindex',
	RelativeSitemap = 'relativesitemap',
}

interface OpenAPIExplorerNode {
	type: YeastCustomNodesTypes.OpenAPIExplorer;
	children: never;
	search?: boolean;
	showAll?: boolean;
	source?: string;
}

interface QuickHitNode {
	type: YeastCustomNodesTypes.QuickHit;
	id: string;
	title?: string;
	defaultTitle?: string;
}

const CustomComponentRendererPlugin: NodeRendererPlugin = (node: YeastChild, renderer: ReactRenderer): ReactNode => {
	const key = useKey();
	const typedNode = node as YeastNode;
	if (!typedNode.type) return;

	switch (typedNode.type.toLowerCase()) {
		case YeastCustomNodesTypes.NotificationTool: {
			return <NotificationTool key={key.current} source="/data/notificationtopics.json" />;
		}
		case YeastCustomNodesTypes.AvailableMediaTypeTool: {
			return <AvailableMediaTypeTool key={key.current} source="/data/availablemediatypes.json" />;
		}
		case 'swaggerlive': // legacy name for api explorer, shouldn't be used
		case YeastCustomNodesTypes.OpenAPIExplorer: {
			if (!Object.hasOwn(node, 'type') || (node as any).type.toLowerCase() !== YeastCustomNodesTypes.OpenAPIExplorer) return undefined;
			const typedNode = node as OpenAPIExplorerNode;
			return <OpenAPIExplorer key={key.current} source={typedNode.source} showAll={typedNode.showAll} search={typedNode.search} />;
		}
		case YeastCustomNodesTypes.PostmanFiles: {
			return <PostmanFiles key={key.current} />;
		}
		// TODO: set up quick hit prop mapping
		case YeastCustomNodesTypes.QuickHit: {
			if (!Object.hasOwn(node, 'type') || (node as any).type.toLowerCase() !== YeastCustomNodesTypes.QuickHit) return undefined;
			const typedNode = node as QuickHitNode;
			return <QuickHit id={typedNode.id} title={typedNode.title} defaultTitle={typedNode.defaultTitle} />;
		}
		case YeastCustomNodesTypes.QuickHitListing: {
			return <QuickHitListing key={key.current} />;
		}
		case YeastCustomNodesTypes.ScreenShare: {
			return <ScreenShare key={key.current} />;
		}
		case YeastCustomNodesTypes.SwaggerDiff: {
			return <SwaggerDiff key={key.current} />;
		}
		case YeastCustomNodesTypes.WebChatAndMessenger: {
			return <WebChatAndMessenger key={key.current} />;
		}
		case YeastCustomNodesTypes.PremiumAppSubmission: {
			return <PremiumAppSubmission key={key.current} />;
		}
		case YeastCustomNodesTypes.ApplicationInspector: {
			return <ApplicationInspector key={key.current} />;
		}
		case YeastCustomNodesTypes.BlogIndex: {
			return <CardCatalog key={key.current} source="/data/blogs.json" cardStyle="image" useCategorySubtitle={true} />;
		}
		case YeastCustomNodesTypes.BlueprintIndex: {
			return <CardCatalog key={key.current} source="/data/blueprints.json" cardStyle="image" useCategorySubtitle={true} />;
		}
		case YeastCustomNodesTypes.Changelog: {
			return <Changelog key={key.current} />;
		}
		case YeastCustomNodesTypes.SdkDocExplorer: {
			return <SdkDocExplorer key={key.current} />;
		}
		case YeastCustomNodesTypes.GuideIndex: {
			return <CardCatalog key={key.current} source="/data/guides.json" cardStyle="plain" useCategorySubtitle={true} />;
		}
		case YeastCustomNodesTypes.RelativeSitemap: {
			return <RelativeSitemap key={key.current} />;
		}
	}
};

export default CustomComponentRendererPlugin;
