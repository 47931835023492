import axios from 'axios';
import qs from 'qs';

import { CategorizedOperations, ModelSchema, OpenAPIDefinition, OperationDetails } from './OpenAPITypes';
import SettingsManager from '../settings/SettingsManager';
import CmsApi from '../CmsApi';

interface ICache {
	[url: string]: any;
}

interface HealthCheckResponse {
	name: string;
	'Build-Time': string;
	'Build-Version': string;
	'Implementation-Version': string;
}

const OpenAPIDefinitionUrlRegex = /^https?:\/\//i;

class SwaggerCache {
	private cache: ICache;
	private promiseCache: ICache;
	private defaultSwaggerUrl = process.env.REACT_APP_DEFAULT_SWAGGER_URL || 'https://api.mypurecloud.com/api/v2/docs/swagger';
	// The override URL is parsed from the query string parameter "openapidefinition". Consumers of the cache must choose to use this property; the cache itself does not apply the override.
	public overrideSwaggerUrl: string | undefined;
	public overrideApiHost: string | undefined;

	constructor() {
		this.cache = {};
		this.promiseCache = {};
		this.checkQueryParams();
	}

	checkQueryParams() {
		// Parse OpenAPI definition override from query string
		const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
		if ((queryParams.openapidefinition as string)?.match(OpenAPIDefinitionUrlRegex)) {
			this.overrideSwaggerUrl = queryParams.openapidefinition as string;
			this.overrideApiHost = queryParams.openapihost as string;
		}
	}

	async get(url = this.defaultSwaggerUrl): Promise<OpenAPIDefinition> {
		if (this.cache[url]) return this.cache[url];

		if (!this.promiseCache[url]) {
			// Recheck query params that may have been added via in-site navigation
			this.checkQueryParams();
			if (url === this.defaultSwaggerUrl && this.overrideSwaggerUrl) {
				url = this.overrideSwaggerUrl;
			}

			// NOTE: This logic is encapsulated in its own function becase eslint doesn't like the promise function being marked as async, even though it compiles
			const getSwagger = async (resolve: any, reject: any) => {
				try {
					// Create sanitized keys
					const localCacheName = `openapi-cache-${url.toLowerCase().replace(/[^a-z0-9]/gi, '-')}`;
					const localCacheBuildNumberName = `openapi-cache-build-number-${url.toLowerCase().replace(/[^a-z0-9]/gi, '-')}`;
					const hostname = url.startsWith('/') ? window.location.hostname : new URL(url).hostname || 'api.mypurecloud.com';
					let currentBuildNumber = -1;

					// Use cache for published definitions; a definition from localhost should never be cached
					if (hostname.toLowerCase() !== 'localhost' && SettingsManager.getStorageAllowed()) {
						// Get current API build number
						if (hostname.startsWith('api.')) {
							const healthCheckResponse = await axios.get<HealthCheckResponse>(`https://${hostname}/api/v2/health/check`);
							currentBuildNumber = parseInt(healthCheckResponse.data ? healthCheckResponse.data['Build-Version'] || '0' : '0') || 0;
						} else {
							currentBuildNumber = 0;
						}

						// Try to load a cached definition
						try {
							// Load from cache
							const cachedDefinition: OpenAPIDefinition | undefined =
								((await SettingsManager.getDirect(localCacheName)) as OpenAPIDefinition) || undefined;
							const cachedBuildNumber = (await SettingsManager.getDirect(localCacheBuildNumberName)) || -1;

							// Positive version match, use cached version
							if (cachedBuildNumber === currentBuildNumber && cachedDefinition) {
								// Intentional trace for client-side debugging
								console.info(`Using cached OpenAPI definition\n  build: ${currentBuildNumber}\n  host: ${hostname}`);

								// Quick sanity check
								if (!cachedDefinition.swagger || !cachedDefinition.paths) throw new Error('something is wrong with the cached definition');

								// Set to in-memory cache
								this.cache[url] = cachedDefinition;

								// Return OpenAPI definition
								resolve(cachedDefinition);
								return;
							}
						} catch (e) {
							console.warn('failed to load OpenAPI definition from cache:', e);
						}
					}

					// Load definition from URL
					const data: OpenAPIDefinition | undefined = url.startsWith('/')
						? (await CmsApi.getAssetContent(url))?.content
						: (await axios.get(url)).data;
					if (!data) throw Error('Failed to fetch OpenAPI defintion from ' + url);

					if (this.overrideApiHost) data.host = this.overrideApiHost;

					// Set to in-memory cache
					this.cache[url] = data;

					// Save to local storage
					if (currentBuildNumber > 0 && SettingsManager.getStorageAllowed()) {
						try {
							SettingsManager.setDirect(localCacheName, data);
							SettingsManager.setDirect(localCacheBuildNumberName, currentBuildNumber);
						} catch (e) {
							console.warn('Failed to save the swagger document to Indexed DB storage!', e);
						}
					}

					// Return OpenAPI definition
					resolve(data);
				} catch (err) {
					reject(err);
				}
			};
			this.promiseCache[url] = new Promise((resolve, reject) => getSwagger(resolve, reject));
		}
		return this.promiseCache[url];
	}

	getSync(url = this.defaultSwaggerUrl) {
		return this.cache[url];
	}

	async getCategorizedOperations(url = this.defaultSwaggerUrl) {
		const openApiDefinition = (await this.get(url)) as OpenAPIDefinition;

		const ops: CategorizedOperations = {};

		// Iterate paths
		Object.entries(openApiDefinition.paths).forEach(([path, pathGroup]) => {
			// Iterate path group (key=verb)
			Object.entries(pathGroup).forEach(([verb, operationInfo]) => {
				if (!operationInfo) return;
				operationInfo.tags.forEach((tag) => {
					if (!ops[tag]) {
						ops[tag] = [];
					}
					ops[tag]!.push({
						key: this.makeOperationKey(verb, path),
						title: this.makeOperationTitle(verb, path),
						verb,
						path,
						description: operationInfo.summary || operationInfo.description || '',
						tags: operationInfo?.tags || [],
						operation: operationInfo,
					});
				});
			});
		});

		Object.values(ops).forEach(this.sortOperations);

		return ops;
	}

	getModelNameFromRef(ref: string) {
		const match = /^#\/definitions\/(.+)$/i.exec(ref);
		return match ? match[1] : undefined;
	}

	//TODO: can't use the enum extension; it's being removed
	// PoC to check for the enum extension on models
	// hasEnumValues(name: string, model: ModelSchema) {
	// 	if (model['x-genesys-enum-members']) {
	// 		const e = model['x-genesys-enum-members'];
	// 		e.forEach((m) => {
	// 			if (Object.keys(m).length > 1) console.log(name, e);
	// 		});
	// 	}
	// 	if (model.properties) {
	// 		for (const [name, prop] of Object.entries(model.properties)) {
	// 			this.hasEnumValues(name, prop as ModelSchema);
	// 		}
	// 	}
	// 	if (model.items) {
	// 		this.hasEnumValues(name, model.items);
	// 	}
	// }

	// resolveModel is the legacy process used by the static schema display. It truncates recursive models. Use getModel instead.
	resolveModel(definition: OpenAPIDefinition, model: ModelSchema, knownModels: string[] = []): any {
		// console.log('resolveModel', model);

		// It's a ref, return the definition
		if (model.$ref) {
			let modelName = /^#\/definitions\/(.+)$/i.exec(model.$ref);
			if (!modelName) return model;
			// console.log(modelName[1]);

			// Deep copy model to new object and tidy up
			let modelSchema = JSON.parse(JSON.stringify(Object.assign({}, model)));
			if (modelSchema.$ref) delete modelSchema.$ref;
			modelSchema.__modelName = modelName[1];

			// Mark as recursive or apply model definition
			if (knownModels.includes(modelName[1])) {
				modelSchema.__isRecursive = true;
			} else {
				knownModels.push(modelName[1]);
				// Deep copy definition onto schema
				modelSchema = JSON.parse(JSON.stringify(Object.assign(modelSchema, definition.definitions[modelName[1]])));
			}

			// Return the resolved model
			return this.resolveModel(definition, modelSchema, knownModels);
		}

		// Crawl properties and resolve them
		if (model.type === 'object' && model.properties) {
			Object.keys(model.properties).forEach((key) => {
				// console.log(`checking ${key}`);
				if (model.properties) {
					model.properties[key].__propertyName = key;
					model.properties[key] = this.resolveModel(definition, model.properties[key], knownModels);
					if (model.required && model.required.includes(key)) {
						model.properties[key].__isRequired = true;
					}
				}
			});
		} else if (model.type === 'object' && model.additionalProperties) {
			// console.log('MODEL', model);
			model.additionalProperties.__isMap = true;
			// model.additionalProperties.__propertyName = model.__propertyName;
			model.additionalProperties = this.resolveModel(definition, model.additionalProperties, knownModels);
			// model.additionalProperties.__modelName = `Map<string, ${model.additionalProperties.__modelName || 'object'}>`;
			if (model.additionalProperties) model.__modelName = model.additionalProperties.__modelName;
		} else if (model.type === 'object') {
			model.__isPrimitiveObject = true;
		} else if (model.type === 'array' && model.items) {
			model.items = this.resolveModel(definition, model.items, knownModels);
		}

		return model;
	}

	makeOperationKey(verb: string, path: string) {
		return `${verb.toLowerCase()}${path.replace(/[/{}]/g, '-')}`;
	}

	makeOperationTitle(verb: string, path: string) {
		return `${verb.toUpperCase()} ${path}`;
	}

	getModel(definition: OpenAPIDefinition, ref: string): ModelSchema | undefined {
		if (!ref) return;

		// Get model name
		const match = ref.match(/^#\/definitions\/(.+)$/i);
		if (!match || !definition.definitions[match[1]]) return;
		const modelName = match[1];

		// Deep copy model to new object and set name helper
		let model = JSON.parse(JSON.stringify(Object.assign({}, definition.definitions[modelName])));
		model.__modelName = modelName;

		// Crawl properties and set helper props
		if (model.type === 'object' && model.properties) {
			Object.keys(model.properties).forEach((key) => {
				// console.log(`checking ${key}`);
				model.properties[key].__propertyName = key;
				if (model.required && model.required.includes(key)) {
					model.properties[key].__isRequired = true;
				}
			});
		} else if (model.type === 'object' && model.additionalProperties) {
			// console.log('MODEL', model);
			model.additionalProperties.__isMap = true;
		} else if (model.type === 'object') {
			model.__isPrimitiveObject = true;
		}

		return model;
	}

	applyModelSchema(target: any, modelSchema: ModelSchema) {
		let dest = Object.assign(target, modelSchema);
		if (dest.$ref) delete dest.$ref;
		return dest;
	}

	resolveModelRef(definition: OpenAPIDefinition, modelSchema: ModelSchema) {
		if (modelSchema.$ref) {
			const model = this.getModel(definition, modelSchema.$ref);
			Object.assign(modelSchema, model);
			delete modelSchema.$ref;
		}

		return modelSchema;
	}

	// Sorts an array of operations in place
	sortOperations(operations?: OperationDetails[]) {
		if (!operations) return operations;

		return operations.sort((a, b) => {
			// Same path, sort by verb
			if (a.path === b.path) return a.verb > b.verb ? 1 : -1;

			const aParts = a.path.split('/');
			const bParts = b.path.split('/');

			// Sort by matching part, but one is shorter (e.g. data vs. dataactions)
			if (b.path.startsWith(a.path)) return -1;
			if (a.path.startsWith(b.path)) return 1;

			// Same length, sort alphabetically
			for (let i = 0; i < aParts.length; i++) {
				// Same part, move on
				if (aParts[i] === bParts[i]) continue;

				// Sort alphabetically
				return aParts[i] > bParts[i] ? 1 : -1;
			}

			// This can't logically happen, but makes the linter happy
			return 0;
		});
	}
}

export default new SwaggerCache();
