/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DxAccordion, DxAccordionGroup, DxButton, DxItemGroupItemValue, LoadingPlaceholder } from 'genesys-react-components';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { setFilters } from '../../../helpers/atoms/InPageFilters';
import { CategorizedOperations, OpenAPIDefinition, OperationDetails } from '../../../helpers/openapi/OpenAPITypes';
import AppSettings from '../../../helpers/settings/AppSettings';
import { Account } from '../../../helpers/accounts/Account';
import { selectedAccountAtom, setSelectedAccount } from '../../../helpers/atoms/AccountsAtom';
import { EntityData } from '../../../types';
import OperationTile from './OperationTile';
import OperationFilterWidget, { HttpVerb, HttpVerbFilter, defaultHttpVerbFilter } from './OperationFilterWidget';
import SwaggerCache from '../../../helpers/openapi/SwaggerCache';

import './OpenAPIExplorer.scss';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

interface IProps {
	swaggerTag?: string;
	path?: string;
	verb?: string;
	search?: boolean;
	searchLabel?: string;
	initialSearchTerm?: string;
	source?: string;
	showAll?: boolean;
	showExpanded?: boolean;
	preventCollapse?: boolean;
}

export default function OpenAPIExplorer(props: IProps) {
	const filteredTags = useRecoilValue(AppSettings.apiExplorerCateogryFiltersAtom());
	const selectedAccount = useRecoilValue(selectedAccountAtom);
	const [swagger, setSwagger] = useState<OpenAPIDefinition | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [apiOperations, setApiOperations] = useState<CategorizedOperations>();
	const [filteredOperations, setFilteredApiOperations] = useState<CategorizedOperations>();
	const [areAllExpanded, setAreAllExpanded] = useState<boolean>(true);
	const [areAllExpandedTrigger, setAreAllExpandedTrigger] = useState<any>();
	// WORKAROUND: This atom is pre-loaded here even though it's not used because it will cause suspense when loading the atom for the first time when an operation is expanded.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const proMode = useRecoilValue(AppSettings.apiExplorerProModeAtom());
	let openAPIDefinitionSource = SwaggerCache.overrideSwaggerUrl || props.source;

	// Constructor
	useEffect(() => {
		(async () => {
			setSwagger(await SwaggerCache.get(openAPIDefinitionSource));
			setApiOperations(await SwaggerCache.getCategorizedOperations(openAPIDefinitionSource));
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Scroll to anchor point
		if (window.location.hash) {
			setTimeout(() => {
				let element = document.getElementById(window.location.hash.replace('#', ''));
				element?.scrollIntoView();
			}, 100);
		}
	}, [swagger]);

	useEffect(() => {
		const uninitialized: boolean =
			!isLoading &&
			!!selectedAccount &&
			!selectedAccount.isLoading &&
			!selectedAccount.divisionCache &&
			!selectedAccount.userCache &&
			!selectedAccount.queueCache &&
			!selectedAccount.orgPresenceCache &&
			!selectedAccount.systemPresenceCache;
		if (uninitialized) initializeAccountEntities();
	}, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!props.search || !swagger) return;

		setFilters([
			{
				label: 'Category',
				options: (
					Object.entries(swagger.paths)
						.map(([path, pathGroup]) => {
							return Object.entries(pathGroup)
								.map(([verb, operationInfo]) => operationInfo?.tags || [])
								.flat();
						})
						.flat()
						.filter((value, index, self) => self.indexOf(value) === index)
						.map((tag) => {
							return { label: tag, value: tag, isSelected: !filteredTags?.includes(tag) };
						}) || []
				).sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0)),
				onItemsChanged: (items: DxItemGroupItemValue[]) => {
					if (items.length === 0) return;
					AppSettings.setApiExplorerCateogryFilters(items.filter((item) => !item.isSelected).map((item) => item.item.value));
				},
			},
		]);

		return () => {
			setFilters(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.search, swagger]);

	const initializeAccountEntities = async () => {
		if (!selectedAccount) return;

		const preCacheAccount: Account = Object.assign(Object.create(Object.getPrototypeOf(selectedAccount)), selectedAccount);
		const postCacheAccount: Account = Object.assign(Object.create(Object.getPrototypeOf(preCacheAccount)), preCacheAccount);

		if (!selectedAccount?.userCache) {
			try {
				preCacheAccount.setIsLoading(true);
				setSelectedAccount(preCacheAccount);
				setIsLoading(true);
				const entities: EntityData[] = await selectedAccount.getUsers();
				postCacheAccount.setUserCache(entities);
			} catch (err: any) {
				console.error("Failed to load users for the logged-in account's organization", err);
			}
		}
		if (!selectedAccount.queueCache) {
			try {
				if (!preCacheAccount.isLoading) preCacheAccount.setIsLoading(true);
				setSelectedAccount(preCacheAccount);
				setIsLoading(true);
				const entities = await selectedAccount.getQueues();
				postCacheAccount.setQueueCache(entities);
			} catch (err: any) {
				console.error("Failed to load queues for the logged-in account's organization", err);
			}
		}
		if (!selectedAccount.divisionCache) {
			try {
				if (!preCacheAccount.isLoading) preCacheAccount.setIsLoading(true);
				setSelectedAccount(preCacheAccount);
				setIsLoading(true);
				const entities: EntityData[] = await selectedAccount.getDivisions();
				postCacheAccount.setDivisionCache(entities);
			} catch (err: any) {
				console.error("Failed to load divisions for the logged-in account's organization", err);
			}
		}
		if (!selectedAccount.orgPresenceCache) {
			try {
				if (!preCacheAccount.isLoading) preCacheAccount.setIsLoading(true);
				setSelectedAccount(preCacheAccount);
				setIsLoading(true);
				const entities: EntityData[] = await selectedAccount.getOrgPresences();
				postCacheAccount.setOrgPresenceCache(entities);
			} catch (err: any) {
				console.error("Failed to load org presences for the logged-in account's organization", err);
			}
		}
		if (!selectedAccount.systemPresenceCache) {
			try {
				if (!preCacheAccount.isLoading) preCacheAccount.setIsLoading(true);
				setSelectedAccount(preCacheAccount);
				setIsLoading(true);
				const entities: EntityData[] = await selectedAccount.getSystemPresences();
				postCacheAccount.setSystemPresenceCache(entities);
			} catch (err: any) {
				console.error("Failed to load system presences for the logged-in account's organization", err);
			}
		}
		setIsLoading(false);
		postCacheAccount.setIsLoading(false);
		setSelectedAccount(postCacheAccount);
	};

	const makeTiles = (operations?: OperationDetails[]) => {
		if (!operations) return undefined;
		return operations.map((operation: OperationDetails, i: number) => (
			<OperationTile
				operationDetails={operation}
				key={i}
				source={openAPIDefinitionSource}
				showExpanded={props.showExpanded}
				preventCollapse={props.preventCollapse}
			/>
		));
	};

	const displayResources = () => {
		if (!filteredOperations) {
			return <LoadingPlaceholder text="Loading API definition, this may take a while" />;
		}
		if (!!filteredOperations && Object.keys(filteredOperations).length === 0) {
			return <em>No resources match the applied filters</em>;
		}

		// Apply component prop filters
		if (props.swaggerTag || props.verb || props.path) {
			let ops: (OperationDetails[] | undefined)[] = [];

			// Filter by swaggerTag
			const swaggerTagFilter = props.swaggerTag?.split(',').map((s) => s.toLowerCase().trim());
			if (swaggerTagFilter) {
				Object.entries(filteredOperations)
					.filter(([tag]) => swaggerTagFilter.includes(tag.toLowerCase()))
					.forEach(([, val]) => ops.push(val));
			} else {
				ops = Object.values(filteredOperations);
			}

			// Filter verb/path
			const filterPath = props.path?.toLowerCase() || '';
			const filterVerb = props.verb?.toLowerCase() || '';
			if (filterVerb || filterPath) {
				ops.forEach((opGroup) => {
					opGroup = opGroup?.filter((op) => {
						if (filterVerb && op.verb.toLowerCase() !== filterVerb) return false;
						if (filterPath && !op.path.toLowerCase().startsWith(filterPath)) return false;
						return true;
					});
				});
			}

			// Show placeholder if nothing left after these filters
			if (ops.length === 0) {
				return <em>No resources match the applied filters</em>;
			}

			// Display list without category segments
			return ops.map(makeTiles);
		}

		return (
			<React.Fragment>
				<div className="expand-all-buttons">
					<DxButton
						type="link"
						onClick={() => {
							setAreAllExpanded(false);
							setAreAllExpandedTrigger(Date.now());
						}}
					>
						Collapse All
					</DxButton>{' '}
					|{' '}
					<DxButton
						type="link"
						onClick={() => {
							setAreAllExpanded(true);
							setAreAllExpandedTrigger(Date.now());
						}}
					>
						Expand All
					</DxButton>
				</div>
				<DxAccordionGroup className="api-resource-accordion">
					{Object.entries(filteredOperations)
						.filter(([tag]) => !filteredTags.includes(tag))
						.sort(([atag], [btag]) => (atag > btag ? 1 : btag > atag ? -1 : 0))
						.map(([tag, operations]) => (
							<React.Fragment>
								<a id={makeAnchor(tag)} />
								<DxAccordion
									title={
										<h2 style={{ margin: 0 }}>
											{tag}
											<a
												id={makeAnchor(tag)}
												href={`#${makeAnchor(tag)}`}
												className="accordion-header-button"
												onClick={(e) => e.stopPropagation()}
											>
												<GenesysDevIcon icon={GenesysDevIcons.AppLink} />
											</a>
										</h2>
									}
									showOpen={areAllExpanded}
									showOpenTrigger={areAllExpandedTrigger}
									key={tag}
								>
									{makeTiles(operations)}
								</DxAccordion>
							</React.Fragment>
						))}
				</DxAccordionGroup>
			</React.Fragment>
		);
	};

	if (!apiOperations || !swagger) return <LoadingPlaceholder text="Loading API Definition, this may take a while" />;

	const showSearchControls: boolean = props.search || !!props.swaggerTag;
	let verbFilter: HttpVerbFilter | undefined = undefined;
	if (props.verb) {
		verbFilter = defaultHttpVerbFilter();
		verbFilter[props.verb.toLowerCase() as HttpVerb] = true;
	}

	return (
		<div className="openapi-explorer-container">
			<div className="openapi-explorer">
				<form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
					<OperationFilterWidget
						apiOperations={apiOperations}
						hideInterface={!showSearchControls}
						verbFilter={verbFilter}
						initialSearchTerm={props.path}
						onOperationsFiltered={(newOps: CategorizedOperations) => {
							if (props.verb && props.path) {
								// Only show one resource when verb & path are set.
								// A resource with multiple tags will be returned multiple times by the filter.
								const filterPath = props.path?.toLowerCase() || '';
								const filterVerb = props.verb?.toLowerCase() || '';
								Object.entries(newOps).some(([tag, ops]) => {
									let op = ops?.find((op) => op.path.toLowerCase() === filterPath && op.verb.toLowerCase() === filterVerb);
									if (op) {
										setFilteredApiOperations({ [tag]: [op] });
										return true;
									}
									return false;
								});
							} else {
								setFilteredApiOperations(newOps);
							}
						}}
					/>
					{displayResources()}
				</form>
			</div>
		</div>
	);
}

function makeAnchor(str = '') {
	return str.toLowerCase().replaceAll(/[^a-z0-9-]/g, '-');
}
