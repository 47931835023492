import { atom, useRecoilValue } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

export interface FeedbackData {
	message: string;
}

// This atom use used by the feedback form so the data isn't lost on accidental closure
const feedbackAtom = atom<FeedbackData>({
	key: 'feedback-atom',
	default: { message: '' },
});

export function useFeedback() {
	return useRecoilValue(feedbackAtom);
}

export function setFeedbackMessage(message: string) {
	const newFeedback = structuredClone(getRecoil(feedbackAtom));
	newFeedback.message = message;
	setRecoil(feedbackAtom, newFeedback);
}
