import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useSitemap } from '../../../helpers/atoms/SitemapAtom';
import { Page, SitemapData, isPageVisible } from '../../../helpers/Sitemap';

interface IProps {
	level?: number;
}

export default function RelativeSitemap(props: IProps) {
	const sitemap = useSitemap();
	const location = useLocation();

	const printSitemap = (relativeSitemap: SitemapData | Page | undefined, depth?: number) => {
		if (!relativeSitemap) return;
		if (depth === undefined) depth = 2;
		if (depth < 1) return;
		return (
			<ul>
				{Object.entries(relativeSitemap)
					.filter(
						([key, value]) =>
							typeof value === 'object' &&
							key !== 'index' &&
							value !== null &&
							((value.isDir && value.index && isPageVisible(value.index)) || (value.title && isPageVisible(value)))
					)
					.map(([key, value]) => {
						if (value.isDir) {
							return (
								<li key={key}>
									<Link to={value.index.link}>{value.index.title}</Link>
									{printSitemap(value, depth! - 1)}
								</li>
							);
						}
						return (
							<li key={key}>
								<Link to={value.link}>{value.title}</Link>
							</li>
						);
					})}
			</ul>
		);
	};

	return printSitemap(sitemap.getPage(location.pathname, true), props.level) || <></>;
}
